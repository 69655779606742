const DoctorToolIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_795_2207"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="15"
      >
        <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_795_2207)">
        <path
          d="M7.87508 13.3334C6.82508 13.3334 5.93064 12.964 5.19175 12.2251C4.45286 11.4862 4.08342 10.5917 4.08342 9.54175V9.20633C3.2473 9.07022 2.55216 8.6789 1.998 8.03237C1.44383 7.38585 1.16675 6.62508 1.16675 5.75008V2.25008H2.91675V1.66675H4.08342V4.00008H2.91675V3.41675H2.33341V5.75008C2.33341 6.39175 2.56189 6.94105 3.01883 7.398C3.47578 7.85494 4.02508 8.08342 4.66675 8.08342C5.30841 8.08342 5.85772 7.85494 6.31466 7.398C6.77161 6.94105 7.00008 6.39175 7.00008 5.75008V3.41675H6.41675V4.00008H5.25008V1.66675H6.41675V2.25008H8.16675V5.75008C8.16675 6.62508 7.88967 7.38585 7.3355 8.03237C6.78133 8.6789 6.08619 9.07022 5.25008 9.20633V9.54175C5.25008 10.2709 5.50529 10.8907 6.01571 11.4011C6.52612 11.9115 7.14592 12.1667 7.87508 12.1667C8.60425 12.1667 9.22404 11.9115 9.73446 11.4011C10.2449 10.8907 10.5001 10.2709 10.5001 9.54175V8.56466C10.1598 8.448 9.88029 8.23897 9.66154 7.93758C9.44279 7.63619 9.33342 7.29592 9.33342 6.91675C9.33342 6.43064 9.50355 6.01744 9.84383 5.67717C10.1841 5.33689 10.5973 5.16675 11.0834 5.16675C11.5695 5.16675 11.9827 5.33689 12.323 5.67717C12.6633 6.01744 12.8334 6.43064 12.8334 6.91675C12.8334 7.29592 12.724 7.63619 12.5053 7.93758C12.2865 8.23897 12.007 8.448 11.6667 8.56466V9.54175C11.6667 10.5917 11.2973 11.4862 10.5584 12.2251C9.81953 12.964 8.92508 13.3334 7.87508 13.3334ZM11.0834 7.50008C11.2487 7.50008 11.3872 7.44418 11.499 7.33237C11.6108 7.22057 11.6667 7.08203 11.6667 6.91675C11.6667 6.75147 11.6108 6.61293 11.499 6.50112C11.3872 6.38932 11.2487 6.33342 11.0834 6.33342C10.9181 6.33342 10.7796 6.38932 10.6678 6.50112C10.556 6.61293 10.5001 6.75147 10.5001 6.91675C10.5001 7.08203 10.556 7.22057 10.6678 7.33237C10.7796 7.44418 10.9181 7.50008 11.0834 7.50008Z"
          fill="#FAFAFA"
        />
      </g>
    </svg>
  );
};

export default DoctorToolIcon;
