import { message } from "antd";
import moment from "moment";
import {
  markLeaveForSelectedDates,
  deleteLeaveByID,
  fetchLeaveForDaysBetween
} from "../../../../redux/reducers/slot.slice";

import { ConfirmationModal } from "./confirmation-wrapper";
import { useDispatch } from "react-redux";
import { useMemo, useCallback } from "react";

interface MarkUnmarkLeaveModalProps {
  _appointmentData: any;
  profile: any;
  model_open: any;
  set_model_open: (on: boolean) => void;
}

export const MarkLeaveModal: React.FC<MarkUnmarkLeaveModalProps> = ({
  _appointmentData,
  profile,
  model_open,
  set_model_open,
}) => {
  const dispatch = useDispatch();

  const markLeaveAppointment = useCallback(async () => {
    try {
      var date = _appointmentData.date;
      const res: any = await dispatch(
        markLeaveForSelectedDates({
          doctors_off_days: [
            {
              branch_id: _appointmentData.branch,
              reason: "reason",
              status: "mark",
              start_date: moment(date).format("YYYY-MM-DD"),
              end_date: moment(date).format("YYYY-MM-DD"),
              doctor_id: profile.id,
            },
          ],
        })
      );

      if ("error" in res) {
        message.error(res.error.message || "An error occurred");
        return;
      }

      console.log(res)

      const payload = res.payload;
      if (payload.status === 201) {
        if (payload.data?.statusCode === 200) {
          message.success("Leave Marked Successfully");
        } else {
          message.error(payload.data?.message);
        }
      } else {
        message.error(payload.data?.message || "An error occurred");
      }
    } catch (error) {
      message.error("An error occurred");
      console.error(error);
    }
    set_model_open(false)
  }, []);

  const leave_date = moment(_appointmentData.date).format("Do MMMM, YYYY")
  return (
    <ConfirmationModal
      onConfirm={() => markLeaveAppointment()}
      onCancel={() => set_model_open(false)}
      ButtonTitle={"Mark Leave"}
      ButtonContent={`Please note that this will mark you on leave for the entire day of ${leave_date}. Do you want to continue?`}
      confirmText={"Yes"}
      cancelText={"No"}
      isModalVisible={model_open}
      setIsModalVisible={set_model_open}
    />
  );
};

export const UnmarkLeaveModal: React.FC<MarkUnmarkLeaveModalProps> = ({
  _appointmentData,
  profile,
  model_open,
  set_model_open,
}) => {
  const dispatch = useDispatch();

  const unmarkLeaveAppointment = useCallback(async () => {
    try {
      const formattedData = moment(_appointmentData.date).format("YYYY-MM-DD");
      const data = {
        doctor_ids: [profile.id],
        branch_id: _appointmentData.branch,
        start_date: formattedData,
        end_date: formattedData,
      };

      const leave_data_response = await dispatch(fetchLeaveForDaysBetween(data));
      let leaveData = null;

      try {
        leaveData = leave_data_response.payload.data.data[0];
      } catch {
        message.error("Error in fetching leave data");
      }

      if (!leaveData) {
        message.error("Leave data is null");
        model_open(false)
        return;
      }
      const response: any = await dispatch(deleteLeaveByID(leaveData.id));

      if (response.payload?.data?.statusCode === 200) {
        message.success("Leave Unmarked Successfully");
      } else {
        message.error("An error occurred");
        console.log("Errored Response: ", response);
      }
    } catch (e) {
      message.error("Error Occured while unmarking leave!!");
      console.log(e);
    }
    console.log(model_open)
    set_model_open(false)
  }, []);

  const leave_date = useMemo(() => {
    return moment(_appointmentData.date).format("Do MMMM, YYYY");
  }, []);

  return (
    <ConfirmationModal
      onConfirm={() => unmarkLeaveAppointment()}
      onCancel={() => set_model_open(false)}
      ButtonTitle={"Unmark Leave"}
      ButtonContent={`This will unmark your leave for ${leave_date}. Do you want to continue?`}
      confirmText={"Yes"}
      cancelText={"No"}
      isModalVisible={model_open}
      setIsModalVisible={set_model_open}
    />
  );
};
