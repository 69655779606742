import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GET_PROFILE,
  GET_PROFILE_BY_ID,
  SYNC_PROFILE,
} from "../../shared/routes/route.constants";
import { Client } from "../../shared/Utils/api-client";
import { BetaClient } from "../../shared/Utils/beta-api-client";

const config = {
  name: "profile",
};
export const fetchProfile: any = createAsyncThunk(
  `${config.name}/fetchProfile`,
  async (type: any) => {
    return await Client.get(GET_PROFILE(type), {});
  }
);

export const syncProfile: any = createAsyncThunk(
  `${config.name}/syncProfile`,
  async (data: any) => {
    return await BetaClient.post(SYNC_PROFILE, data, { shouldHide: true });
  }
);

export const fetchProfileById: any = createAsyncThunk(
  `${config.name}/fetchProfileById`,
  async (data: any) => {
    return await Client.get(GET_PROFILE_BY_ID(data.id, data.type), {});
  }
);

export const fetchProfileByAnalytics: any = createAsyncThunk(
  `${config.name}/fetchProfileByAnalytics`,
  async (data: any) => {
    return await Client.get(GET_PROFILE_BY_ID(data.id, data.type), {});
  }
);

export const profileSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingProfile: false,
    error: "" as string,
    profile: null as any,
    selected_doctor: null as any,
    account_id: "fb02e1d5-5d27-4028-9cb4-882e919a7a23",
    coordinators_doctors: [] as any,
    details: null,
    notification: [],
  },
  reducers: {
    reset: (state) => {
      state.savingProfile = false;
      state.loading = false;
      state.error = "";
      state.profile = null;
      state.notification = [];
    },
    setSelectedUser: (state, { payload }) => {
      state.details = payload?.user;
    },
  },
  extraReducers(builder) {
    builder
      // GET_PROFILE Fetch
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action?.payload?.data?.data;
        const { managed_doctors } = action?.payload?.data?.data;
        state.coordinators_doctors = managed_doctors || [];
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchProfileById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchProfileById.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action?.payload?.data?.data;
      })
      .addCase(fetchProfileById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset, setSelectedUser } = profileSlice.actions;

export default profileSlice.reducer;
