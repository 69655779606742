import { Avatar, Badge, Button, Col, List, Row, Space, Tag } from "antd";
import moment from "moment";
import React, { useCallback } from "react";
import DoctorToolIcon from "../../../shared/icons/DoctorToolIcon";
import EyeIcon from "../../../shared/icons/EyeIcon";
import DoctorAppointmentBadge from "../../../shared/layouts/doctor-badge.layout";
import {
  formatDate,
  getAge,
  getFullName,
  getGender,
  getInitials,
} from "../../../shared/Utils/utilities";
var _ = require("lodash");

type Props = {
  type?: any;
  appointments?: any;
  patients?: any;
  showDetails?: (values: any) => void;
  selectedAppointment?: any;
  startConsulting?: (d: any) => void;
};

// types = {
//   1 = Long Card,
//   2 = IPD Card,
//   3 = OPD Card
//   4 = Chat Card
// }

const PatientList: React.FunctionComponent<Props> = ({
  type,
  appointments,
  showDetails,
  patients,
  selectedAppointment,
  startConsulting,
}) => {
  const onConsulting = useCallback(
    (e, item) => {
      e.stopPropagation();
      e.preventDefault();
      showDetails && showDetails(item);
      startConsulting?.(item);
    },
    [showDetails, startConsulting]
  );

  return (
    <Row>
      {type === 3 && (
        <List
          style={{ width: "100%" }}
          dataSource={appointments}
          renderItem={(item: any) => (
            <List.Item
              className={
                selectedAppointment?.id === item.id
                  ? `selected-patient padding20LR patient_list__list_item`
                  : "padding20LR patient_list__list_item"
              }
            >
              <Row className={"full-width"}>
                <Col span={3} className="mt5">
                  <Avatar
                    shape={"square"}
                    className="avtarOPD user-frame  capitalize"
                  >
                    {" "}
                    {item?.patient?.pii &&
                      getInitials(item?.patient?.pii?.first_name)}
                    {item?.patient?.pii &&
                      getInitials(item?.patient?.pii?.last_name)}{" "}
                  </Avatar>
                </Col>
                <Col span={21} className={"pl10"}>
                  <p
                    className="doctor-primary-color bold cursor capitalize mb-0"
                    style={{ marginBottom: "0px" }}
                    onClick={() => showDetails && showDetails(item)}
                  >
                    {item?.patient?.pii?.first_name}{" "}
                    {item?.patient?.pii?.last_name}{" "}
                    <span className="max-subHead-color capitalize">
                      (
                      {item?.patient?.age_in_ymd
                        ? item?.patient?.age_in_ymd
                        : item?.patient?.dob || item?.patient?.birth_year
                        ? getAge(
                            item?.patient?.dob || item?.patient?.birth_year
                          )
                        : ""}
                      ,{" "}
                      {item?.patient?.genderMaster?.name
                        ? getGender(item?.patient?.genderMaster?.name)
                        : ""}
                      )
                    </span>{" "}
                    <br />
                    <small className="sub-text light-text capitalize">
                      {item?.start_time}{" "}
                    </small>{" "}
                    <small className="sub-text light-text capitalize">
                      {" "}
                      {item?.patient_room_wait_time &&
                      item?.patient_room_wait_time !== 0 ? (
                        item?.patient_room_wait_time < 40 ? (
                          <>
                            {" "}
                            |{" "}
                            <img
                              src={
                                require("../../../assets/images/clock.svg")
                                  .default
                              }
                              width={"4%"}
                              alt="clock"
                            />
                            {item?.patient_room_wait_time} mins{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            |{" "}
                            <img
                              src={
                                require("../../../assets/images/clockred.svg")
                                  .default
                              }
                              width={"4%"}
                              alt="clock red"
                            />{" "}
                            <span style={{ color: "#B2403A" }}>
                              {" "}
                              {item?.patient_room_wait_time} mins{" "}
                            </span>
                          </>
                        )
                      ) : (
                        ""
                      )}{" "}
                      |{" "}
                      {
                        <DoctorAppointmentBadge
                          show={false}
                          count={null}
                          type={item?.procedure_info?.name}
                          width={5}
                        ></DoctorAppointmentBadge>
                      }
                    </small>
                  </p>
                  <Row>
                    {item?.patient_appt_status && (
                      <Tag className="doctor-color-tag-green noborder capitalize mt5">
                        {item?.patient_appt_status}{" "}
                      </Tag>
                    )}
                    {item?.patient?.patient_type && (
                      <Tag className="doctor-secondary-color-tag noborder capitalize mt5">
                        {item?.patient?.patient_type}{" "}
                      </Tag>
                    )}
                    {item?.patient?.his_patient_type &&
                      item?.patient?.his_patient_type !== null && (
                        <Tag className="doctor-primary-color-tag-dark noborder capitalize mt5">
                          {item?.patient?.his_patient_type}
                        </Tag>
                      )}
                    {item?.patient?.his_patient_visit_type &&
                      item?.patient?.his_patient_visit_type === "new" && (
                        <Tag className="doctor-primary-color-tag-danger noborder capitalize mt5">
                          New
                        </Tag>
                      )}
                    {item?.is_walk_in_appointment && (
                      <Tag className="doctor-primary-color-tag-danger noborder capitalize mt5">
                        Walk in
                      </Tag>
                    )}
                    {item?.book_source === "overbooking" && (
                      <Tag className="doctor-primary-color-tag-danger-text noborder capitalize mt5">
                        Overbooking
                      </Tag>
                    )}
                    {item?.boss_payment_tag &&
                      item?.boss_payment_tag === "prepaid" && (
                        <Tag className="doctor-primary-color-tag-dark noborder capitalize mt5">
                          Prepaid
                        </Tag>
                      )}
                  </Row>
                  {/* New buttons added here */}
                </Col>
              </Row>

              <Row
                gutter={5}
                className="mt-3 view_consult_button_wrapper full-width"
              >
                <Col span={8}>
                  <Button
                    block
                    size="large"
                    className="doctor-border-button new_button_config"
                    onClick={(e) => {
                      showDetails && showDetails(item);
                    }}
                  >
                    View <EyeIcon />
                  </Button>
                </Col>
                <Col span={16}>
                  <Button
                    className="doctor-secondary-button new_button_config"
                    block
                    size="large"
                    disabled={item.latest_status !== "checked-in"}
                    onClick={(e) => {
                      onConsulting(e, item);
                    }}
                  >
                    <DoctorToolIcon />
                    Consult
                  </Button>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      {type === 4 && (
        <List
          style={{ width: "100%" }}
          dataSource={patients}
          renderItem={(item: any) => (
            <List.Item
              className={
                selectedAppointment?.id === item.patient_id
                  ? `selected-patient padding20LR`
                  : "padding20LR"
              }
            >
              <Row className="full-width">
                <Col span={3} className="mt5">
                  <Badge
                    count={item?.is_new_unread_msg ? " " : 0}
                    size={"small"}
                  >
                    <Avatar
                      shape={"square"}
                      size={"large"}
                      src={
                        _.find(item.members, { role: "patient" })?.profile_pic
                      }
                      className="user-frame"
                    >
                      {getInitials(
                        _.find(item.members, { role: "patient" })?.name
                      )}
                    </Avatar>
                  </Badge>
                </Col>
                <Col span={21} className={"pl10"}>
                  <Row>
                    <Col span={16}>
                      <span
                        className="doctor-primary-color bold cursor capitalize"
                        onClick={() => showDetails && showDetails(item)}
                      >
                        {_.find(item.members, { role: "patient" })?.name}{" "}
                        <small className="max-subHead-color light-text">
                          (
                          {["NaNyrs", null, "Null"].indexOf(
                            getAge(
                              _.find(item.members, { role: "patient" })?.age
                            )
                          ) === -1
                            ? getAge(
                                _.find(item.members, { role: "patient" })?.age
                              )
                            : _.find(item.members, { role: "patient" })
                                ?.age}{" "}
                          {getGender(
                            _.find(item.members, { role: "patient" })?.gender
                          )}{" "}
                          )
                        </small>{" "}
                      </span>
                    </Col>
                    <Col span={8} className="text-right">
                      <i className="max-subHead-color font12">
                        {moment(item?.last_updated).fromNow()}
                      </i>
                    </Col>
                    <Col span={24}>
                      <p>
                        <i className="max-subHead-color font12">
                          Last Visit:{formatDate(item?.last_updated)}
                        </i>
                      </p>
                    </Col>
                    <Col span={24} className="mt-10">
                      <i className="sub-title">{item?.last_msg}</i>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      {type === 2 && (
        <List
          style={{ width: "100%" }}
          dataSource={appointments}
          renderItem={(item: any) => (
            <List.Item
              className={
                selectedAppointment?.max_id === item.max_id
                  ? `selected-patient padding20LR`
                  : "padding20LR"
              }
            >
              <Col span={3}>
                <Avatar shape={"square"} size="large" className={"user-frame"}>
                  {" "}
                  {item?.name && getInitials(item?.name)}{" "}
                </Avatar>
              </Col>
              <Col span={21} className={"pl10"}>
                <p
                  className={
                    item?.admit_status === "Admit"
                      ? "doctor-primary-color bold cursor mt5 capitalize mb-0"
                      : item?.ipd_status !== "Unknown" &&
                        item?.admit_status !== "Admit"
                      ? "doctor-primary-color bold cursor mt5 capitalize mb-0"
                      : "doctor-primary-color bold  mt5 capitalize mb-0"
                  }
                  onClick={() =>
                    item?.admit_status === "Admit"
                      ? showDetails && showDetails(item)
                      : item?.ipd_status !== "Unknown" &&
                        showDetails &&
                        showDetails(item)
                  }
                >
                  {item?.name && (item?.name).toLowerCase()}{" "}
                  {
                    <small className="sub-text dateColors">
                      ({item?.age}, {getGender(item?.gender)})
                    </small>
                  }
                  <br></br>
                  <span>
                    {" "}
                    {item?.admission_date &&
                      moment(item?.admission_date).format("Do MMM' YYYY")}{" "}
                    <span className="sub-text dateColors">
                      {" "}
                      {item?.admit_status === "Discharged"
                        ? "(Discharged Date)"
                        : item?.admit_status === "Admit"
                        ? "(Admission Date)"
                        : "(OPD Date)"}
                    </span>
                  </span>
                  <br></br>
                  {item?.admit_status !== "Preadmission" && (
                    <>
                      <span className="sub-text dateColors">
                        Room: {item?.room_no} | Bed no: {item?.bed_no}
                      </span>
                      <br></br>
                    </>
                  )}
                  <Space size="small" className="tagSpace">
                    <Tag className="doctor-primary-color-tag  capitalize">
                      {item?.payment_type}
                    </Tag>
                    {item?.admit_status !== "Preadmission" ? (
                      <Tag className="doctor-secondary-color-tag  capitalize">
                        {item?.role}
                      </Tag>
                    ) : (
                      <Tag className="doctor-default-color-tag  capitalize">
                        {item?.ipd_status}{" "}
                      </Tag>
                    )}
                  </Space>
                </p>
              </Col>
            </List.Item>
          )}
        />
      )}

      {type === 1 && (
        <List
          style={{ width: "100%" }}
          dataSource={patients}
          renderItem={(item: any) => (
            <List.Item
              className={
                selectedAppointment?.id === item.id
                  ? `selected-patient padding20LR`
                  : "padding20LR"
              }
            >
              <Row className={"full-width"}>
                <Col span={3} className="mt5">
                  <Avatar
                    shape={"square"}
                    className={"user-frame"}
                    size="large"
                  >
                    {item && item?.first_name && getInitials(item?.first_name)}{" "}
                    {item && item?.last_name && getInitials(item?.last_name)}{" "}
                  </Avatar>
                </Col>

                <Col span={21} className={"pl10"}>
                  <p
                    className="doctor-primary-color bold cursor capitalize mb-0"
                    style={{ marginTop: "2px" }}
                    onClick={() => showDetails && showDetails(item)}
                  >
                    {getFullName(item)}{" "}
                    <small className="max-subHead-color">
                      (
                      {item?.age_in_ymd ? (
                        item?.age_in_ymd + ","
                      ) : item && item?.dob ? (
                        <>{getAge(item?.dob || item?.birth_year) + ","}</>
                      ) : (
                        ""
                      )}{" "}
                      {item && item?.gender ? (
                        <>{getGender(item?.gender)}</>
                      ) : (
                        ""
                      )}
                      )
                    </small>{" "}
                    <br></br>
                    <small className="max-subHead-color">
                      +91-{item.mobile}{" "}
                    </small>
                  </p>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}
    </Row>
  );
};

export default PatientList;
