import { Col, Input, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  findAppointmentPatients,
  resetAppointments,
} from "../../../redux/reducers/appointment.slice";
import {
  fetchChatRooms,
  fetchMultipleRooms,
  markReadMessagePatient,
  reset,
} from "../../../redux/reducers/chat.slice";
import { fetchPatientsDetailsById } from "../../../redux/reducers/patients.slice";
import { RootState } from "../../../shared/constants";
import ChatLayout from "../../../shared/layouts/chat.layout";
import PatientNameCard from "../../../shared/layouts/name-card";
import PatientList from "../Patients/patients.page";

const _ = require("lodash");
const { Search } = Input;
type Props = {};

const DoctortPatientsList: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { profile } = useSelector((state: RootState) => state.profile);
  const { room_loading, rooms } = useSelector((state: RootState) => state.chat);
  const { patient } = useSelector((state: RootState) => state.patient);
  const [chat, setChat] = useState(false);
  const [allRooms, setALlRoms] = useState([] as Array<any>);
  const _patientData: any = location?.state || {};
  const { connection } = useSelector((state: RootState) => state.chat);
  const [allMessages, setAllMessages] = useState([] as any);

  useEffect(() => {
    const wsCurrent = connection;
    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (
        Object.keys(_message).length === 0 ||
        _message.message === "success"
      ) {
        return;
      }
      setAllMessages((prevState: any) => [...prevState, _message]);
      var element = document.getElementById("box") as any;
      element &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    };
  });

  useEffect(() => {
    dispatch(
      fetchChatRooms({
        user_type: `doctor`,
        limit: "",
        user_id: profile?.id,
        room_type: "all",
      })
    );
    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  useEffect(() => {
    setALlRoms(rooms);
    setChat(false);
    if (_patientData && rooms.length !== 0 && _patientData?.patient_id) {
      let patient = rooms.find((a: any) => {
        return a.patient_id === _patientData?.patient_id;
      });
      showDetails(patient);
    }
  }, [rooms, _patientData]);

  const showDetails = (data: any) => {
    (async function () {
      try {
        setChat(false);
        reset();
        const response = await dispatch(
          fetchPatientsDetailsById(data.patient_id)
        );
        dispatch(markReadMessagePatient(data.chat_room_id));
        if (
          response.payload.status === 200 ||
          response.payload.status === 201
        ) {
          setChat(true);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const getPatientsList = (text: any) => {
    (async function () {
      try {
        const response = await dispatch(
          findAppointmentPatients({
            doctor_id: profile.id,
            q: text,
          })
        );
        if (
          response?.payload?.status === 200 ||
          response?.payload?.status === 201 ||
          response?.payload?.data?.data?.length > 0
        ) {
          //check here for data length
          if (response.payload?.data?.data.length !== 0) {
            dispatch(
              fetchMultipleRooms({
                doctor_id: profile.id,
                patient_ids: response.payload?.data?.data.map((item: any) => {
                  return item.id;
                }),
              })
            );
          } else {
            dispatch(reset());
          }
        }
      } catch (e) {
        dispatch(reset());
      }
    })();
  };

  return (
    <Row>
      <Col
        span={7}
        className="full-height white-background padding20 overflowhide border-right"
        style={{ paddingBottom: "50px" }}
      >
        <Row>
          <Col span={20}>
            <h2 className="bold doctor-primary-color mt10 ">My Chats</h2>
          </Col>

          <Col span={24}>
            <Search
              style={{ width: "100%" }}
              allowClear
              size={"large"}
              placeholder="Search Patient"
              className="doctor-search border-radius5"
              onSearch={(evt) => getPatientsList(evt)}
            ></Search>
          </Col>
        </Row>
        {room_loading ? (
          <>
            <Skeleton active className={"mt20"}></Skeleton>{" "}
            <Skeleton active className={"mt20"}></Skeleton>
          </>
        ) : (
          <>
            {" "}
            <Row
              className="mt20 nomarginLR20 padding20 doctor-light-background borderRightGray"
              style={{ marginRight: "-20px" }}
            >
              <Col span={24}>
                {allRooms && (
                  <h3 className="bold doctor-primary-color mt10 ">
                    {allRooms.length} Patients Found
                  </h3>
                )}
              </Col>
            </Row>
            <Row className={"nomargin"}>
              <Col span={24} style={{ marginTop: "24px" }}>
                <PatientList
                  type={4}
                  selectedAppointment={patient}
                  patients={allRooms}
                  showDetails={(item: any) => showDetails(item)}
                ></PatientList>
              </Col>
            </Row>
          </>
        )}
      </Col>
      <Col span={17}>
        {chat === true && (
          <>
            <Col span={24} className="white-background paddingLR20">
              <PatientNameCard
                type={"patient"}
                details={patient}
              ></PatientNameCard>
            </Col>
            <ChatLayout
              allMessages={allMessages}
              allData={allRooms}
              isDrawer={false}
              show={chat}
              details={patient}
              type={"patient"}
              loginUser={"doctor"}
              setChat={(value: any) => setChat(value)}
            ></ChatLayout>
          </>
        )}
      </Col>
    </Row>
  );
};

export default DoctortPatientsList;
