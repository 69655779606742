import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  updateDeleteAddBlockSlots,
} from "../../../../redux/reducers/slot.slice";

import {
  ConfirmationWrapperButton,
} from "./confirmation-wrapper";

import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as DropDownBoxArrow } from "../../../../assets/icons/DropDownBoxArrow.svg";
import { ReactComponent as HospitalIcon } from "../../../../assets/icons/hospital_icon.svg";
import executeWithLoaderAndStatus from "./sliding-status-wrapper";

const { Text, Title } = Typography;

interface BlockShiftEntryProps {
  startTime?: string;
  setStartTime: (time: string) => void;
  endTime?: string;
  setEndTime: (time: string) => void;
  blockReason: string;
  setBlockReason: (reason: string) => void;
  isBlocked: boolean;
  setIsBlocked: (isBlocked: boolean) => void;
  isNewEntry: boolean;
  isDeleteDisabled: boolean;
  onDelete: () => void;
}

interface CustomTimePickerProps {
  placeholder: string;
  value: string;
  style: any;
  onChange: (time: string) => void;
}


const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  placeholder,
  style,
  onChange,
  value
}) => {
  return (
    <TimePicker
      use12Hours
      format="h:mm a"
      placeholder={placeholder}
      suffixIcon={<DropDownBoxArrow />}
      className="w-full border-2 border-teal-500 rounded-md"
      popupClassName="ant-picker-dropdown-placement-bottomLeft"
      renderExtraFooter={() => null}
      size={"large"}
      style={{ ...style }}
      onChange={(evt) => onChange(moment(evt).format("h:mm a"))}
      value={value ? moment(value, "h:mm a") : undefined}
    />
  );
};



const BlockShiftEntry: React.FC<BlockShiftEntryProps> = ({
  setStartTime,
  setEndTime,
  setIsBlocked,
  setBlockReason,
  blockReason,
  startTime,
  endTime,
  isBlocked,
  onDelete,
  isNewEntry,
  isDeleteDisabled,
}) => {
  return (
    <>
      <Row gutter={20} className="font16">
        <Col span={12} className="mt20">
          <Row gutter={10}>
            <Text className="doctor-primary-color cursor">
              <b>Block Time</b>
            </Text>{" "}
            <br />
          </Row>
        </Col>
        <Col span={12} className="mt20">
          <Text className="doctor-primary-color cursor">
            <b>Block Reason</b>
          </Text>{" "}
          <br />
        </Col>
      </Row>
      <Row gutter={20} className="font16" style={{ marginBottom: "10px" }}>
        <Col span={6} style={{ marginTop: "10px" }}>
          <CustomTimePicker
            placeholder="Start Time"
            value={startTime || ""}
            onChange={(time) => setStartTime(time)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={6} style={{ marginTop: "10px" }}>
          <CustomTimePicker
            placeholder="End Time"
            value={endTime || ""}
            onChange={(time) => setEndTime(time)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={10} style={{ marginTop: "10px" }}>
          <Input
            placeholder="Please mention the Reason"
            value={blockReason}
            onChange={(evt) => setBlockReason(evt.target.value)}
            size={"large"}
          ></Input>
          <br />
        </Col>
        <Col span={2} style={{ textAlign: "center" }}>
          <Button
            className="doctor-border-button"
            type="text"
            icon={<DeleteOutlined />}
            onClick={onDelete}
            style={{ marginTop: "13px", scale: "1.2" }}
            disabled={!isNewEntry || isDeleteDisabled}
          />
        </Col>
      </Row>
      <Row gutter={20} className="font16">
        <Col span={15} />
        <Col span={8} style={{ textAlign: "right" }}>
          <Checkbox
            checked={isBlocked}
            onChange={(e) => setIsBlocked(e.target.checked)}
            style={{ marginRight: "5px" }}
          ></Checkbox>
          <Text>Uncheck to unblock</Text>
        </Col>
      </Row>
    </>
  );
};


interface SlotsInterface {
  id: string | null;
  start_time: string;
  end_time: string;
  reason: string;
}

interface BlockShiftModalProps {
  _appointmentData: any;
  profile: any;
  blockShift: any;
  setBlockShift: (on: boolean) => void;
  blockShiftEntries: any;
  setBlockShiftEntries: any;
  setLoadingSlider: (on: boolean) => void;
  setStatus: (status: string) => void;
}

const BlockShiftModal: React.FC<BlockShiftModalProps> = ({
  _appointmentData,
  profile,
  blockShift,
  setBlockShift,
  blockShiftEntries,
  setBlockShiftEntries,
  setLoadingSlider,
  setStatus,
}) => {
  const dispatch = useDispatch();

  const blockShiftClose = () => {
    // remove the block shift entry with null id
    setBlockShiftEntries(
      blockShiftEntries.filter((slot: any) => slot.id !== null)
    );
    setBlockShift(false);
  };


  const isOverlapping = (slot1: SlotsInterface, slot2: SlotsInterface): boolean => {
    return (
      (slot1.start_time < slot2.end_time && slot1.end_time > slot2.start_time) ||
      (slot2.start_time < slot1.end_time && slot2.end_time > slot1.start_time)
    );
  };

  const isWithinAllowedTimeRange = (slot: SlotsInterface): boolean => {
    const start = moment(slot.start_time, 'HH:mm a');
    const end = moment(slot.end_time, 'HH:mm a');
    const allowedStart = moment(_appointmentData.start, 'HH:mm a');
    const allowedEnd = moment(_appointmentData.end, 'HH:mm a');

    // Ensure start and end are on the same day
    if (end.isBefore(start)) {
      end.add(1, 'day');
    }

    // Check if the slot is entirely within the allowed range
    return start.isSameOrAfter(allowedStart) && end.isSameOrBefore(allowedEnd);
  };

  const blockShiftAppointment = async () => {
    try {
      if (blockShiftEntries !== null) {
        let createdSlots: Array<SlotsInterface> = [];
        let updatedSlots: Array<SlotsInterface> = [];
        let deletedSlots: Array<string> = [];
        let hasInvalidSlots = false;

        if (blockShiftEntries.length === 0) {
          message.info("No block shifts found");
          return;
        }

        console.log(_appointmentData);
        let doctor_slot_config_id: string =
          _appointmentData.doctor_slot_config_id;
        const formattedDate = moment(_appointmentData.date).format(
          "YYYY-MM-DD"
        );

        const allSlots: Array<SlotsInterface> = [];

        blockShiftEntries.forEach((slot: any) => {
          if (slot.is_blocked === false) {
            deletedSlots.push(slot.id);
            return;
          }

          const newSlot: SlotsInterface = {
            start_time: slot.start_time,
            end_time: slot.end_time,
            reason: slot.reason,
            id: slot.id,
          };

          // Check if the slot is within the allowed time range
          if (!isWithinAllowedTimeRange(newSlot)) {
            message.error(`Slot outside allowed time range (${_appointmentData.start} - ${_appointmentData.end}): ${slot.start_time} - ${slot.end_time}`);
            hasInvalidSlots = true;
            return;
          }

          // Check for overlaps with existing slots
          const hasOverlap = allSlots.some(existingSlot => isOverlapping(newSlot, existingSlot));

          if (hasOverlap) {
            message.error(`Overlapping slot detected: ${slot.start_time} - ${slot.end_time}`);
            hasInvalidSlots = true;
            return;
          }

          allSlots.push(newSlot);

          if (slot.id === null || slot.id === "") {
            createdSlots.push(newSlot);
          } else {
            updatedSlots.push(newSlot);
          }
        });

        // Exit the function if any invalid slots were found
        if (hasInvalidSlots) {
          return;
        }

        const blocked_slots_data = {
          blocked_slots_data: [
            {
              doctor_slot_config_id: doctor_slot_config_id,
              doctor_id: profile.id,
              date: formattedDate,
              update_blocked_slots: updatedSlots,
              create_blocked_slots: createdSlots,
              delete_blocked_slot_ids: deletedSlots,
            },
          ],
        };

        const response: any = await dispatch(
          updateDeleteAddBlockSlots(blocked_slots_data)
        );

        const payload = response.payload;
        if (payload.status === 200 || payload.status === 201) {
          message.success("Shift blocked successfully");
        }
      } else {
        message.error("No block shifts found");
      }
    } catch (error) {
      console.error("Error blocking shift:", error);
    }
    blockShiftClose();
  };

  return (
    <Modal
      centered
      footer={false}
      title={<Title className="text-center" level={4}> Block Shift </Title>}
      maskClosable={true}
      onCancel={blockShiftClose}
      visible={blockShift}
      width={"60%"}
    >
      <Row style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }} >
        <Col span={24} className="font14" style={{ textAlign: "center", display: "flex", justifyContent: "center" }} >
          <InfoCircleOutlined className="doctor-secondary-color" style={{ marginTop: 4, marginRight: "10px" }} />
          <Text className="text-center doctor-primary-color">
            Blocking a slot will prevent new appointments from being booked
            for that time interval without changing the shift time.
          </Text>
        </Col>

        <Col
          span={24}
          className="text-center font18"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px"
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "20px"
            }}>
            <HospitalIcon
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: "5px"
              }} />
            <Text
              className="cursor capitalize doctor-primary-color"
            >
              {_appointmentData.branch_name}
            </Text>{" "}
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "20px"
            }}>
            <Text
              className="doctor-primary-color "
            >
              Shift Timing: <Text className="bold doctor-primary-color">{_appointmentData.start} - {_appointmentData.end}</Text>
            </Text>
          </Row>
        </Col>
      </Row>

      {blockShiftEntries.map(
        (
          entry: {
            reason: string;
            start_time: string;
            end_time: string;
            is_blocked: boolean;
            id: string;
          },
          index: number
        ) => (
          <BlockShiftEntry
            key={index}
            setBlockReason={(reason) => {
              const updatedEntries = [...blockShiftEntries];
              updatedEntries[index].reason = reason;
              setBlockShiftEntries(updatedEntries);
            }}
            setStartTime={(time) => {
              const updatedEntries = [...blockShiftEntries];
              updatedEntries[index].start_time = time;
              setBlockShiftEntries(updatedEntries);
            }}
            setEndTime={(time) => {
              const updatedEntries = [...blockShiftEntries];
              updatedEntries[index].end_time = time;
              setBlockShiftEntries(updatedEntries);
            }}
            setIsBlocked={(is_blocked) => {
              const updatedEntries = [...blockShiftEntries];
              updatedEntries[index].is_blocked = is_blocked;
              setBlockShiftEntries(updatedEntries);
            }}
            onDelete={() => {
              const updatedEntries = [...blockShiftEntries];
              updatedEntries.splice(index, 1);
              setBlockShiftEntries(updatedEntries);
            }}
            blockReason={entry.reason}
            startTime={entry.start_time}
            endTime={entry.end_time}
            isBlocked={entry.is_blocked}
            isNewEntry={entry.id === null}
            isDeleteDisabled={blockShiftEntries.length === 1}
          />
        )
      )}


      <Row className="text-center" gutter={20}>
        <Col span={12}>
          <Button
            size="large"
            block
            className="doctor-border-button mt20 "
            onClick={() => {
              const newEntry = {
                start_time: "",
                end_time: "",
                reason: "",
                is_blocked: true,
                id: null,
              };
              setBlockShiftEntries([...blockShiftEntries, newEntry]);
            }}
          >
            Add More
          </Button>
        </Col>
        <Col span={12}>
          <ConfirmationWrapperButton
            button={
              <Button
                size="large"
                block
                className="doctor-secondary-button mt20"
              >
                {" "}
                Update Shift
              </Button>
            }
            confirmationTitle="Alert"
            confirmationContent={`This will block/unblock your shift between ${_appointmentData.start} - ${_appointmentData.end}. Do you want to continue?`}
            onConfirm={
              () => executeWithLoaderAndStatus({
                loading_slider: setLoadingSlider,
                setting_status: setStatus,
                status: "Blocking your shift...",
                func: blockShiftAppointment,
              })
            }
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default BlockShiftModal;
