import { CHAT_BASE_URL } from "../constants";
import {
        _DEVICE_NOTIFICATIONS,
        _DEVICE_REMOVE,
        _REGISTER_DEVICE,
} from "../Utils/api-client";

const chat_url = process.env.CHAT_BASE_URL || CHAT_BASE_URL;

// Testing Routes
export const ROUTE_VIDEO_TEST = `/video-room`;
//

export const ROUTE_LOGIN = (account: string, source: string) =>
        `/${account || "max"}/${source}/login`;
export const ROUTE_PAGE_NOT_FOUND = () => `/404`;
export const ROUTE_FORGET_PASSWORD = (
        account: string,
        source: string,
        number: string
) => `/${account || "max"}/${source}/forget-password/${number}`;
export const ROUTE_LANDING = (account: string) => `/${account}`;
export const ROUTE_PROFILE = (account: string, source: string) =>
        `/${account}/${source}/profile`;
export const ROUTE_DASHBOARD = (source: string) => `/${source}/dashboard`;
export const ROUTE_HOME = "/home";
export const ROUTE_PAYMENT_SUCCESS = (id: string) => `/pay/${id}/success`;
export const ROUTE_DEEP_LINK = () => `/deeplink`;
export const ROUTE_PAYMENT_FAILURE = (id: string) => `/pay/${id}/fail`;
export const ROUTE_REDIRECT_URL = (id: any) => `/r/u/${id}`;

export const ROUTE_USERS = (account: string) => `/${account}/admin/users`;
export const ROUTE_USER_CREATE = "/admin/users/create";
export const ROUTE_USER_ACTION = (id: string, mode: string) =>
        `/admin/users/${id}/${mode}`;

export const ROUTE_ROLES = "/admin/roles";
export const ROUTE_ROLE_CREATE = "/admin/roles/create";
export const ROUTE_ROLE_ACTION = (id: string, mode: string) =>
        `/admin/roles/${id}/${mode}`;

export const ROUTE_PRESCRIPTION_TEMPLATE_CREATE =
        "/admin/prescription-templates/create";
export const ROUTE_PRESCRIPTION_TEMPLATE_ACTION = (id: string, mode: string) =>
        `/admin/prescription-templates/${id}/${mode}`;

export const ROUTE_ACCESS_GROUPS = (account: string) =>
        `/${account}/admin/access-groups`;
export const ROUTE_ACCESS_GROUP_CREATE = (account: string) =>
        `/${account}/admin/access-groups/create`;
export const ROUTE_ACCESS_GROUP_ACTION = (
        account: string,
        id: string,
        mode: string
) => `/${account}/admin/access-groups/${id}/${mode}`;

export const ROUTE_BRANCHES = "/admin/branches";
export const ROUTE_BRANCH_CREATE = "/admin/roles/branches";
export const ROUTE_BRANCH_ACTION = (id: string, mode: string) =>
        `/admin/branches/${id}/${mode}`;

export const ROUTE_REGIONS = "/admin/regions";
export const ROUTE_REGION_CREATE = "/admin/create-region";
export const ROUTE_REGION_ACTION = (id: string, mode: string) =>
        `/admin/region/${id}/${mode}`;

export const ROUTE_DEPARTMENTS = "/admin/departments";
export const ROUTE_DEPARTMENT_CREATE = "/admin/departments/create";
export const ROUTE_DEPARTMENT_ACTION = (id: string, mode: string) =>
        `/admin/departments/${id}/${mode}`;

export const ROUTE_TEAMS = "/admin/teams";
export const ROUTE_TEAM_CREATE = "/admin/roles/create";
export const ROUTE_TEAM_ACTION = (id: string, mode: string) =>
        `/admin/teams/${id}/${mode}`;

export const ROUTE_MESSAGE_TEMPLATES = (account: string) =>
        `/${account}/admin/message-templates`;
export const ROUTE_MESSAGE_TEMPLATE_CREATE = (account: string) =>
        `/${account}/admin/message-templates/create`;
export const ROUTE_MESSAGE_TEMPLATE_ACTION = (
        account: string,
        id: string,
        mode: string
) => `/${account}/admin/message-templates/${id}/${mode}`;

export const ROUTE_PERMISSIONS = "/admin/permissions";
export const ROUTE_GENERAL_SETTINGS = "/admin/settings";
export const ROUTE_ADMIN_STATS = "/admin/stats";

export const ROUTE_CMS_DATA = "/admin/cms-data";

export const ROUTE_DOCTOR = "/admin/doctors";
export const ROUTE_DOCTOR_CREATE = (id: string) => `/user/${id}/doctor/create`;
export const ROUTE_DOCTOR_ACTION = (id: string, mode: string) =>
        `/user/doctor/${id}/${mode}`;
export const ROUTE_DOCTOR_SPECIALIZATION = (id: string) =>
        `/user/${id}/doctor/specialization`;
export const ROUTE_DOCTOR_PROPERTIES = (id: string) =>
        `/user/${id}/doctor/properties`;

//tasks
export const ROUTE_TASKS = "/doctor/tasks";
export const DUMMY = "/admin/dummy";

export const ROUTE_DOCTOR_SLOT_CREATE = (id: string) => `/doctor/${id}/slot`;
export const ROUTE_SLOT_ACTION = (id: string, mode: string) =>
        `/doctor/slot/${id}/${mode}`;

export const ROUTE_APPOINTMENTS = "/admin/appointment/search/patient";
export const ROUTE_BOOK_APPOINTMENTS = "/admin/appointment/book";
export const ROUTE_APPOINTMENTS_HISTORY = "/admin/appointment/history";

///////////// Patient App Routes
export const ROUTE_PATIENT_APPOINTMENTS = `/patient/appointments`;
export const ROUTE_PATIENT_APPOINTMENT_DETAILS = (id: string) =>
        `/patient/appointment/${id}`;
export const ROUTE_PATIENT_DOCUMENTS = (type: string) =>
        `/patient/documents/${type}`;
export const ROUTE_PATIENT_WAITING_AREA = (
        room_id: string,
        patient_id: string,
        appointment_id: string
) => `/patient/appointment/${room_id}/${patient_id}/${appointment_id}`;

///////////// Open App Routes
export const ROUTE_WAITING_AREA = (room_id: string, patient_id: string) =>
        `/appointment/${room_id}/${patient_id}`;
export const ROUTE_PUBLIC_APPOINTMENT_VIEW = (
        room_id: string,
        source_id: string,
        source: string
) => `/patient/appointment-room/${room_id}/${source_id}/${source}`;

///////////// Pharmacy Routes
export const ROUTE_PHARMACY_ORDER_QC = "/order/pharmacy/order-qc";
export const ROUTE_PHARMACY_CREATE_QC = (id: string) =>
        `/order/pharmacy/${id}/create-qc`;
export const ROUTE_PHARMACY_ORDER_FULFIL = "/order/pharmacy/order-fulfilment";
export const ROUTE_PHARMACY_RX_CREATION = "/order/pharmacy/order-rx";
export const ROUTE_PHARMACY_CREATE_RX = (id: string) =>
        `/order/pharmacy/${id}/create-rx`;

///////////// API Routes
export const PERMISSIONS = "roles/all-actions";
export const MODULES = "roles/all-modules";
export const USERS = "users";
export const LOGINS = "logins";
export const GETCURRENTUSER = "task/ticket/my-agent-profile";
export const USERS_LOGS = (userId: string) =>
        `request-logs/by-user-id/${userId}`;
export const AGENTS = "task/ticket/agent-profile";
export const AGENTS_ACTIVE = "task/ticket/active-agents-list";
export const AGENTS_ALL = "task/ticket/all-agents-list";
export const AGENTS_BY_ID = (id: string) => `task/ticket/agent-profile/${id}`;
export const TOGGLE_AGENTS_BY_ID = (id: string) =>
        `task/ticket/agent-profile/${id}`;
export const AGENTTPATCH = (id: string) => `task/ticket/agent-profile/${id}`;
export const USERS_SELF = "users/get-my-profile";
export const MY_PERMISSIONS = "logins/get-my-permissions";
export const USER_BY_ID = (id: string) => `users/${id}`;
export const USER_LOGINS_BY_ID = (id: string) => `users/${id}/logins`;
export const USER_COGNITO_PASSWORD_BY_ID = (id: string) =>
        `users/${id}/update-cognito-password`;
export const ASSIGN_ROLE_BY_USER_ID = (id: string) => `users/${id}/assign-role`;
export const ASSIGN_ACCESS_GROUP_BY_USER_ID = (id: string) =>
        `users/${id}/assign-access-group`;
export const PERMISSION_BY_ID = (id: string) => `permissions/${id}`;
export const FETCH_USER_BRANCH = (id: string) => `users/branch-mapping/${id}`;
export const ADD_USER_BRANCH = `users/branch-mapping`;
export const DELETE_USER_BRANCH = (id: string) => `users/branch-mapping/${id}`;
// Roles
export const ROLES = "roles";
export const ROLE_BY_ID = (id: string) => `roles/${id}`;
export const PERMISSIONS_BY_ROLE_ID = (id: string) => `roles/${id}/permissions`;

// Roles
export const ACCESS_GROUP = "access-groups";
export const ALL_SCOPE_VALUES = "all-scope-values";
export const ACCESS_GROUP_BY_ID = (id: string) => `access-groups/${id}`;
// export const PERMISSIONS_BY_ROLE_ID = (id: string) => `roles/${id}/permissions`;

// Docters
export const DOCTORS_QUERY = (type: string, query: string) =>
        `doctor?search_term=${query}&search_column=${type}`;
export const DOCTORS = `doctor`;
export const ASSIGN_DOCTOR_BY_USER_ID = (id: string) =>
        `users/${id}/assign-doctor`;
export const ASSIGN_DOCTOR_SLOT_CONFIG = `slots/create-doctor-slot-config`;
export const UPDATE_DOCTOR_SLOT_CONFIG = (id: string) =>
        `slots/doctor-slot-config/${id}`;
export const ASSIGN_DOCTOR_SLOT_PROCEDURE = `slots/create-doctor-procedure-config`;
export const UPDATE_DOCTOR_SLOT_PROCEDURE = (id: string) =>
        `slots/doctor-slot-procedure/${id}`;
export const DOCTOR_BY_ID = (id: string) => `doctor/${id}`;
export const DOCTOR_SLOTS_BY_ID = (id: string) =>
        `slots/doctor-slot-config?doctor_id=${id}`;
export const DOCTOR_AUTOCOMPLETE = (doctor: string) =>
        `doctor/get-search-autocomplete?search_term=${doctor}`;

// Branches
export const REQUEST_LOGS = "request-logs";
export const REQUEST_LOG_BY_ID = (id: string) => `request-logs/${id}`;
export const TRIGGER_REQUEST_LOGS = `tpresponse/request-logs`;
export const BRANCHES = `branches`;
export const BRANCH_BY_ID = (id: string) => `branches/${id}`;
export const _REQUEST_LOGS_USER_INFO = `request-logs/user-info/{type}/{user_id}`;

// regions
export const REGIONS = `master/region`;
export const UPDATE_REGION = `master/region/update`;
export const ADD_REGION = `master/region/add`;

// Roles
export const DEPARTMENTS = "departments";
export const DEPARTMENT_BY_ID = (id: string) => `departments/${id}`;
// Roles
export const TEAMS = "teams";
export const TEAM_TYPES = "team-types";
export const MESSAGE_TEMPLATES = "message-templates";
export const TEAM_USERS = (teamId: string) => `teams/${teamId}/users`;
export const TEAM_USERS_BY_ID = (teamId: string, userId: string) =>
        `teams/${teamId}/users/${userId}`;
export const TEAM_BY_ID = (id: string) => `teams/${id}`;
// Auth
export const ACCOUNT_BY_DOMAIN = (domain: string) => `accounts/${domain}`;
export const APPLICATIONS = () => `applications`;
export const ATTEMPT_OTP_LOGIN = "logins/attempt/otp";
export const FORGET_PASSWORD_WITH_OTP = "logins/password/forgot-with-otp";
export const ATTEMPT_OTP_LOGIN_ATTANDANT = (id: string) =>
        `appointments/attendant/otp/${id}`;
export const VALIDATE_OTP_LOGIN_ATTANDANT = (id: string) =>
        `appointments/attendant/verify/otp/${id}`;
export const SWITCH_APPLICATION = (application_id: string) =>
        `logins/switch-application/${application_id}`;
export const SET_PASSWORD_PIN = "logins/password/create";
export const CHANGE_PASSWORD_PIN = "logins/password/change";
export const FORGET_PASSWORD_PIN = "logins/password/forgot";
export const SELF_NOTIFICATION_PREFERENCE =
        "users/self-notification-preference";
export const VALIDATE_OTP_LOGIN = "logins/verify/otp";
export const VALIDATE_OTP_LOGIN_WITH_IMPERSONATE =
        "logins/verify/otp-with-impersonate";
export const ATTEMPT_PIN = "logins/attempt/pin";
export const COORDINATOR_SWITCH = (doctorId: string) =>
        `logins/coordinator-switch/${doctorId}`;
export const REGISTER_DEVICE = _REGISTER_DEVICE;
export const REMOVE_DEVICE = _DEVICE_REMOVE;
export const DEVICE_NOTIFICATIONS = _DEVICE_NOTIFICATIONS;
export const VALIDATE_OTP_LOGIN_PATIENT = "logins/register-patient-with-otp";
export const RESET_OTP_WITH_PASSWORD = "logins/password/reset-with-otp";
export const LOGOUT = (id: string) => `logins/${id}`;

// Master
export const MASTER_DESIGNATIONS = "master/designation";
export const MASTER_GENDER = "master/gender";
export const MASTER_CITIES = "master/cities";
export const MASTER_REGION = "master/region";
export const MASTER_WORKING_DAY = "master/day";
export const MASTER_PROCEDURE = "master/procedure";
export const MASTER_PROPERTY = "master/property";
export const MASTER_MESSAGE_TEMPLATE = "message-templates";
export const MASTER_NATIONALITY = "master/nationality";
export const MASTER_RELIONSHIPS = `master/relationship`;
export const TASK_TYPES = `master/task-type`;
export const TASK_SUB_TYPES = `master/task-sub-type`;
export const TASK_CATEGORIES = `master/task-category`;
export const TASK_DROP_DOWN = `master/task-drop-down`;
export const DROPDOWN_MASTER_DATA_BY_ID = (id: string) =>
        `master/get-dropdown-master-data?mode=${id}`;
export const MASTER_SPECIALITIES = "master/specialities";
export const MASTER_DROPDOW_LIST = "master/get-dropdown?type=report"

export const MASTER_MESSAGE_TEMPLATE_BY_ID = (id: string) =>
        "message-templates/" + id;
export const MASTER_MESSAGE_TEMPLATE_TYPES =
        "message-templates/all-template-types";

// Slots
export const SLOT_BY_ID = (id: string) =>
        `slots/get-practice?doctor_slot_config_id=${id}`;
export const SLOT_PROCEDURE_BY_ID = (id: string) =>
        `slots/doctor-procedure-config?doctor_slot_config_id=${id}`;
export const PREVOIUS_SLOTS = (id: string) =>
        `appointments/previous-shift/${id}`;
export const UPDATE_SLOT_BY_ID = `slots/practice-update`;
export const DELAY_SLOT_BY_ID = `slots/mark-delay`;
export const INSTANT_APPOINTMENT_VC = "slots/instant/schedule";
export const MARK_LEAVE_BULK = "slots/bulk-off-days";
export const GET_OFF_DAYS = "slots/get-off-days";
export const OFF_DAYS_BY_ID = (id: string) => `slots/off-days/${id}`;
export const BLOCK_SHIFT_ENTRIES = "slots/block-practice-slots";

// Appointment
export const APPOINTMENT = "appointments";
export const CREATE_APPOINTMENT = "appointments/create-by-doctor";
export const APPOINTMENTS_BY_PATIENT = "appointments/patient";
export const ADD_APPOINTMENT_ATTENDANT = "appointments/attendant";
export const ADD_APPOINTMENT_NOTE = "appointments/note";
export const UPDATE_APPOINTMENT_NOTE_BY_ID = (id: string) =>
        `appointments/note/${id}`;
export const APPOINTMENT_BY_ID = (id: string) => `appointments/${id}`;
export const APPOINTMENT_IPD_BY_ID = (id: string) => `appointments/patient-recent-appointment?max_id=${id}&days=60`;
export const HISTORICAL_APPOINTMENT_BY_ID = `appointments/find-by-external-id`;
export const APPOINTMENT_STATE_BY_ID = `appointments/patient/state`;
export const IPD_APPOINTMENTS = `appointments/ipd`;
export const APPOINTMENT_PATIENT_JOURNEY = (id: string) =>
        `appointments/audit/${id}`;
export const APPOINTMENT_PATIENT_MESSAGE_JOURNEY = (
        id: string,
        appointment_id: any
) => `notifications?patient_id=${id}&appointment_id=${appointment_id}`;
export const RESHOOT_MESSAGE = (id: string) => `notifications/${id}`;
export const APPOINTMENT_STATUS_BY_ID = (id: string) =>
        `appointments/status/${id}`;
export const APPOINTMENT_VALIDATE_ROOM = "videochat/validate-room";
export const APPOINTMENT_VALIDATE_ROOM_TOKEN = `videochat/access-token`;
export const APPOINTMENT_ROOM_DETAILS = `roomlogs/user/video-details`;
export const APPOINTMENT_VALIDATE_ATTENDANT_ROOM_TOKEN = (
        room_id: string,
        participant_id: any
) => `videochat/open-token?room_id=${room_id}&participant_id=${participant_id}`;

export const APPOINTMENT_VALIDATE_DEEP_LINK = (appointment_id: string) =>
        `appointments/deep-link/info/${appointment_id}`;

export const APPOINTMENT_PAYMENT_LINK = (id: string) =>
        `appointments/payment_link/${id}`;
export const APPOINTMENT_PAYMENT_RECEIPT = `maxdata/bill/pdf`;
export const APPOINTMENT_CHECKOUT = (id: string) =>
        `appointments/checkout/${id}`;
export const APPOINTMENT_CHECKIN = (id: string) =>
        `appointments/patient-check-in/${id}`;
export const APPOINTMENT_ANALYTIS = `appointments/analytics`;
export const APPOINTMENT_PATIENTS = `appointments/my-patients`;
export const APPOINTMENT_TICKET_STATUS = `appointments/ticket`;
export const APPOINTMENT_TICKET_STATUS_BY_ID = (id: string) =>
        `appointments/ticket/${id}`;
export const APPOINTMENT_TICKET_PATIENT_AUDIT_TRAIL = (id: string) =>
        `appointments/audit/${id}`;
export const APPOINTMENT_TRANSACTIONS = `appointments/get-transactions`;
export const APPOINTMENT_TP_SYNC_FAILED = `appointments/tp-sync-failed-appts/get`;
export const APPOINTMENT_THB_SYNC_FAILED = `appointments/thb-sync-failed-appts/get`;

// Patients
export const PATIENTS = `patients`;
export const PATIENTS_BY_NUMBER = (number: string) =>
        `get-patients-by-mobile/${number}`;
export const PATIENTS_RELATIONSHIPS = `patients/relationship`;
export const PATIENT_BY_ID = (id: string) => `patients/${id}`;
export const PATIENTS_DOCUMENTS = `patients/document`;
export const PATIENTS_DOCUMENTS_URL_BY_ID = (id: string) =>
        `patients/document/url/${id}`;

// Prescription
export const PRESCRIPTION = "prescription";
export const PRESCRIPTION_UPDATE = (id: string) => `prescription/${id}`;
export const PRESCRIPTION_COUNT = "prescription/count";
export const PRESCRIPTION_LABREPORTS = "maxdata/laborder/pdf";
export const DISCHARGE_SUMMARY = (uhid: string, start_date: any, end_date: any) =>
        `prescription/patient/discharge-summary-by-uhid/${uhid}?start_date=${start_date}&end_date=${end_date}`;
export const PATIENT_IPD_SUMMARY = (uhid: string, view: any) =>
        `appointments/ipd-patient-details?max_id=${uhid}&view=${view}`;

export const PRESCRIPTION_FILE_BY_ID = (type: string, id: string) =>
        `prescription/path/${type}/${id}`;
export const PRESCRIPTION_TEMPLATES = "templates";
export const PRESCRIPTION_BOTH_TEMPLATES = "templates/all";
export const PRESCRIPTION_TEMPLATE_URL_BY_ID = (id: string) =>
        `templates/path/${id}`;
export const PRESCRIPTION_TEMPLATE_GROUPS = "templates/group";
export const PRESCRIPTION_ITEM_LIST = (type: string, searchTerm: string) =>
        `prescription/item-list?type=${type}&search_term=${searchTerm}`;

// Upload
export const UPLOAD_FILE = "fileuploads/path";
export const UPLOAD_FILE_BY_ID = (id: string) => `fileuploads/path/${id}`;

// Login User
export const GET_PROFILE = (type: string) => `${type}/get-my-profile`;
export const SYNC_PROFILE = `maxdata/sync`;
export const GET_PROFILE_BY_ID = (id: string, type: string) => `${type}/${id}`;

// Chats
export const GET_CHAT_MESSAGES = (
        patient_id: string,
        doctor_id: any,
        limit: any
) =>
        `${chat_url}chats/room?patient_id=${patient_id}&doctor_id=${doctor_id}&lastEvaluatedKey=${limit}`;
export const GET_CHAT_ROOMS = (
        type: string,
        limit: any,
        user_id: any,
        room_type: any
) =>
        `${chat_url}rooms/${type}/${user_id}/${room_type}?lastEvaluatedKey=${limit}`;

export const GET_MULTIPLE_CHAT_ROOMS = `${chat_url}rooms/multiple_chat_rooms`;
export const MARK_READ_CHAT = (room_id: string) =>
        `${chat_url}room/last-read/${room_id}`;

// call logs
export const ROOM_LOGS = `roomlogs`;

//Redirect URL
export const REDIRECT_URL = (id: any) => `maxdata/original-url/${id}`;

export const GET_CRM_TYPES_DATA = (id: string) =>
        `master/get-crm-types-data?mode=${id}`;
