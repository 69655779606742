import React from 'react';

export default class Validations extends React.Component {
    constructor(props) {
        super(props)
    }

    validationChange = (e) => {
        this.props.onValidationChange(e);
    }

    render() {
        let {meta} = this.props;
        return <>
            {meta['validation']['isNumber'] !== undefined && <div className='mb-1 mt-1'>
                <input type="checkbox" name="isNumber" checked={meta['validation'].isNumber} onChange={this.validationChange.bind(this)} />
                <label className='ml-2'> Allow only numbers</label>
            </div>}
            {meta['validation']['minLength'] !== undefined && !meta['validation']['isNumber'] && <div className='mb-1 mt-1'>
                <label className='mr-2'>Minimum Length</label>
                <input className="form-control" type="number" name="minLength" placeholder='Enter min length' onChange={this.validationChange.bind(this)} value={meta.validation['minLength']} />
            </div>}
            {meta['validation']['maxLength'] !== undefined && !meta['validation']['isNumber'] && <div className='mb-1 mt-1'>
                <label className='mr-2'>Maximum Length</label>
                <input className="form-control" type="number" name="maxLength" placeholder='Enter max length' onChange={this.validationChange.bind(this)} value={meta.validation['maxLength']} />
            </div>}
            {meta['validation']['minValue'] !== undefined && meta['validation']['isNumber'] && <div className='mb-1 mt-1'>
                <label className='mr-2'>Minimum Value</label>
                <input className="form-control" type="number" name="minValue" placeholder='Enter min value' onChange={this.validationChange.bind(this)} value={meta.validation['minValue']} />
            </div>}
            {meta['validation']['maxValue'] !== undefined && meta['validation']['isNumber'] && <div className='mb-1 mt-1'>
                <label className='mr-2'>Maximum Value</label>
                <input className="form-control" type="number" name="maxValue" placeholder='Enter max value' onChange={this.validationChange.bind(this)} value={meta.validation['maxValue']} />
            </div>}
            {meta['validation']['minSelections'] !== undefined && <div className='mb-1 mt-1'>
                <label className='mr-2'>Minimum Choices</label>
                <input className="form-control" type="number" name="minSelections" placeholder='Enter min no.of choices' onChange={this.validationChange.bind(this)} value={meta.validation['minSelections']} />
            </div>}
            {meta['validation']['maxSelections'] !== undefined && <div className='mb-1 mt-1'>
                <label className='mr-2'>Maximum Choices</label>
                <input className="form-control" type="number" name="maxSelections" placeholder='Enter max no.of choices' onChange={this.validationChange.bind(this)} value={meta.validation['maxSelections']} />
            </div>}
            {meta['validation']['required'] !== undefined && <div className='mb-1 mt-1'>
                <input type="checkbox" name='required' checked={meta['validation'].required} onChange={this.validationChange.bind(this)} />
                <label className='ml-2'> Required</label>
            </div>}
            {meta.type === 'date' && <div className='mb-1 mt-1'> 
                <input type="checkbox" name='disableFutureDates' checked={meta['validation'].disableFutureDates} onChange={this.validationChange.bind(this)} />
                <label className='ml-2'> Disable Future Dates</label>
            </div>}
            {meta.type === 'date' && <div className='mb-1 mt-1'>
                <input type="checkbox" name='disablePastDates' checked={meta['validation'].disablePastDates} onChange={this.validationChange.bind(this)} />
                <label className='ml-2'> Disable Past Dates</label>
            </div>}
        </>
    }
}