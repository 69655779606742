import { HMSRoomProvider } from "@100mslive/react-sdk";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { message, notification, Spin, Row, Col, Card } from "antd";
import * as dotenv from "dotenv";
import moment from "moment";
import { Suspense, useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.less";
import { BUILD_TIME } from "./built-time";
import { ScreenType } from "./context/ScreenType";
import { BrowserFcmProvider } from "./firebase";
import DeepLinkAppointment from "./pages/deep-link-appointment.page";
import Conference from "./pages/doctor/Video-Room/components/conference";
import { AppContextProvider } from "./pages/doctor/Video-Room/components/context/AppContext.js";
import { Notifications } from "./pages/doctor/Video-Room/components/Notifications";
import PostLeave from "./pages/doctor/Video-Room/components/PostLeave";
import { ToastContainer } from "./pages/doctor/Video-Room/components/Toast/ToastContainer";
import VideoMeetingRoom from "./pages/doctor/Video-Room/video.room.page";
import { NotFoundPage } from "./pages/not-found.page";
import AppointmentWaitingPage from "./pages/patient/Appointment/components/appointment.waitingarea.page";
import PublicAppointmentPage from "./pages/public-appointment.page";
import { RedirectPage } from "./pages/redirect";
import { registerDevice } from "./redux/reducers/auth.slice";
import { getConnection } from "./redux/reducers/chat.slice";
import { persistor, store } from "./redux/store";
import RouteGuard from "./route-guards/route.guard";
import MaxLogoIcon from "./shared/icons/MaxLogoIcon";
import {
  APPLICATION_NAME,
  ENABLE_NOTIFICATION,
  ENABLE_WEB_SOCKET,
  RootState,
  SENTRY_INIT_DSN,
  SENTRY_INIT_NAME,
  WEBSOCKET_END_POINT,
} from "./shared/constants";
import ResourceNotFoundComponent from "./shared/layouts/404.layout";
import GlobalLayout from "./shared/layouts/global.layout";
import PaymentFailure from "./shared/layouts/payment-failure.layout";
import PaymentSuccess from "./shared/layouts/payment-success.layout";
import {
  ROUTE_DOCTOR_APPOINTMENTS_CALL,
  ROUTE_DOCTOR_APPOINTMENTS_CALL_ATTANDANT,
} from "./shared/routes/doctor.routes.constants";
import {
  PATIENTS_DEEP_LINK,
  ROUTE_PATIENT_WAITING_AREA,
  ROUTE_PATIENT_WAITING_AREA_ATTANDANT,
} from "./shared/routes/patient.routes.constants";
import {
  ROUTE_PAGE_NOT_FOUND,
  ROUTE_PAYMENT_FAILURE,
  ROUTE_PAYMENT_SUCCESS,
  ROUTE_PUBLIC_APPOINTMENT_VIEW,
  ROUTE_REDIRECT_URL,
} from "./shared/routes/route.constants";
// import {getToken} from "./firebaseInit.js";
// import {BrowserFcmProvider} from "./firebase";
import { getCookie, setCookie } from "./shared/Utils/utilities";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 min stale time
      cacheTime: 300000, // 5 min cache time
    },
  },
});
document.title = APPLICATION_NAME;

Sentry.init({
  dsn: SENTRY_INIT_DSN,
  release: SENTRY_INIT_NAME + "@" + process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing()],
  maxBreadcrumbs: 50,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  ignoreErrors: [
    "resizer oversor",
    "Script https://patient.maxhospitals.in/flutter_service_worker.js load failed",
  ],
});

dotenv.config();

function App({ pca }: any) {
  const [configData, setConfigData] = useState(
    localStorage.getItem("config") as any
  );

  const loadConfig = () => {
    let origin = window.location.hostname.split(".").join("_");
    origin = origin.includes("max-web-beta")
      ? "maxalpha-medi_maxhospitals_in"
      : origin === "localhost"
      ? "betamedi_maxhospitals_in"
      : origin;

    fetch(
      "https://d27zjjrd786p5a.cloudfront.net/medi-ui/max/" + origin + ".json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (allData) {
        localStorage.setItem("config", JSON.stringify(allData));
        // !configData && window.location.reload();
      })
      .catch((error) => {
        if (window.location.pathname !== "/404") window.location.href = "/404";
      });
  };

  useEffect(() => {
    !configData && loadConfig();
  }, []);

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ScreenType.Provider value={screenType}>
          <GlobalLayout>
            <Router>
              <AppRouted pca={pca} />
            </Router>
          </GlobalLayout>
        </ScreenType.Provider>
      </PersistGate>
    </Provider>
  );
}

const DownloadApp = () => {
  useEffect(() => {
    try {
      if (
        navigator?.platform === "MacIntel" ||
        navigator?.platform === "iPhone" ||
        navigator?.platform === "iPod" ||
        navigator?.platform === "iPad" ||
        navigator?.platform === "MacPPC" ||
        navigator?.platform === "MacIntel"
      ) {
        window.open(
          "https://apps.apple.com/in/app/max-myhealth/id965797580",
          "_self"
        );
        return;
      }
      window.open("https://maxpatientapp.page.link/w5Eb", "_self");
    } catch (error) {
      console.error(error);
    }
  }, []);
  return <></>;
};

const DoctorDownloadApp = () => {
  useEffect(() => {
    try {
      window.open("https://maxpatientapp.page.link/ansH", "_self");
    } catch (error) {
      console.error(error);
    }
  }, []);
  return <></>;
};

function AppRouted({ pca }: any) {
  const app: string | "admin" | "order" | "patient" | "crm" | "doctor" =
    window.location.pathname.toLowerCase().split("/")[1] || "doctor";
  const { account } = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const ws = useRef(null as any);
  const dispatch = useDispatch();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        notification["error"]({
          message: "Please check your internet connection.",
          description: "",
        });
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const setupNotification = async () => {
    try {
      const token = await BrowserFcmProvider.webGetToken();
      let device_id: any = new Date().getTime() + "";

      if (getCookie("device_id")) {
        device_id = getCookie("device_id");
      } else {
        setCookie("device_id", device_id, 100);
      }
      if (token) {
        localStorage.setItem("device_id", device_id + "");
        await dispatch(
          registerDevice({
            app_id: "max_app",
            visitor_id: profile.id,
            user_id: profile.id,
            account_id: "max",
            token: token,
            device_id,
            os: "windows",
            platform: "web",
          })
        );
        BrowserFcmProvider.showMessage();
      }
    } catch (e) {
      console.log("Unable to register firebase");
    }
  };
  function startWebsocket() {
    const accessToken = localStorage.getItem("token");
    if (accessToken && profile && account) {
      ws.current = new WebSocket(
        `${WEBSOCKET_END_POINT}?name=${
          profile?.name
            ? profile?.name
            : profile?.first_name + " " + profile?.last_name
        }&account_id=${account?.id}&role=${app}&date=${moment().format(
          "YYYY-MM-DD"
        )}&token=${localStorage.getItem("token")}&user_id=${profile?.id}`
      );

      ws.current.onmessage = (e: any) => {
        const _message = JSON.parse(e.data);
        if (_message.type === "notification") {
          message.success({
            content: _message.message,
            className: "notifications",
          });
        }
      };
      ws.current.onclose = () =>
        setTimeout(() => {
          ws.current = null;
          startWebsocket();
        }, 5000);

      dispatch(getConnection(ws.current));
    }
  }
  useEffect(() => {
    if (profile && (app === "doctor" || app === "patient" || app === "boss")) {
      if (ENABLE_WEB_SOCKET === true) {
        startWebsocket();

        setInterval(() => {
          let data = {
            action: "sendMessage",
            message: "",
            type: "keep_alive",
            user_id: profile?.id,
            account_id: account?.id,
            source: "webSocket",
            user_name: profile?.name,
            message_type: "text",
            doctor_id: "",
            patient_id: "",
            role: app,
            appointment_id: "",
            token: localStorage.getItem("token"),
          };
          ws.current.send(JSON.stringify(data));
        }, 60000);

        // ws.current = new WebSocket(
        //   `${WEBSOCKET_END_POINT}?name=${
        //     profile?.name
        //       ? profile?.name
        //       : profile?.first_name + " " + profile?.last_name
        //   }&account_id=${account?.id}&role=${app}&date=${moment().format(
        //     "YYYY-MM-DD"
        //   )}&token=${localStorage.getItem("token")}&user_id=${profile?.id}`
        // );
        // ws.current.onclose = () =>
        //   setTimeout(() => {
        //     new WebSocket(
        //       `${WEBSOCKET_END_POINT}?name=${
        //         profile?.name
        //           ? profile?.name
        //           : profile?.first_name + " " + profile?.last_name
        //       }&account_id=${account?.id}&role=${app}&date=${moment().format(
        //         "YYYY-MM-DD"
        //       )}&token=${localStorage.getItem("token")}&user_id=${profile?.id}`
        //     );
        //   }, 1000);

        //    setInterval(() => {
        //         ws.current =  new WebSocket(`${WEBSOCKET_END_POINT}?name=${profile.name}&account_id=${account?.id}&role=${app}&date=${moment().format("YYYY-MM-DD")}&token=${localStorage.getItem('token')}&user_id=${profile.id}`)
        //         dispatch(getConnection(ws.current))
        //     }, 1000*60*15)

        // dispatch(getConnection(ws.current));
      }
      if (ENABLE_NOTIFICATION === true) {
        setupNotification();
      }
    }
  }, []);

  const envPolicyConfig = JSON.parse(
    process.env.REACT_APP_POLICY_CONFIG || "{}"
  );

  return (
    <div>
      <div className={"build-time"}>{BUILD_TIME}</div>
      <HMSRoomProvider>
        <AppContextProvider
          policyConfig={envPolicyConfig}
          appDetails={{ metadata: profile?.id }}
          logo={""}
        >
          <ToastContainer />
          <Notifications />
          <Suspense
            fallback={
              <div>
                <Spin />
              </div>
            }
          >
            <MsalProvider instance={pca}>
              <QueryClientProvider client={queryClient}>
                {/*<ReactQueryDevtools initialIsOpen={false}/>*/}

                <Sentry.ErrorBoundary fallback={ResourceNotFoundComponent}>
                  <Switch>
                    <Route
                      path="/under-maintenance"
                      exact={true}
                      component={UnderMaintainence}
                    />
                    <Route
                      path="/patient/download"
                      exact={true}
                      component={DownloadApp}
                    />
                    <Route
                      path="/doctor/download"
                      exact={true}
                      component={DoctorDownloadApp}
                    />

                    <Route
                      path="/meeting/:appointment/:role"
                      exact={true}
                      component={Conference}
                    />
                    <Route
                      path="/leave/:role"
                      exact={true}
                      component={PostLeave}
                    />
                    <Route
                      path={ROUTE_PAGE_NOT_FOUND()}
                      exact={true}
                      component={NotFoundPage}
                    />

                    <Route
                      path={ROUTE_DOCTOR_APPOINTMENTS_CALL_ATTANDANT(
                        ":id",
                        ":source",
                        ":appointment",
                        ":participant_id"
                      )}
                      exact={true}
                      component={VideoMeetingRoom}
                    />
                    <Route
                      path={ROUTE_DOCTOR_APPOINTMENTS_CALL(
                        ":id",
                        ":source",
                        ":appointment"
                      )}
                      exact={true}
                      component={VideoMeetingRoom}
                    />
                    <Route
                      path={ROUTE_PATIENT_WAITING_AREA_ATTANDANT(
                        ":room_id",
                        ":participant_id",
                        ":appointment_id",
                        ":source"
                      )}
                      exact={true}
                      component={AppointmentWaitingPage}
                    />
                    <Route
                      path={ROUTE_PATIENT_WAITING_AREA(
                        ":room_id",
                        ":source",
                        ":appointment_id"
                      )}
                      exact={true}
                      component={AppointmentWaitingPage}
                    />
                    <Route
                      path={ROUTE_PUBLIC_APPOINTMENT_VIEW(
                        ":room_id",
                        ":source_id",
                        ":source"
                      )}
                      exact={true}
                      component={PublicAppointmentPage}
                    />
                    <Route
                      path={PATIENTS_DEEP_LINK(":id")}
                      exact={true}
                      component={DeepLinkAppointment}
                    />
                    <Route
                      path={ROUTE_REDIRECT_URL(":id")}
                      exact={true}
                      component={RedirectPage}
                    />
                    <Route
                      path={ROUTE_PAYMENT_SUCCESS(":id")}
                      exact={true}
                      component={PaymentSuccess}
                    />
                    <Route
                      path={ROUTE_PAYMENT_FAILURE(":id")}
                      exact={true}
                      component={PaymentFailure}
                    />
                    <RouteGuard path="/" app={app} />
                    {/* <Route  exact={true} component={ResourceNotFoundComponent} /> */}
                  </Switch>
                </Sentry.ErrorBoundary>
              </QueryClientProvider>
            </MsalProvider>
          </Suspense>
        </AppContextProvider>
      </HMSRoomProvider>
    </div>
  );
}
const UnderMaintainence = () => {
  return (
    <>
      <Row style={{ height: "100vh" }} align="middle" justify="center">
        <Col md={18} lg={12}>
          <Card>
            <p className="text-center">
              <MaxLogoIcon width="130" />
            </p>
            <h2 className="text-center">App Under Maintenance</h2>
            <h4 className="text-center">
              App is under maintenance. Please try in some time!
            </h4>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default App;
