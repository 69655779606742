import { DownOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Select,
  Tag,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
var _ = require("lodash");

type Props = {
  name: string;
  heading?: string;
  onFinish: any;
  onFinishFailed: any;
  formElements: any;
  initialValues: any;
  buttonText: string;
  buttonType?: any;
  loading?: boolean;
  sub_heading?: any;
  actionType?: any;
  onValuesChange?: any;
  setValues?: any;
  onClicks?: any;
  filters?: any;
  filtersApplied?: number;
};

function FormBar({
  filters,
  onClicks,
  actionType,
  name,
  heading,
  onFinish,
  onFinishFailed,
  formElements,
  buttonText,
  initialValues,
  buttonType = "primary",
  loading = false,
  sub_heading,
  onValuesChange = () => {},
  setValues = null,
  filtersApplied = 0,
}: Props) {
  const [form] = Form.useForm();
  //console.log(filtersApplied)
  const [visiblePopover, setvisiblePopover] = useState(false);
  const handleVisibleChange = () => {
    setvisiblePopover(!visiblePopover);
  };
  form.setFieldsValue(setValues);
  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };
  const content = (
    <>
      {heading && (
        <Row
          className="bg-secondary mb-20 headColor"
          style={{ padding: "7px 15px 2px" }}
        >
          <Col>
            <h1 className="headColor">{heading}</h1>
          </Col>
        </Row>
      )}
      {sub_heading && (
        <Row style={{ padding: "7px 15px 2px" }}>
          <Col>
            <p className={"sub-title"}>{sub_heading}</p>
          </Col>
        </Row>
      )}
      <Form
        form={form}
        initialValues={initialValues}
        name={name}
        className="paddingx20  crm-form full-width"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={_.debounce(onValuesChange, 750)}
      >
        <Row gutter={10}>
          {formElements.map((ele: any, index: number) => {
            return ele.filterType === "moreFilter" ? (
              <Col
                key={index}
                {...(ele.colSpanResponsive
                  ? {}
                  : { span: ele?.colSpan ? 12 : 24 })}
                {...ele.colSpanResponsive}
              >
                <Form.Item
                  name={ele.name}
                  key={index}
                  label={ele.label}
                  rules={ele.rules ? ele.rules : []}
                >
                  {ele.type === "Select" ? (
                    <Select
                      mode={ele.mode ? ele.mode : ""}
                      className={"capitalize"}
                      showSearch={ele.showSearch ? ele.showSearch : true}
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      placeholder={ele.placeholder ? ele.placeholder : ""}
                      allowClear={ele.allowClear ? ele.allowClear : false}
                    >
                      {ele.options?.map((option: any, index: number) => {
                        return (
                          <Option
                            className={"capitalize"}
                            key={index}
                            value={option.value}
                          >
                            {option.name}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : ele.type === "DatePicker" ? (
                    <DatePicker
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                      disabledDate={
                        ele.disabledDate ? ele.disabledDate : (d) => false
                      }
                    />
                  ) : ele.type === "DateTimePicker" ? (
                    <DatePicker
                      showTime={{ format: "hh:mm A" }}
                      format="DD MMM'YY hh:mm A"
                      style={{ width: "100%" }}
                      disabledDate={
                        ele.disabledDate ? ele.disabledDate : (d) => false
                      }
                    />
                  ) : ele.type === "RangePicker" ? (
                    <RangePicker
                      format="DD MMM'YY"
                      style={{ width: "100%" }}
                      disabledDate={
                        ele.disabledDate
                          ? ele.disabledDate
                          : (d: any) => {
                              return d > moment();
                            }
                      }
                    />
                  ) : ele.type === "Divider" ? (
                    <Divider />
                  ) : ele.type === "TextArea" ? (
                    <TextArea
                      placeholder={ele.placeholder}
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                      rows={ele.rows}
                      allowClear
                    />
                  ) : ele.type === "Input" ? (
                    <Input
                      disabled={ele.disabled ? true : false}
                      placeholder={ele.placeholder ? ele.placeholder : ""}
                      prefix={ele.prefix ? ele.prefix : null}
                    />
                  ) : ele.type === "Button" ? (
                    <Button
                      onClick={() => {
                        onClicks();
                      }}
                    >
                      {ele.name}
                    </Button>
                  ) : ele.type === "InputNumber" ? (
                    <InputNumber
                      style={{ width: "100%" }}
                      min={ele.min ? ele.min : null}
                      max={ele.max ? ele.max : null}
                      disabled={ele.disabled ? true : false}
                      onChange={
                        ele.onChange ? (e) => ele.onChange(e) : () => {}
                      }
                    />
                  ) : ele.type === "Cascader" ? (
                    <Cascader
                      className={"capitalize"}
                      options={ele.data}
                      placeholder="Please select"
                    />
                  ) : ele.type === "Hidden" ? (
                    <></>
                  ) : ele.type === "Radio" ? (
                    <Radio.Group buttonStyle="solid">
                      {ele.options?.map((val: any) => {
                        return (
                          <Radio.Button
                            className={val.name.toLowerCase()}
                            value={val.value}
                          >
                            {val.name}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  ) : ele.type === "AutoComplete" ? (
                    <AutoComplete
                      dataSource={ele.AutoCompleteData}
                      onSearch={ele.onSearch}
                      onSelect={ele.onSelect}
                      placeholder={ele.placeholder}
                    />
                  ) : ele.type === "clear_filters" ? (
                    <Button onClick={ele.onClick} type={"link"}>
                      Clear Filters
                    </Button>
                  ) : (
                    ""
                  )}
                </Form.Item>
              </Col>
            ) : (
              ""
            );
          })}
        </Row>
        {buttonText !== "" && (
          <Row className="button-row">
            <Col span={24}>
              <Form.Item>
                <Button
                  className={"doctor-secondary-button mt20"}
                  loading={loading}
                  type={buttonType}
                  size="large"
                  htmlType="submit"
                  block
                >
                  {actionType === "edit" ? "Edit Contact" : buttonText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
  return (
    <>
      {filters === "multiple" ? (
        <Row gutter={10}>
          <Col span={24}>
            <Form
              form={form}
              initialValues={initialValues}
              name={name}
              className="paddingx20  crm-form full-width"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={_.debounce(onValuesChange, 750)}
            >
              <Row gutter={16}>
                {formElements.map((ele: any, index: number) => {
                  return ele.filterType !== "moreFilter" ? (
                    <Col
                      key={index}
                      span={
                        ele.colSpanResponsive
                          ? null
                          : ele?.colSpan
                          ? ele?.colSpan
                          : ele.flex === "none"
                          ? null
                          : 24
                      }
                      flex={ele.flex ? ele.flex : null}
                      {...ele.colSpanResponsive}
                    >
                      <Form.Item
                        name={ele.name}
                        key={index}
                        label={ele.label}
                        rules={ele.rules ? ele.rules : []}
                      >
                        {ele.type === "Select" ? (
                          <Select
                            mode={ele.mode ? ele.mode : ""}
                            showSearch={ele.showSearch ? ele.showSearch : true}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            placeholder={ele.placeholder ? ele.placeholder : ""}
                            allowClear={ele.allowClear ? ele.allowClear : false}
                          >
                            {ele.options?.map((option: any, index: number) => {
                              return (
                                <Option key={index} value={option.value}>
                                  {option.name}
                                </Option>
                              );
                            })}
                          </Select>
                        ) : ele.type === "DatePicker" ? (
                          <DatePicker
                            style={{ width: "100%" }}
                            format="DD MMM'YY"
                            disabledDate={
                              ele.disabledDate ? ele.disabledDate : (d) => false
                            }
                          />
                        ) : ele.type === "DateTimePicker" ? (
                          <DatePicker
                            style={{ width: "100%" }}
                            showTime={{ format: "hh:mm A" }}
                            format="DD MMM'YY hh:mm A"
                            disabledDate={
                              ele.disabledDate ? ele.disabledDate : (d) => false
                            }
                          />
                        ) : ele.type === "RangePicker" ? (
                          <RangePicker
                            style={{ width: "100%" }}
                            format="DD MMM'YY"
                            disabledDate={
                              ele.disabledDate
                                ? ele.disabledDate
                                : (d: any) => {
                                    return d > moment();
                                  }
                            }
                          />
                        ) : ele.type === "Divider" ? (
                          <Divider />
                        ) : ele.type === "TextArea" ? (
                          <TextArea
                            placeholder={ele.placeholder}
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                            rows={ele.rows}
                            allowClear
                          />
                        ) : ele.type === "Button" ? (
                          <Button
                            onClick={() => {
                              onClicks();
                            }}
                          >
                            {ele.name}
                          </Button>
                        ) : ele.type === "Input" ? (
                          <Input
                            disabled={ele.disabled ? true : false}
                            placeholder={ele.placeholder ? ele.placeholder : ""}
                            prefix={ele.prefix ? ele.prefix : null}
                          />
                        ) : ele.type === "InputNumber" ? (
                          <InputNumber
                            style={{ width: "100%" }}
                            min={ele.min ? ele.min : null}
                            max={ele.max ? ele.max : null}
                            disabled={ele.disabled ? true : false}
                            onChange={
                              ele.onChange ? (e) => ele.onChange(e) : () => {}
                            }
                          />
                        ) : ele.type === "Cascader" ? (
                          <Cascader
                            className={"capitalize"}
                            options={ele.data}
                            placeholder="Please select"
                          />
                        ) : ele.type === "Hidden" ? (
                          <></>
                        ) : ele.type === "Radio" ? (
                          <Radio.Group buttonStyle="solid">
                            {ele.options?.map((val: any) => {
                              return (
                                <Radio.Button
                                  className={val.name.toLowerCase()}
                                  value={val.value}
                                >
                                  {val.name}
                                </Radio.Button>
                              );
                            })}
                          </Radio.Group>
                        ) : ele.type === "AutoComplete" ? (
                          <AutoComplete
                            dataSource={ele.AutoCompleteData}
                            onSearch={ele.onSearch}
                            onSelect={ele.onSelect}
                            placeholder={ele.placeholder}
                          />
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  );
                })}
                <Col flex="none">
                  <Popover
                    overlayStyle={{ width: "800px" }}
                    content={content}
                    placement="bottom"
                    title="FILTERS"
                    trigger="click"
                    visible={visiblePopover}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button type="default" className="more-filters">
                      More {screenType.mobile ? "" : "Filters"}
                      {filtersApplied > 0 && (
                        <Tag
                          className="solid-full filter-applied-count-tag"
                          color="red"
                        >
                          {filtersApplied}
                        </Tag>
                      )}{" "}
                      <DownOutlined />
                    </Button>
                  </Popover>
                </Col>
              </Row>
              {buttonText !== "" && (
                <Row className="button-row">
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        loading={loading}
                        className={"doctor-secondary-button mt20"}
                        size="large"
                        type={buttonType}
                        htmlType="submit"
                        block
                      >
                        {actionType === "edit" ? "Edit Contact" : buttonText}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
      ) : (
        <>
          {heading && (
            <Row
              className="bg-secondary mb-20 headColor"
              style={{ padding: "7px 15px 2px", color: "red" }}
            >
              <Col>
                <h1 className="headColor">{heading}</h1>
              </Col>
            </Row>
          )}
          {sub_heading && (
            <Row style={{ padding: "7px 15px 2px" }}>
              <Col>
                <p className={"sub-title"}>{sub_heading}</p>
              </Col>
            </Row>
          )}
          <Form
            form={form}
            initialValues={initialValues}
            name={name}
            className="paddingx20  crm-form full-width"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={_.debounce(onValuesChange, 750)}
          >
            <Row gutter={10} align="bottom" className="cover">
              {formElements.map((ele: any, index: number) => {
                return (
                  <Col key={index} span={ele?.colSpan ? ele?.colSpan : 24}>
                    <Form.Item
                      name={ele.name}
                      key={index}
                      label={ele.label}
                      rules={ele.rules ? ele.rules : []}
                      validateStatus={
                        ele.validateStatus ? ele.validateStatus : undefined
                      }
                      help={ele.help ? ele.help : undefined}
                    >
                      {ele.type === "Select" ? (
                        <Select
                          disabled={ele.disabled ? true : false}
                          className={"capitalize"}
                          mode={ele.mode ? ele.mode : ""}
                          showSearch={ele.showSearch ? ele.showSearch : true}
                          optionFilterProp="children"
                          filterOption={(input, option: any) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          placeholder={ele.placeholder ? ele.placeholder : ""}
                          allowClear={ele.allowClear ? ele.allowClear : false}
                        >
                          {ele.options?.map((option: any, index: number) => {
                            return (
                              <Option
                                className={"capitalize"}
                                key={index}
                                value={option.value}
                              >
                                {option.name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : ele.type === "DatePicker" ? (
                        <DatePicker
                          disabled={ele.disabled ? true : false}
                          style={{ width: "100%" }}
                          format="DD MMM'YY"
                          disabledDate={
                            ele.disabledDate ? ele.disabledDate : (d) => false
                          }
                        />
                      ) : ele.type === "DateTimePicker" ? (
                        <DatePicker
                          showTime={{ format: "hh:mm A" }}
                          format="DD MMM'YY hh:mm A"
                          style={{ width: "100%" }}
                          disabledDate={
                            ele.disabledDate ? ele.disabledDate : (d) => false
                          }
                        />
                      ) : ele.type === "RangePicker" ? (
                        <RangePicker
                          style={{ width: "100%" }}
                          format="DD MMM'YY"
                          disabledDate={
                            ele.disabledDate
                              ? ele.disabledDate
                              : (d: any) => {
                                  return d > moment();
                                }
                          }
                        />
                      ) : ele.type === "Divider" ? (
                        <Divider />
                      ) : ele.type === "TextArea" ? (
                        <TextArea
                          placeholder={ele.placeholder}
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                          rows={ele.rows}
                          allowClear
                        />
                      ) : ele.type === "Input" ? (
                        <Input
                          disabled={ele.disabled ? true : false}
                          placeholder={ele.placeholder ? ele.placeholder : ""}
                          prefix={ele.prefix ? ele.prefix : null}
                        />
                      ) : ele.type === "Button" ? (
                        <Button
                          onClick={() => {
                            onClicks();
                          }}
                        >
                          {ele.name}
                        </Button>
                      ) : ele.type === "InputNumber" ? (
                        <InputNumber
                          style={{ width: "100%" }}
                          min={ele.min ? ele.min : null}
                          max={ele.max ? ele.max : null}
                          disabled={ele.disabled ? true : false}
                          onChange={
                            ele.onChange ? (e) => ele.onChange(e) : () => {}
                          }
                        />
                      ) : ele.type === "Cascader" ? (
                        <Cascader
                          className={"capitalize"}
                          options={ele.data}
                          placeholder="Please select"
                        />
                      ) : ele.type === "Hidden" ? (
                        <></>
                      ) : ele.type === "Radio" ? (
                        <Radio.Group buttonStyle="solid">
                          {ele.options?.map((val: any) => {
                            return (
                              <Radio.Button
                                className={val.name.toLowerCase()}
                                value={val.value}
                              >
                                {val.name}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      ) : ele.type === "AutoComplete" ? (
                        <AutoComplete
                          dataSource={ele.AutoCompleteData}
                          onSearch={ele.onSearch}
                          onSelect={ele.onSelect}
                          placeholder={ele.placeholder}
                        />
                      ) : (
                        ""
                      )}
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
            {buttonText !== "" && (
              <Row className="button-row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      loading={loading}
                      size="large"
                      className={"doctor-secondary-button mt20"}
                      type={buttonType}
                      htmlType="submit"
                      block
                    >
                      {actionType === "edit" ? "Edit Contact" : buttonText}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </>
      )}
    </>
  );
}
export default FormBar;
