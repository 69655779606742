import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches } from "../../../redux/reducers/branch.slice";
import {
  addDoctorCoordinator,
  fetchDoctorCoordinator,
  fetchDoctors,
  updateDoctorCoordinator
} from "../../../redux/reducers/doctor.slice";
import { fetchMasterProcedures } from "../../../redux/reducers/master.slice";
import { fetchUsers } from "../../../redux/reducers/user.slice";
import { RootState } from "../../../shared/constants";
import FormBar from "../../../shared/layouts/formbar.layout";

const { Title } = Typography;

const DoctorCordinator: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const [addCoordinator, addNewCoordinator] = useState(false as any);
  const { branches } = useSelector((state: RootState) => state.branch);
  const [isDoctor, SetIsDoctor] = useState(true as any);
  const { doctors, loading, coordinators } = useSelector(
    (state: RootState) => state.doctor || {}
  );
  const { users } = useSelector((state: RootState) => state.user);
  const [formElements, setFormData] = useState(null as any);

  const showDetails = async (data: any) => {
    await setFormData({
      heading: "Update Coordinator",
      onFinish: (values: any) => {
        let _branches = [] as any;
        values.branches.map((item: any) => _branches.push({ branch_id: item }));
        values.branches = _branches;
        onSaveCordinator(values, data.id);
      },
      initialValues: {
        user_id: data?.user_id,
        doctor_id: data?.doctor_id,
        branches: data?.branches.map((item: any) => {
          return item.branch_id;
        }),
        procedure_id: data?.procedure_id,
        status: data?.status,
      },
      buttonText: "Update Coordinator",
      formElements: [
        {
          label: "Select Coordinator",
          type: "Select",
          name: "user_id",
          disabled: true,
          rules: [{ required: true, message: "Please choose user name" }],
          colSpan: 12,
          className: "capitalize",
          options:
            users &&
            users.map((val: any) => {
              return {
                value: val?.id,
                name: val.first_name + " " + val.last_name,
              };
            }),
        },
        {
          label: "Select Doctor",
          type: "Select",
          name: "doctor_id",
          disabled: true,
          className: "capitalize",
          rules: [{ required: true, message: "Please choose doctor name" }],
          colSpan: 12,
          options:
            doctors &&
            doctors.map((val: any) => {
              return { value: val?.id, name: val.name };
            }),
        },
        {
          label: "Select Location",
          type: "Select",
          name: "branches",
          colSpan: 12,
          mode: "multiple",
          className: "capitalize",
          rules: [{ required: true, message: "Please choose Status" }],
          options:
            branches &&
            branches.map((val: any) => {
              return { value: val.id, name: val.name };
            }),
        },
        // {
        //     label: "Select Appointment Type",
        //     type: "Select",
        //     name: "procedure_id",
        //     colSpan: 12,
        //     options: procedures && procedures.map((val: any) => {
        //         return {value: val.id, name: val.name}
        //     })
        // },
        {
          label: "Select Status",
          type: "Select",
          name: "status",
          className: "capitalize",
          rules: [{ required: true, message: "Please choose Status" }],
          colSpan: 12,
          options: [
            { value: "active", name: "Active" },
            { value: "in-active", name: "In-Active" },
          ],
        },
      ],
    });
    addNewCoordinator(true);
  };

  const columns: any = [
    // {
    //     title: 'Coordinator ID',
    //     dataIndex: ['id'],
    //     className: "capitalize",
    // },
    {
      title: "Coordinator Details",
      dataIndex: ["user"],
      className: "capitalize",
      render: (key: any) => (
        <p>
          {key?.first_name} {key?.last_name} <br></br>{" "}
          <span className={"sub-text font12"}>
            {key?.country_code} {key?.phone}
          </span>
        </p>
      ),
    },
    {
      title: "Doctor Reg No.",
      dataIndex: ["doctor", "reg_no"],
      className: "capitalize",
    },
    {
      title: "Doctor Name",
      dataIndex: ["doctor", "name"],
      className: "capitalize",
    },
    {
      title: "Hospital Name",
      dataIndex: "branches",
      className: "capitalize",
      key: "branches",
      render: (key: any) =>
        key.map((branch: any) => <p> {branch?.branch?.name}</p>),
    },
    // {
    //     title: 'Appointment Type',
    //     dataIndex: ['procedure_info', 'name'],
    //     className: "capitalize",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (key: any, record: any) => (
        <Space>
          <Tag
            className="capitalize"
            color={key === "active" ? "green" : "error"}
          >
            {key}
          </Tag>
          {!isDoctor && (
            <Button type={"link"} onClick={() => showDetails(record)}>
              Edit
            </Button>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    SetIsDoctor(profile?.type === "doctor" ? true : false);
    dispatch(
      fetchDoctorCoordinator(
        profile?.type === "doctor"
          ? {
              doctor_id: profile?.id,
            }
          : {}
      )
    );
  }, []);

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchMasterProcedures());
    dispatch(
      fetchDoctors({
        type: "doctor",
        query: "",
      })
    );
    dispatch(fetchUsers({ type: "coordinator", limit: 1000 }));
  }, [dispatch]);

  const onSaveCordinator = (values: any, id: any) => {
    (async function () {
      try {
        let _data = coordinators.filter((a: any) => a?.id === id);
        if (_data.length !== 0) {
          let response = await dispatch(
            updateDoctorCoordinator({ id: id, data: values })
          );
          if (
            response.payload.status === 201 ||
            response.payload.status === 200
          ) {
            message.success("Coordinator updated");
            dispatch(
              fetchDoctorCoordinator(
                profile?.type === "doctor"
                  ? {
                      doctor_id: profile?.id,
                    }
                  : {}
              )
            );
            addNewCoordinator(false);
          }
        } else {
          let response = await dispatch(addDoctorCoordinator(values));
          if (
            response.payload.status === 201 ||
            response.payload.status === 200
          ) {
            message.success("Coordinator Added");
            dispatch(
              fetchDoctorCoordinator(
                profile?.type === "doctor"
                  ? {
                      doctor_id: profile?.id,
                    }
                  : {}
              )
            );
            addNewCoordinator(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const addNew_Coordinator = async (value: any) => {
    await setFormData({
      heading: "Add Coordinator",
      onFinish: (values: any) => {
        let _branches = [] as any;
        values.branches.map((item: any) => _branches.push({ branch_id: item }));
        values.branches = _branches;
        onSaveCordinator(values, null);
      },

      initialValues: {
        user_id: "",
        doctor_id: "",
        branches: [],
        procedure_id: "",
        status: "",
      },
      buttonText: "Add Coordinator",
      formElements: [
        {
          label: "Select Coordinator",
          type: "Select",
          name: "user_id",
          rules: [{ required: true, message: "Please choose user name" }],
          colSpan: 12,
          options:
            users &&
            users.map((val: any) => {
              return {
                value: val.id,
                name: val.first_name + " " + val.last_name,
              };
            }),
        },
        {
          label: "Select Doctor",
          type: "Select",
          name: "doctor_id",
          rules: [{ required: true, message: "Please choose doctor name" }],
          colSpan: 12,
          options:
            doctors &&
            doctors.map((val: any) => {
              return { value: val.id, name: val.name };
            }),
        },
        {
          label: "Select Location",
          type: "Select",
          name: "branches",
          colSpan: 12,
          mode: "multiple",
          rules: [{ required: true, message: "Please choose Status" }],
          options:
            branches &&
            branches.map((val: any) => {
              return { value: val.id, name: val.name };
            }),
        },
        // {
        //     label: "Select Appointment Type",
        //     type: "Select",
        //     name: "procedure_id",
        //     colSpan: 12,
        //     options: procedures && procedures.map((val: any) => {
        //         return {value: val.id, name: val.name}
        //     })
        // },
        {
          label: "Select Status",
          type: "Select",
          name: "status",
          rules: [{ required: true, message: "Please choose Status" }],
          colSpan: 12,
          options: [
            { value: "active", name: "Active" },
            { value: "in-active", name: "In-Active" },
          ],
        },
      ],
    });
    addNewCoordinator(value);
  };

  return (
    <Row>
      <Col span={24} className={"padding20 white-background"}>
        <Row>
          <Col span={12}>
            <Title className="paddingcollapse" level={4}>
              {" "}
              {profile?.type === "doctor"
                ? "Coordinator Access"
                : "Coordinators"}{" "}
            </Title>
          </Col>
          <Col span={12} className={"text-right"}>
            {!isDoctor && (
              <Button
                size="large"
                className="doctor-border-button  btn-shadow"
                onClick={() => addNew_Coordinator(true)}
              >
                + Add Coordinator
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={24} className={"mt20 padding20"}>
        {loading ? (
          <p className={"mt200 text-center"}>
            <Spin className={"mt100"}></Spin> <br></br> Fetching ...{" "}
          </p>
        ) : (
          <Table
            className={"dark-table"}
            dataSource={coordinators}
            columns={columns}
            pagination={false}
          ></Table>
        )}
      </Col>

      <Modal
        width={"50%"}
        className={"doctor-app-dark-modal"}
        footer={false}
        title={formElements?.heading}
        visible={addCoordinator}
        onCancel={() => {
          addNewCoordinator(false);
          setFormData(null);
        }}
      >
        {formElements && (
          <FormBar
            name={"createContactForm"}
            loading={loading}
            onFinish={formElements.onFinish}
            onFinishFailed={formElements.onFinishFailed}
            setValues={formElements.initialValues}
            formElements={formElements.formElements}
            initialValues={formElements.initialValues}
            buttonText={"Add Coordinator"}
          ></FormBar>
        )}
      </Modal>
    </Row>
  );
};

export default DoctorCordinator;
