export const ROUTE_PATIENT_APPOINTMENTS = `/patient/appointments`;
export const ROUTE_PATIENT_LINKING = (number: string) =>
  `/patient/link/profile/${number}`;
export const ROUTE_PATIENT_APPOINTMENT_SUPPORT = (id: string) =>
  `/patient/appointment/support/${id}`;
export const ROUTE_PATIENT_APPOINTMENT_DETAILS = (id: string) =>
  `/patient/appointment/${id}`;
export const ROUTE_PATIENT_DOCUMENTS = (type: string) =>
  `/patient/documents/${type}`;
export const ROUTE_PATIENT_WAITING_AREA = (
  room_id: string,
  source: string,
  appointment_id: string
) => `/patient/appointment/${room_id}/${source}/${appointment_id}`;
export const ROUTE_PATIENT_WAITING_AREA_ATTANDANT = (
  room_id: string,
  participant_id: string,
  appointment_id: string,
  source: any
) =>
  `/patient/appointment/${room_id}/${participant_id}/${appointment_id}/${source}`;
export const ROUTE_PATIENT_DASHBOARD = "/patient/dashboard";
export const PATIENTS_DEEP_LINK = (id: string) =>
  `/patient/deep-link/appointment/${id}/patient`;

//// APIS
export const PATIENTS = `patients`;
export const PATIENTS_BY_NUMBER = (number: string) =>
  `get-patients-by-mobile/${number}`;
export const LINK_PATIENT_BY_NUMBER = `patients/update-patient-user-profile`;
export const PATIENTS_RELATIONSHIPS = `patients/relationship`;
export const PATIENT_BY_ID = (id: string) => `patients/${id}`;
export const PATIENTS_DOCUMENTS = `patients/document`;
export const PATIENTS_DOCUMENTS_ADD = `patients/document/add`;
export const PATIENTS_DOCUMENTS_DELETE = (id: string) =>
  `patients/document/${id}`;
export const PATIENT_DOCUMENT_FILE_BY_ID = (id: string) =>
  `patients/document/url/${id}`;
export const PATIENT_DOCUMENT_QC_STATUS = `patients/document/qc`;
export const PATIENT_DOCUMENT_REORDER = `patients/document/order`;
export const PATIENTS_RELATIONSHIPS_NON_LINKED = `patients/relationship/non-linked-members`;
export const PATIENTS_RELATIONSHIPS_LINK = `patients/relationship/add`;
