import { Pagination } from "antd";

type Props = {
  skip?: string;
  take?: string;
  total?: any;
  onChange: (values: string) => void;
  defaultCurrent?: number;
  showTotal?: boolean;
};

function PaginationLayout({
  showTotal = false,
  onChange,
  total,
  defaultCurrent,
}: Props) {
  console.log("showTotal::", showTotal);
  return (
    <Pagination
      showTotal={(total, range) => {
        return showTotal === true ? `${range[0]}-${range[1]} of ${total} ` : "";
      }}
      responsive={true}
      simple={window.innerWidth <= 900}
      size={window.innerWidth <= 1200 ? "small" : "default"}
      showSizeChanger={false}
      defaultCurrent={defaultCurrent}
      showLessItems
      total={parseInt(total)}
      onChange={(evt: any) => onChange(evt)}
    />
  );
}

export default PaginationLayout;
