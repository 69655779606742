type CallerFunction = (...args: any[]) => any | Promise<any>;

interface WrapperParams {
  loading_slider: (loading: boolean) => void;
  setting_status: (status: string) => void;
  status: string;
  func: CallerFunction;
  args?: any[]; // Optional arguments for the caller function
}

async function executeWithLoaderAndStatus({
  loading_slider,
  setting_status,
  status,
  func,
  args = [], // Default to an empty array if no args are provided
}: WrapperParams): Promise<any> {
  try {
    // start the loading slider and set the status
    loading_slider(true);
    setting_status(status);

    // call the provided fucntion with args
    const result = await Promise.resolve(func(...args));

    // stop the loading slider after the function completion
    loading_slider(false);

    return result;
  } catch (error) {
    loading_slider(false);
    throw error;
  }
}

export default executeWithLoaderAndStatus;
