const EyeIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_812_677"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="15"
      >
        <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_812_677)">
        <path
          d="M6.99992 9.83325C7.72908 9.83325 8.34888 9.57804 8.85929 9.06763C9.36971 8.55721 9.62492 7.93742 9.62492 7.20825C9.62492 6.47909 9.36971 5.85929 8.85929 5.34888C8.34888 4.83846 7.72908 4.58325 6.99992 4.58325C6.27075 4.58325 5.65096 4.83846 5.14054 5.34888C4.63013 5.85929 4.37492 6.47909 4.37492 7.20825C4.37492 7.93742 4.63013 8.55721 5.14054 9.06763C5.65096 9.57804 6.27075 9.83325 6.99992 9.83325ZM6.99992 8.78325C6.56242 8.78325 6.19054 8.63013 5.88429 8.32388C5.57804 8.01763 5.42492 7.64575 5.42492 7.20825C5.42492 6.77075 5.57804 6.39888 5.88429 6.09263C6.19054 5.78638 6.56242 5.63325 6.99992 5.63325C7.43742 5.63325 7.80929 5.78638 8.11554 6.09263C8.42179 6.39888 8.57492 6.77075 8.57492 7.20825C8.57492 7.64575 8.42179 8.01763 8.11554 8.32388C7.80929 8.63013 7.43742 8.78325 6.99992 8.78325ZM6.99992 11.5833C5.58047 11.5833 4.28742 11.1871 3.12075 10.3947C1.95409 9.60235 1.10825 8.5402 0.583252 7.20825C1.10825 5.87631 1.95409 4.81415 3.12075 4.02179C4.28742 3.22943 5.58047 2.83325 6.99992 2.83325C8.41936 2.83325 9.71242 3.22943 10.8791 4.02179C12.0458 4.81415 12.8916 5.87631 13.4166 7.20825C12.8916 8.5402 12.0458 9.60235 10.8791 10.3947C9.71242 11.1871 8.41936 11.5833 6.99992 11.5833Z"
          fill="#00B5AD"
        />
      </g>
    </svg>
  );
};
export default EyeIcon;
