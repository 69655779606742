import { Col, Row, Skeleton, Space } from "antd";

export type TOPDPatientFiltersTabs =
  | "checked-in"
  | "done"
  | "all"
  | "no-show cancelled";

// D is generic which is a subtype of string (which allows for literal types like "checked-in")
export type TPatientFilterData<D extends string> = Record<
  D,
  {
    title: string;
    noOfPatients: number;
  }
>;

interface IPatientFilterTabsProps {
  onChangeFilter: (d: TOPDPatientFiltersTabs) => void;
  patientFiltersData: TPatientFilterData<TOPDPatientFiltersTabs>;
  selectedFilter: string;
  loading: boolean;
}

const PatientFilterTabs = ({
  onChangeFilter,
  selectedFilter,
  patientFiltersData,
  loading,
}: IPatientFilterTabsProps) => {
  return (
    <Row
      style={{
        width: "calc(100% + 40px)",
        marginLeft: "-20px",
        zIndex: "-1000",
      }}
    >
      {loading ? (
        <Space
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",

            width: "100%",
            height: "74px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Skeleton.Button active size="default" shape="default" />
          <Skeleton.Button active size="default" shape="default" />
          <Skeleton.Button active size="default" shape="default" />
          <Skeleton.Button active size="default" shape="default" />
        </Space>
      ) : (
        (
          Object.entries(patientFiltersData) as [
            TOPDPatientFiltersTabs,
            TPatientFilterData<TOPDPatientFiltersTabs>[TOPDPatientFiltersTabs]
          ][]
        ).map(([key, val]) => {
          const isSelected = selectedFilter === key;

          return (
            <Col
              span={6}
              style={{
                height: "74px",
              }}
              className="patient_tab_col"
            >
              <div
                className="patient_tab"
                onClick={() => {
                  onChangeFilter(key);
                }}
                data-isSelected={isSelected}
              >
                <div className="doctor-primary-color bold stat">
                  {val.noOfPatients}
                </div>
                <div className="doctor-primary-color font12"> {val.title}</div>
              </div>
            </Col>
          );
        })
      )}
    </Row>
  );
};

export { PatientFilterTabs };
