import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Affix,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  message,
  Modal,
  Progress,
  Radio,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import axios from "axios";
import { t } from "i18next";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../../assets/icons/backicon.svg";
import { ReactComponent as SuccessMessage } from "../../../../assets/icons/successMessage.svg";
import {
  addNewAppointemnt,
  checkoutAppointment,
  fetchAppointment,
  fetchAppointmentById,
  resetAppointments,
  setVideoRoom,
  updateAppointmentById,
  validateAppointmentCall,
} from "../../../../redux/reducers/appointment.slice";
import { fetchDoctorAvailSlots } from "../../../../redux/reducers/doctor.slice";
import {
  fetchPatientsDocuments,
  reset,
} from "../../../../redux/reducers/patients.slice";
import {
  addPrescriptions,
  fetchAllPrescriptions,
  fetchPrescriptions,
  fetchPrescriptionsLabReports,
} from "../../../../redux/reducers/prescription.slice";
import {
  fetchBlockShiftEntries,
  fetchPrevoiusSlots,
  fetchSlotById,
  updateDoctorSlotConfigById,
} from "../../../../redux/reducers/slot.slice";
import {
  fetchPatientDocumentFileById,
  fetchUploadedFileById,
  uploadFile,
} from "../../../../redux/reducers/upload.slice";
import { fetchRemoteConfigValue, remoteConfig } from "../../../../firebase";
import { RootState } from "../../../../shared/constants";
import ChatLayout from "../../../../shared/layouts/chat.layout";
import FileViewLayout from "../../../../shared/layouts/file-view.layout";
import FilesLayout from "../../../../shared/layouts/files.layout";
import PatientNameCard from "../../../../shared/layouts/name-card";
import NoDataLayout from "../../../../shared/layouts/no-data.layout";
import ReportLayout from "../../../../shared/layouts/report-issue.layout";
import UploadLayout from "../../../../shared/layouts/upload.layout";
import { ROUTE_DOCTOR_APPOINTMENTS_CALL } from "../../../../shared/routes/doctor.routes.constants";
import {
  formatOnlyDate,
  getAge,
  getDayFormat,
} from "../../../../shared/Utils/utilities";
import DoctorSlots from "../../../admin/Doctors/components/doctor-slots";
import DynamicFormContainer from "../../../admin/PrescriptionTemplates/Builder/Components/ProcedureFromView/DynamicFormContainer";
import PatientList from "../../Patients/patients.page";
import DoctorAddAppointment from "./add-appointment";
import AppointmentDetails from "./appointment-details";
import AppointmentRecords from "./appointment-records";
import BlockShiftModal from "./block-shift";
import ExtraMenu from "./extra-menu";
import MarkDelayModal from "./mark-delay";
import { MarkLeaveModal, UnmarkLeaveModal } from "./mark-leave";
import {
  PatientFilterTabs,
  TOPDPatientFiltersTabs,
  TPatientFilterData,
} from "./patientFilterTabs";
import PracticeButton from "./practice-button";
import PrepareCaseSummary from "./prepareCaseSummary";

const { Text } = Typography;
var _ = require("lodash");

const OPDAppointments: React.FunctionComponent<any> = () => {
  useEffect(() => {
    fetchRemoteConfigValue("extra_menu_options")
      .then((res) => {
        setUseExtraMenuFlag(res?.asBoolean() ?? false);
      })
      .catch((err) => console.log(err));
  }, []);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.profile);
  const [chat, setChat] = useState(false);
  const [call, joinCall] = useState(false);
  const [useExtraMenuFlag, setUseExtraMenuFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [seletectappointment, setAppointment] = useState("" as any);
  const [detail, setDetails] = useState(false);
  const [hideAuto, sethideAuto] = useState(true);
  const [, setWebCamEnabled] = useState(true);
  const [addAppointment, setAddAppointment] = useState(false);
  const [loader, setLoader] = useState(false);
  const { appointments, appointment, pipEnabled } = useSelector(
    (state: RootState) => state.appointment
  );

  const filters = _.groupBy(appointments, "latest_status");

  const { prescriptions, allPrescriptions, labreports } = useSelector(
    (state: RootState) => state.prescription
  );
  const { Documents, loading } = useSelector(
    (state: RootState) => state.patient
  );

  const [fileData, setFileData] = useState([] as any);
  const [prescriptionFinalModal, setPrescriptionFinalModal] = useState(false);
  const { signedUrl } = useSelector((state: RootState) => state.upload);
  const { slot, old_slots, slots_loader } = useSelector(
    (state: RootState) => state.slot
  );
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const _appointmentData: any = location?.state || {};
  const [fileviewer, setFileViewer] = useState(false);
  const [templateViewer, setTemplateViewer] = useState(false);
  const [templateFormViewer, setTemplateFormViewer] = useState(false);
  const [selectedTemplateData, setSelectedTemplateData] = useState([] as any);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const { doctor_avail_slots, slots_loader: _slots_loader } = useSelector(
    (state: RootState) => state.doctor || {}
  );

  const [blockShiftEntries, setBlockShiftEntries] = useState([] as any);
  const [blockShift, setBlockShift] = useState(false as any);
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [newDate, setNewDate] = useState(moment(_appointmentData?.date) as any);
  const [markDelay, setMarkDelay] = useState(false as any);
  const [markLeave, setMarkLeave] = useState(false as any);
  const [unmarkLeave, setUnmarkLeave] = useState(false as any);

  const [resheduleAppointment, setReSheduleAppointment] = useState(false);
  const [oldSlot, setNewSlot] = useState(null as any);
  const [documents, setdocuments] = useState(false);
  const [loadingSlider, setLoadingSlider] = useState(false);
  const [status, setStatus] = useState(null as any);
  const [_file, setFile] = useState(null as any);
  const [uploading, setUploading] = useState(false as any);
  const [uploaderror, setUploadError] = useState("" as any);
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    "doctor" as any
  );
  const [oldAppointments, setOldAppointments] = useState(false as any);
  const [support, setSupport] = useState(false as any);
  const [totalSlots, setTotalSlots] = useState(null as any);
  const { prescriptionTemplate, prescriptionLoader } = useSelector(
    (state: RootState) => state.prescription
  );
  const [appointmentsLoader, setAppointmentsLoader] = useState(true as any);
  const [caseSumary, setCaseSummary] = useState(false);
  const [qcPass, setqcPass] = useState(false);
  const { connection } = useSelector((state: RootState) => state.chat);
  const [chatCount, setChatCount] = useState(null as any);
  const [allMessages, setAllMessages] = useState([] as any);
  const [ip, setIP] = useState("");
  const [fileLoader, setFileLoader] = useState(true);
  const [intialFilter, setFilter] = useState(
    (filters["checked-in"] ? filters["checked-in"].length : 0) +
      (filters["consulting"] ? filters["consulting"].length : 0) >
      0
      ? "checked-in"
      : "all"
  );


  const previousApptDate =
    _appointmentData?.date < moment().format("YYYY-MM-DD");

  useEffect(() => {
    getData();
  }, []);

  const showAppointmentDetails = useCallback(
    (item: any, loader: boolean) => {
      async function getAppointment(item: any) {
        try {
          if (loader) {
            setLoader(true);
          }

          dispatch(
            fetchPrescriptions({
              appointment_id: item?.id,
              patient_id: item?.patient_id,
              type: "PRESCRIPTION",
            })
          );
          dispatch(
            fetchAllPrescriptions({
              patient_id: item?.patient_id,
              type: "PRESCRIPTION",
            })
          );
          dispatch(
            fetchPrescriptionsLabReports({
              patient_id: item?.patient_id,
              historical: "1",
            })
          );

          const response = await dispatch(fetchAppointmentById(item?.id));
          if (response.payload.status === 200) {
            setDetails(true);
            setdocuments(false);
            setCaseSummary(false);
            setAddAppointment(false);
            setAppointment(response?.payload?.data?.data);
            setLoader(false);
          }
          const docs = await dispatch(
            fetchPatientsDocuments({
              appointment_id: item?.id,
              patient_id: item?.patient_id,
            })
          );
          if (docs?.payload?.data?.data?.qc_done_txt) {
            setqcPass(docs?.payload?.data?.data?.qc_done_txt);
          }
        } catch (e) {
          console.error(e);
          setLoader(false);
        }
      }
      getAppointment(item);
    },
    [dispatch]
  );

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const cancelPrescriptionUpload = useCallback(() => {
    setStatus("Fetching Uploaded Data");
    setLoadingSlider(true);
    setUploading(false);
    sethideAuto(true);
    setUploadError("");
    setUploadPercentage(0);
    setPrescriptionFinalModal(false);
    setTimeout(() => {
      dispatch(
        fetchPrescriptions({
          appointment_id: appointment?.id,
          patient_id: appointment?.patient_id,
          type: "PRESCRIPTION",
        })
      );
      setLoadingSlider(false);
    }, 2000);
  }, [appointment?.id, appointment?.patient_id, dispatch]);

  useEffect(() => {
    if (uploadPercentage === 100) {
      sethideAuto(false);
    }
  }, [uploadPercentage]);

  useEffect(() => {
    if (hideAuto !== true) {
      setTimeout(() => {
        cancelPrescriptionUpload();
      }, 3000);
    }
  }, [cancelPrescriptionUpload, hideAuto]);

  useEffect(() => {
    if (location.state) {
      (async function () {
        try {
          let response = null;
          if (_appointmentData?.doctor_slot_config_id) {
            response = await dispatch(
              fetchAppointment({
                appointment_date: _appointmentData?.date,
                branch_id: _appointmentData?.branch,
                doctor_slot_config_id: _appointmentData?.doctor_slot_config_id,
                limit: 1000,
              })
            );
            dispatch(fetchSlotById(_appointmentData?.doctor_slot_config_id));
          } else {
            response = await dispatch(
              fetchAppointment({
                appointment_date: _appointmentData?.date,
                branch_id: _appointmentData?.branch,
                limit: 100,
              })
            );
          }
          if (_appointmentData?.is_appointment) {
            const _response = await dispatch(
              fetchAppointmentById(_appointmentData.appointment_id)
            );
            if (_response.payload.status === 200) {
              let data = _response?.payload?.data?.data;
              if (data?.latest_status === "cancelled") {
                setFilter("no-show cancelled");
              } else if (data?.latest_status === "checked-in") {
                setFilter("checked-in");
              } else if (data?.latest_status === "done") {
                setFilter("done");
              } else {
                setFilter("all");
              }
              showAppointmentDetails(_response?.payload?.data?.data, true);
            }
          }
          // dispatch(fetchAllTemplates());
          if (response.payload.status === 200) {
            setAppointmentsLoader(false);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }

    return () => {
      dispatch(resetAppointments());
    };
  }, [
    _appointmentData.appointment_id,
    _appointmentData?.branch,
    _appointmentData?.date,
    _appointmentData?.doctor_slot_config_id,
    _appointmentData?.is_appointment,
    dispatch,
    location.state,
    showAppointmentDetails,
  ]);

  useEffect(() => {
    const wsCurrent = connection;

    if (!wsCurrent) return;
    wsCurrent.onmessage = (e: any) => {
      const _message = JSON.parse(e.data);
      if (_message.type === "appointment_update") {
        if (_appointmentData?.doctor_slot_config_id) {
          dispatch(
            fetchAppointment({
              appointment_date: _appointmentData?.date,
              branch_id: _appointmentData?.branch,
              doctor_slot_config_id: _appointmentData?.doctor_slot_config_id,
              limit: 100,
            })
          );
        }

        if (seletectappointment) {
          if (_message?.data?.appointment_id === seletectappointment?.id) {
            showAppointmentDetails(seletectappointment, false);
          }
        }
        return;
      }
      if (_message.user_id === seletectappointment?.patient_id) {
        setChatCount(1);
      }
      setAllMessages((prevState: any) => [...prevState, _message]);
      var element = document.getElementById("box") as any;
      element &&
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
    };
  }, [
    _appointmentData?.branch,
    _appointmentData?.date,
    _appointmentData?.doctor_slot_config_id,
    connection,
    dispatch,
    seletectappointment,
    showAppointmentDetails,
  ]);

  useEffect(() => {
    setTotalSlots(doctor_avail_slots);
  }, [doctor_avail_slots]);

  const filterAppointments = useCallback((type: any) => {
    setFilter(type);
    setAppointmentsLoader(false);
  }, []);

  const AddAppointment = (values: any) => {
    (async function () {
      try {
        const response = await dispatch(addNewAppointemnt(values));
        if (response.payload.status === 201) {
          if (response?.payload?.data?.statusCode === 200) {
            message.success("Created Successfully");
            setAddAppointment(false);
            if (seletectappointment !== "") {
              dispatch(fetchAppointmentById(seletectappointment?.id));
              setDetails(true);
            }
            dispatch(reset());
          } else if (response?.payload?.data?.statusCode === 400) {
            message.error(response?.payload?.data?.message);
          } else {
            message.error(
              response?.payload?.data?.message ||
                "Not able to book slot. Please try later."
            );
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const cancelVideoModal = () => {
    setWebCamEnabled(false);
    joinCall(false);
  };

  const showPrescriptionData = (file: any) => {
    setFileData(file);
    setPrescriptionFinalModal(true);
  };

  const setJoinCall = (item: any) => {
    (async function () {
      try {
        const response = await dispatch(
          validateAppointmentCall({
            room_id: item.room_id,
            doctor_id: item.doctor_id,
          })
        );

        if (
          response?.payload?.status === 201 ||
          response?.payload?.status === 200
        ) {
          (async function () {
            try {
              // dispatch(
              //   submitCallLogs({
              //     event: "join",
              //     user_type: "doctor",
              //     room: appointment?.room_id,
              //     user_id: profile?.id,
              //     device_type: "Browser",
              //     device_name: window.navigator.appCodeName,
              //     device_ip: ip,
              //     device_os: window.navigator.appVersion,
              //     reason: "Doctor Joined on Web"
              //   })
              // );

              if (appointment.latest_status === "consulting") {
                setWebCamEnabled(false);
                showAppointmentDetails &&
                  showAppointmentDetails(appointment, true);
                cancelVideoModal && cancelVideoModal();
                localStorage.setItem(
                  "vc-route",
                  ROUTE_DOCTOR_APPOINTMENTS_CALL(
                    appointment?.room_id,
                    "doctor",
                    appointment?.id
                  )
                );
                dispatch(
                  setVideoRoom({
                    id: appointment?.room_id,
                    source: "doctor",
                    appointment: appointment?.id,
                  })
                );
              } else {
                let _appointmentFinal = {
                  status: "consulting",
                };

                const response = await dispatch(
                  updateAppointmentById({
                    id: appointment?.id,
                    data: _appointmentFinal,
                  })
                );
                if (response.payload.status === 200) {
                  setWebCamEnabled(false);
                  showAppointmentDetails &&
                    showAppointmentDetails(appointment, true);
                  cancelVideoModal && cancelVideoModal();
                  localStorage.setItem(
                    "vc-route",
                    ROUTE_DOCTOR_APPOINTMENTS_CALL(
                      appointment?.room_id,
                      "doctor",
                      appointment?.id
                    )
                  );
                  dispatch(
                    setVideoRoom({
                      id: appointment?.room_id,
                      source: "doctor",
                      appointment: appointment?.id,
                    })
                  );
                }
              }
            } catch (e) {
              console.error(e);
            }
          })();
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  // created this new function similar to the one above, to use prop item
  const setStartVideoConsultation = useCallback(
    (item: any) => {
      (async function () {
        try {
          const response = await dispatch(
            validateAppointmentCall({
              room_id: item.room_id,
              doctor_id: item.doctor_id,
            })
          );

          if (
            response?.payload?.status === 201 ||
            response?.payload?.status === 200
          ) {
            (async function () {
              try {
                if (item.latest_status === "consulting") {
                  setWebCamEnabled(false);
                  showAppointmentDetails && showAppointmentDetails(item, true);
                  cancelVideoModal && cancelVideoModal();
                  localStorage.setItem(
                    "vc-route",
                    ROUTE_DOCTOR_APPOINTMENTS_CALL(
                      item?.room_id,
                      "doctor",
                      item?.id
                    )
                  );
                  dispatch(
                    setVideoRoom({
                      id: item?.room_id,
                      source: "doctor",
                      appointment: item?.id,
                    })
                  );
                } else {
                  let _appointmentFinal = {
                    status: "consulting",
                  };
                  const response = await dispatch(
                    updateAppointmentById({
                      id: item?.id,
                      data: _appointmentFinal,
                    })
                  );
                  if (response.payload.status === 200) {
                    setWebCamEnabled(false);
                    showAppointmentDetails &&
                      showAppointmentDetails(item, true);
                    cancelVideoModal && cancelVideoModal();
                    localStorage.setItem(
                      "vc-route",
                      ROUTE_DOCTOR_APPOINTMENTS_CALL(
                        item?.room_id,
                        "doctor",
                        item?.id
                      )
                    );
                    dispatch(
                      setVideoRoom({
                        id: item?.room_id,
                        source: "doctor",
                        appointment: item?.id,
                      })
                    );
                  }
                }
              } catch (e) {
                console.error(e);
              }
            })();
          }
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [dispatch, showAppointmentDetails]
  );

  // TODO: Add a function to remove Prescription from the uploaded list
  const removePrescription = () => {
    console.log("removePrescription");
  };

  const uploadPrescription = () => {
    (async function () {
      try {
        setUploading(true);
        const response = await dispatch(
          uploadFile({
            file_name: fileData.name,
            type: "PRESCRIPTION",
            content_type:
              fileData.type.split("/")[0] === "image" ? "image" : "pdf",
          })
        );

        if (response.payload.status === 201) {
          return new Promise((resolve, reject) => {
            const uploadFile = fileData;
            let reader = new FileReader();
            reader.onload = () => {
              resolve(
                axios
                  .request({
                    headers: {
                      "Content-Type": uploadFile.type, // This is set to application/flac
                    },
                    method: "PUT",
                    data: reader.result,
                    url: response?.payload?.data?.data?.url,
                    timeout: 0,
                    onUploadProgress: function (progressEvent: any) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      setUploadPercentage(percentCompleted);
                    },
                  })
                  .then(async function () {
                    const upload = await dispatch(
                      addPrescriptions({
                        upload_id: response?.payload?.data?.data?.id,
                        appointment_id: seletectappointment.id,
                        patient_id: seletectappointment.patient_id,
                        date: moment().format("YYYY-MM-DD"),
                        type: "PRESCRIPTION",
                      })
                    );
                    if (upload.error) {
                      setUploading(true);
                      setUploadError(upload.error);
                      return;
                    }
                    if (upload.payload.status === 201) {
                    }
                  })
              );
            };
            reader.onerror = () => {
              reject(/*...*/);
            };
            reader.readAsArrayBuffer(uploadFile);
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const createEmptyBlockSlotEntry = useCallback(() => {
    const newEntry = {
      start_time: "",
      end_time: "",
      reason: "",
      is_blocked: true,
      id: null,
    };
    setBlockShiftEntries([...blockShiftEntries, newEntry]);
  }, [blockShiftEntries]);

  const openBlockShiftModal = () => {
    fetchExistingBlockShiftEntries().then((r) => setBlockShift(true));
  };

  const fetchExistingBlockShiftEntries = useCallback(async () => {
    try {
      const formattedDate = moment(_appointmentData.date).format("YYYY-MM-DD");
      const data = {
        doctor_id: profile.id,
        date: formattedDate,
        branch_id: _appointmentData?.branch,
      };
      const response: any = await dispatch(fetchBlockShiftEntries(data));

      const payload = response.payload;
      if (payload.status === 200) {
        const local_blockShiftEntries: Array<{
          start_time: string;
          end_time: string;
          reason: string;
          id: string;
          doctor_slot_config_id: string;
          is_blocked: boolean;
        }> = [];
        const data = payload.data?.data || [];

        data.forEach((slot: any) => {
          const { start_time, end_time, blockSlotData } = slot;
          if (
            start_time === _appointmentData.start &&
            end_time === _appointmentData.end
          ) {
            blockSlotData.forEach((block: any) => {
              local_blockShiftEntries.push({
                start_time: block.start_time,
                end_time: block.end_time,
                reason: block.reason,
                id: block.id,
                doctor_slot_config_id: block.doctor_slot_config_id,
                is_blocked: true,
              });
            });
          }
        });

        if (local_blockShiftEntries.length !== 0)
          setBlockShiftEntries(local_blockShiftEntries);
        if (
          local_blockShiftEntries.length === 0 &&
          blockShiftEntries.length === 0
        )
          createEmptyBlockSlotEntry();
      } else {
        message.error("Error occured while fetching the block shifts.");
        console.log(data);
      }
    } catch (error) {
      console.error("Error fetching block shift entries:", error);
    }
  }, [
    _appointmentData?.branch,
    _appointmentData.date,
    _appointmentData.end,
    _appointmentData.start,
    blockShiftEntries.length,
    createEmptyBlockSlotEntry,
    dispatch,
    profile.id,
  ]);

  const setNoShow = useCallback(() => {
    (async function () {
      try {
        let _appointmentFinal = {
          status: "no-show",
        };
        const response = await dispatch(
          updateAppointmentById({ id: appointment.id, data: _appointmentFinal })
        );
        setShow(false);
        setStatus("Setting No-Show");
        setLoadingSlider(true);
        if (
          response.payload.status === 200 ||
          response.payload.status === 201
        ) {
          dispatch(fetchAppointmentById(seletectappointment?.id));
          setLoadingSlider(false);
          message.success("Appointment No Show Successfully");
        }
      } catch (e) {
        setLoadingSlider(false);
      }
    })();
  }, [appointment.id, dispatch, seletectappointment?.id]);

  const selectTemplate = useCallback(async () => {
    //setTemplateFormViewer(true);
    //setTemplateViewer(false);
    try {
      let prescriptionTemplateData = prescriptionTemplate.find(
        (pTemplate: { template_data_id: string }) =>
          pTemplate.template_data_id === selectedTemplateId
      );
      if (prescriptionTemplateData) {
        setTemplateFormViewer(true);
        setTemplateViewer(false);
        let selectedTemplateDataResponse = await fetch(
          prescriptionTemplateData.url
        );
        let selectedTemplateDataRaw = await selectedTemplateDataResponse.json();
        setSelectedTemplateData(selectedTemplateDataRaw);
      }
      /* console.log("prescriptionTemplateData",prescriptionTemplate,selectedTemplateId,prescriptionTemplateData);
             let data = await dispatch(geTemplateUrl(selectedTemplateId))
             if(data&&data.payload&&data.payload.status==200){
                 console.log("selectTemplate Check",data.payload,data.payload.status)
                 console.log("selectTemplate",prescriptionTemplateUrl);

                 let selectedTemplateDataResponse = await fetch(prescriptionTemplateUrl);
                 let selectedTemplateDataRaw = await selectedTemplateDataResponse.json();
                 setSelectedTemplateData(selectedTemplateDataRaw);
                 console.log("selectedTemplateData",selectedTemplateData);
             }*/
    } catch (e) {
      console.log("selectTemplate e", e);
    }
  }, [prescriptionTemplate, selectedTemplateId]);

  const showFileDetails = useCallback(
    (item: any, type: any) => {
      (async function () {
        try {
          setFileViewer(true);
          setFileLoader(true);
          let response;
          if (type === "doctor") {
            response = await dispatch(
              fetchUploadedFileById({
                type: item.type,
                id: item.document_data_id || item.data_id,
              })
            );
          } else {
            response = await dispatch(fetchPatientDocumentFileById(item.id));
          }
          if (
            response.payload.status === 200 ||
            response.payload.status === 201
          ) {
            setFile(item);
            setFileViewer(true);
            setFileLoader(false);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [dispatch]
  );

  const reSheduleAppointment = () => {
    (async function () {
      try {
        const response = await dispatch(
          fetchDoctorAvailSlots({
            doctor_id: appointment.doctor_id,
            branch_id: appointment.branch_id,
            procedure_id: appointment.procedure_id,
            old_appointment_id: appointment.id,
            fee: appointment.amount,
          })
        );
        if (response.payload.status === 200) {
          setReSheduleAppointment(true);
        }
      } catch (e) {
        message.error("Error in rescheduling appointment");
        console.error(e);
      }
    })();
  };

  const onSubmit = (slot: any, procedure: any, branch: any, current: any) => {
    let data: any = {
      source: "web",
      procedure_id: procedure?.procedure_id,
      slot_id: slot?.slot_id,
      slot_time: slot?.s_time,
      procedure_type: procedure?.procedure_name,
      branch_id: branch?.branch_id === undefined ? null : branch?.branch_id,
      fee: branch?.fee,
      slot_date: current,
      external_procedure_id: procedure?.external_procedure_id,
      doctor_id: appointment?.doctor?.id,
      patient_id: appointment?.patient?.id,
    };
    setNewSlot(data);
  };

  const rescheduleAppointment = () => {
    if (oldSlot === null) {
      message.error("Choose Time Slot");
      return;
    }

    (async function () {
      try {
        oldSlot.status = "re-scheduled";
        setReSheduleAppointment(false);
        setLoadingSlider(true);
        const response = await dispatch(
          updateAppointmentById({ id: appointment.id, data: oldSlot })
        );
        setStatus("Rescheduling Appintment");
        if (response.payload.status === 200) {
          dispatch(fetchAppointmentById(seletectappointment?.id));
          if (_appointmentData?.doctor_slot_config_id) {
            dispatch(
              fetchAppointment({
                appointment_date: _appointmentData?.date,
                branch_id: _appointmentData?.branch,
                doctor_slot_config_id: _appointmentData?.doctor_slot_config_id,
                limit: 100,
              })
            );
          } else {
            dispatch(
              fetchAppointment({
                appointment_date: _appointmentData?.date,
                branch_id: _appointmentData?.branch,
                limit: 100,
              })
            );
          }
          setLoadingSlider(false);
          message.success(response?.payload?.data?.data?.message);
        } else {
          setLoadingSlider(false);
        }
      } catch (e) {
        setLoadingSlider(false);
      }
    })();
  };

  const startConsultation = (type: any, pass: any) => {
    (async function () {
      try {
        setOldAppointments(false);
        if (type === "start" && !pass) {
          var response2;
          const response = await dispatch(
            fetchPrevoiusSlots(_appointmentData.doctor_slot_config_id)
          );
          if (response.payload.status === 200) {
            if (response.payload.data.data.length === 0) {
              response2 = await dispatch(
                updateDoctorSlotConfigById({
                  doctor_slot_config_id: _appointmentData.doctor_slot_config_id,
                  practice_status: type,
                })
              );
              setStatus(type + "ing your practice...");
              setLoadingSlider(true);

              if (response2.payload.status === 201) {
                setTimeout(() => {
                  dispatch(
                    fetchSlotById(_appointmentData?.doctor_slot_config_id)
                  );
                  setLoadingSlider(false);
                }, 2000);
              }
            } else {
              setOldAppointments(true);
            }
          }
          return;
        }
        response2 = await dispatch(
          updateDoctorSlotConfigById({
            doctor_slot_config_id: _appointmentData.doctor_slot_config_id,
            practice_status: type,
          })
        );
        if (type === "stop") {
          type = "Stopp";
        }
        setStatus(
          type === "pause"
            ? "pausing your practice..."
            : type + "ing your practice..."
        );
        setLoadingSlider(true);
        if (response2.payload.status === 201) {
          setTimeout(() => {
            dispatch(fetchSlotById(_appointmentData?.doctor_slot_config_id));
            setLoadingSlider(false);
          }, 2000);
        }
      } catch (e) {
        setLoadingSlider(false);
        console.error(e);
      }
    })();
  };

  // Helper function to update slot and refresh data
  const updateSlotAndRefresh = async (type: string) => {
    const response = await dispatch(
      updateDoctorSlotConfigById({
        doctor_slot_config_id: _appointmentData.doctor_slot_config_id,
        practice_status: type,
      })
    );

    if (response.payload.status === 201) {
      setStatus(getStatusMessage(type));
      setLoadingSlider(true);

      setTimeout(() => {
        dispatch(fetchSlotById(_appointmentData?.doctor_slot_config_id));
        setLoadingSlider(false);
      }, 2000);
    }
  };

  // Helper function to get status message
  const getStatusMessage = (type: string) => {
    if (type === "stop") return "Stopping your practice...";
    if (type === "pause") return "Pausing your practice...";
    return `${type}ing your practice...`;
  };

  const openaddAppointment = () => {
    setAddAppointment(true);
    setDetails(false);
    setdocuments(false);
  };

  const openFilesUploaded = (type: any) => {
    setSelectedDocumentType(type);
    setAddAppointment(false);
    setDetails(false);
    setdocuments(true);
  };

  const openDetails = () => {
    setAddAppointment(false);
    setDetails(true);
    setdocuments(false);
  };

  const checkinAppointment = (item?: any) => {
    (async function () {
      try {
        setStatus("Starting  Consultation");
        setLoadingSlider(true);
        let _appointmentFinal = {
          status: "consulting",
        };
        const response = await dispatch(
          updateAppointmentById({
            id: item?.id ?? seletectappointment?.id,
            data: _appointmentFinal,
          })
        );
        if (response.payload.status === 200) {
          dispatch(fetchAppointmentById(item?.id ?? seletectappointment?.id));
          setLoadingSlider(false);
        }
      } catch (e) {
        setLoadingSlider(false);
        console.error(e);
      }
    })();
  };

  // const checkIfAppointmentsExist = async () => {
  //   const response = await dispatch(fetchAppointmentByDate({
  //     appointment_date: moment(_appointmentData.date).format("YYYY-MM-DD"),
  //     branch_id: _appointmentData.branch,
  //     doctor_slot_config_id: _appointmentData.doctor_slot_config_id,
  //   }));
  //   return response.payload.data.length > 0;
  // };

  const endCall = () => {
    Modal.confirm({
      title: "Is the consultation completed?",
      okText: "Yes",
      cancelText: "No",
      okCancel: true,
      centered: true,
      maskClosable: false,
      cancelButtonProps: {
        className: "doctor-border-button doctor-padding-15 mt10",
        style: { width: "48%" },
      },
      okButtonProps: {
        className: "doctor-secondary-button doctor-padding-15 mt10 btn-shadow",
        style: { width: "48%" },
      },
      onOk() {
        (async function () {
          try {
            const response = await dispatch(
              checkoutAppointment(appointment.id)
            );
            if (
              response.payload.status === 200 ||
              response.payload.status === 201
            ) {
              setLoadingSlider(true);
              setStatus("Ending your Consultation");
              setTimeout(() => {
                dispatch(fetchAppointmentById(seletectappointment?.id));
                setLoadingSlider(false);
                dispatch(
                  fetchAppointment({
                    appointment_date: _appointmentData?.date,
                    branch_id: _appointmentData?.branch,
                    doctor_slot_config_id:
                      _appointmentData?.doctor_slot_config_id,
                    limit: 100,
                  })
                );
                // filterAppointments("booked");
                message.success("Appointment Ended Successfully");
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        })();
      },
    });
  };

  const onDateChange = (date: any) => {
    dispatch(
      fetchDoctorAvailSlots({
        doctor_id: appointment.doctor_id,
        branch_id: appointment.branch_id,
        procedure_id: appointment.procedure_id,
        old_appointment_id: appointment.id,
        fee: appointment.amount,
        slot_date: moment(date).format("YYYY-MM-DD"),
      })
    );
  };

  const enableCaseSummary = (key: any) => {
    setCaseSummary(key);
    setAddAppointment(false);
    setDetails(false);
    setdocuments(false);
  };
  const disableCaseSummary = () => {
    showAppointmentDetails(seletectappointment, true);
  };

  const total_appointment = appointments.filter((appointment: any) => {
    return appointment.latest_status !== "pending";
  });
  let _data = allPrescriptions.concat(labreports);

  const patientFiltersData: TPatientFilterData<TOPDPatientFiltersTabs> =
    useMemo(() => {
      return {
        "checked-in": {
          title: "Checked-In",
          noOfPatients:
            (filters["checked-in"] ? filters["checked-in"].length : 0) +
            (filters["consulting"] ? filters["consulting"].length : 0),
        },
        all: {
          title: "Total Appts",
          noOfPatients: total_appointment ? total_appointment.length : 0,
        },
        "no-show cancelled": {
          title: "No Show / Cancelled",
          noOfPatients:
            (filters["no-show"] ? filters["no-show"].length : 0) +
            (filters["cancelled"] ? filters["cancelled"].length : 0),
        },
        done: {
          title: "Checked-Out",
          noOfPatients: filters["done"] ? filters["done"].length : 0,
        },
      };
    }, [filters, total_appointment]);

  const onConsultPatientClick = useCallback((val) => {
    // initiate doctor's practice if not started
    if (slot === null || slot.practice_status !== "start") {
      startConsultation("start", false);
    }

    // initiate vc if appointment is video enabled
    val?.enable_video && !pipEnabled && setStartVideoConsultation(val);

    // otherwise start consulting
    val?.enable_start_consultation &&
      val?.procedure_info?.name === "consultation" &&
      checkinAppointment(val);
  }, []);

  return (
    <Row>
      <Col
        span={7}
        className="full-height white-background padding20 overflowhide opd-appointments-col border-right"
      >
        <Row style={{ height: "56px" }}>
          <Col span={20}>
            <Space style={{ alignItems: "baseline" }}>
              {/* <LeftOutlined style={{ fontSize: "15px" }} className="cursor" onClick={() => history.goBack()} />     */}
              <BackIcon
                style={{ fontSize: "15px" }}
                className="cursor"
                onClick={() => history.goBack()}
              />
              <span
                style={{ fontSize: "18px" }}
                className="bold doctor-primary-color "
              >
                OPD Patients
              </span>
            </Space>
          </Col>
          <Col span={4} className="text-right ">
            {useExtraMenuFlag &&
              !previousApptDate &&
              _appointmentData.start &&
              _appointmentData?.branch_name !==
                "Instant Video Consultation" && (
                <ExtraMenu
                  startConsultation={startConsultation}
                  _appointmentData={_appointmentData}
                  profile={profile}
                  slot={slot}
                  openBlockShiftModal={openBlockShiftModal}
                  setMarkDelay={setMarkDelay}
                  setUnmarkLeave={setUnmarkLeave}
                  setMarkLeave={setMarkLeave}
                  appointments={appointments}
                />
              )}
          </Col>
        </Row>
        <Row
          className="doctor-light-background padding20 borderRightGray nomarginLR20"
          style={{ marginRight: "-20px" }}
        >
          <Col span={24}>
            <Text className="cursor font15 capitalize branchColor">
              <strong>{_appointmentData.branch_name}</strong>{" "}
            </Text>{" "}
            <br />
            <h4
              className="doctor-primary-color nopadding"
              style={{ marginBottom: "17px" }}
            >
              {" "}
              <span className="underline2">
                {getDayFormat(_appointmentData.date, false)}
              </span>
              , {formatOnlyDate(_appointmentData.date)}{" "}
              {_appointmentData.start &&
                _appointmentData?.branch_name !==
                  "Instant Video Consultation" && (
                  <small className=" max-subHead-color">
                    ({_appointmentData.start} - {_appointmentData.end})
                  </small>
                )}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PatientFilterTabs
              onChangeFilter={filterAppointments}
              patientFiltersData={patientFiltersData}
              selectedFilter={intialFilter}
              loading={appointmentsLoader}
            />

            {moment(_appointmentData.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD") &&
              _appointmentData.start && (
                <PracticeButton
                  practiceStatus={slot !== null ? slot.practice_status : "stop"}
                  onStartConsultation={startConsultation}
                />
              )}
          </Col>
        </Row>
        <Row className="nomarginLR" style={{ height: "60vh" }}>
          {appointmentsLoader === true ? (
            <Col span={24} className="padding20LR">
              <Skeleton active></Skeleton>
            </Col>
          ) : (
            <Col span={24}>
              {intialFilter === "checked-in" && (
                <>
                  {appointments.filter(
                    (a: any) => a.latest_status === "consulting"
                  ).length !== 0 && (
                    <>
                      <Text
                        className="sub-text cursor font12 capitalize"
                        style={{
                          display: "inline-block",
                          marginLeft: "20px",
                          marginTop: "20px",
                        }}
                      >
                        consulting
                      </Text>
                      <br />
                      <PatientList
                        type={3}
                        appointments={appointments.filter(
                          (a: any) => a.latest_status === "consulting"
                        )}
                        selectedAppointment={seletectappointment}
                        showDetails={(item: any) =>
                          showAppointmentDetails(item, true)
                        }
                        startConsulting={onConsultPatientClick}
                      ></PatientList>
                    </>
                  )}
                </>
              )}
              <Text
                className="sub-text cursor font12 capitalize"
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  marginTop: "20px",
                }}
              >
                {intialFilter}
              </Text>{" "}
              <br />
              {intialFilter === "all" ? (
                <PatientList
                  type={3}
                  appointments={appointments.filter(
                    (a: any) => a.latest_status !== "pending"
                  )}
                  selectedAppointment={seletectappointment}
                  showDetails={(item: any) =>
                    showAppointmentDetails(item, true)
                  }
                  startConsulting={onConsultPatientClick}
                ></PatientList>
              ) : (
                <PatientList
                  type={3}
                  appointments={appointments.filter(
                    (a: any) =>
                      a.latest_status !== "consulting" &&
                      (a.latest_status === intialFilter.split(" ")[0] ||
                        a.latest_status === intialFilter.split(" ")[1])
                  )}
                  selectedAppointment={seletectappointment}
                  showDetails={(item: any) =>
                    showAppointmentDetails(item, true)
                  }
                  startConsulting={onConsultPatientClick}
                ></PatientList>
              )}
              {/* <PatientList type={3} appointments={allAppointments.filter((a: any) => a.latest_status !== "consulting")} selectedAppointment={seletectappointment} showDetails={(item: any) => showAppointmentDetails(item)}></PatientList> */}
              {profile?.allow_add_appointment && (
                <Affix style={{ bottom: 10, left: "28%", position: "fixed" }}>
                  <Button
                    onClick={() => openaddAppointment()}
                    className="doctor-secondary-color-background"
                    style={{ padding: "10px" }}
                    shape="circle"
                    icon={<PlusOutlined style={{ color: "white" }} />}
                    size={"large"}
                  />
                </Affix>
              )}
            </Col>
          )}
        </Row>
      </Col>
      <Col span={17} className="full-height">
        {!addAppointment && !documents && !detail && !caseSumary && !loader ? (
          <NoDataLayout
            className={"mt200"}
            placeholder={"Select a patient to view their details"}
          ></NoDataLayout>
        ) : loader ? (
          <Col span={24} className={"text-center-content"}>
            <p>
              {" "}
              <Spin></Spin>{" "}
            </p>
          </Col>
        ) : detail && !documents && !addAppointment ? (
          <Row>
            <Col span={24} className="doctor-light-background">
              <Row className="h76">
                <Col span={20} className="white-background paddingLR20">
                  <PatientNameCard
                    details={appointment?.patient}
                    type={"patient"}
                  ></PatientNameCard>
                </Col>
                <Col span={4} className="white-background text-right padding20">
                  {appointment?.procedure_info?.name !== "consultation" &&
                  !pipEnabled ? (
                    <Badge count={chatCount}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 29 29"
                        className=" cursor"
                        onClick={() => {
                          setChat(true);
                          setChatCount(0);
                        }}
                        style={{ marginTop: "7px" }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.9398 0.5C14.32 0.5 13.7002 0.5 13.0804 0.5C13.0019 0.517442 12.924 0.539244 12.8443 0.552326C12.1005 0.67255 11.3437 0.737957 10.6148 0.918606C3.10797 2.78115 -1.47053 10.4213 0.432508 17.9133C1.30896 21.3643 3.23567 24.1082 6.18024 26.1022C9.11858 28.0919 12.3796 28.8207 15.8935 28.3722C17.6134 28.1529 19.2386 27.611 20.7548 26.7613C20.9087 26.6753 21.0432 26.6635 21.2139 26.7189C22.9494 27.2839 24.6892 27.8365 26.4259 28.3996C26.7891 28.5173 27.1591 28.5728 27.4687 28.3298C27.6861 28.1598 27.8313 27.8981 28.0088 27.677C28.0088 27.5132 28.0088 27.3487 28.0088 27.1849C27.9976 27.1705 27.982 27.1581 27.9764 27.1419C27.3983 25.3373 26.8246 23.5314 26.2403 21.7293C26.1793 21.5405 26.1892 21.3948 26.2852 21.2216C27.0856 19.7696 27.6139 18.2241 27.8481 16.5802C27.9004 16.2145 27.9552 15.8489 28.0088 15.4832C28.0088 14.8453 28.0088 14.2075 28.0088 13.5696C27.9658 13.2282 27.9291 12.8856 27.8792 12.5455C27.5385 10.2176 26.6994 8.08723 25.2866 6.20288C22.9805 3.1275 19.9238 1.27367 16.1234 0.661338C15.7309 0.597176 15.3348 0.552949 14.9398 0.5ZM25.2056 25.6818C25.0468 25.64 24.9515 25.6188 24.8593 25.5896C23.8389 25.2619 22.8018 24.9772 21.8038 24.5904C21.0432 24.2957 20.4315 24.4048 19.7276 24.8028C17.1985 26.2312 14.4782 26.6417 11.6321 26.0555C8.63021 25.4369 6.2282 23.8541 4.43979 21.3742C2.43272 18.5904 1.78239 15.4845 2.45515 12.1294C3.05752 9.12502 4.65033 6.7224 7.12958 4.93336C9.92091 2.91944 13.0337 2.26848 16.3993 2.94934C19.4037 3.55669 21.8101 5.14765 23.586 7.63623C25.8292 10.7795 26.3506 14.2361 25.2972 17.9382C25.0244 18.8969 24.6076 19.7951 24.0875 20.6442C23.8582 21.0179 23.8078 21.396 23.9585 21.8146C24.1305 22.293 24.2725 22.782 24.4282 23.2667C24.6799 24.0503 24.9334 24.8358 25.2056 25.6818Z"
                          fill="#23BCB5"
                        />
                        <path
                          d="M18.1128 14.5002C18.1128 15.2589 18.7245 15.8682 19.4851 15.8657C20.2432 15.8632 20.8481 15.249 20.8437 14.4865C20.8394 13.7409 20.232 13.1373 19.4851 13.1348C18.7245 13.1323 18.1128 13.7409 18.1128 14.5002Z"
                          fill="#23BCB5"
                        />
                        <path
                          d="M14.018 13.1348C13.2568 13.1317 12.6444 13.7396 12.6444 14.499C12.6438 15.2583 13.2549 15.8676 14.0155 15.8657C14.763 15.8638 15.3703 15.2602 15.376 14.5152C15.3803 13.7527 14.7761 13.1379 14.018 13.1348Z"
                          fill="#23BCB5"
                        />
                        <path
                          d="M7.17721 14.5127C7.18157 15.2695 7.79826 15.8719 8.56197 15.8657C9.31695 15.8594 9.91683 15.239 9.90811 14.4741C9.89939 13.7315 9.28643 13.1329 8.53705 13.1348C7.77833 13.1366 7.17284 13.7502 7.17721 14.5127Z"
                          fill="#23BCB5"
                        />
                      </svg>{" "}
                    </Badge>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className={"padding20"}>
                <Col span={24} className="doctor-box-shadow doctor-top-border ">
                  <Row>
                    <Col span={24} className="doctor-color-heading2">
                      <Row>
                        <Col span={24} className={""}>
                          <span className="doctor-primary-color font15 bold">
                            Appointment Details{" "}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <AppointmentDetails
                        checkinAppointment={() => checkinAppointment()}
                        showCall={true}
                        joinCall={(value: any) => setJoinCall(value)}
                        endCall={() => endCall()}
                        appointment={appointment}
                      ></AppointmentDetails>
                    </Col>
                  </Row>
                </Col>
                {prescriptionLoader ? (
                  <>
                    <Skeleton></Skeleton>
                  </>
                ) : prescriptions && prescriptions.length !== 0 ? (
                  <Col
                    span={24}
                    className="doctor-box-shadow doctor-top-border mt20"
                  >
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={24} className={""}>
                            <span className="doctor-primary-color bold">
                              Patient's prescriptions
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={24}
                        className="white-background padding10"
                        style={{ paddingLeft: "20px" }}
                      >
                        {prescriptions &&
                          prescriptions.map((item: any) => {
                            return (
                              <Row className="mt10">
                                <Col span={12}>
                                  <p className="doctor-primary-color">
                                    {item.name}
                                  </p>
                                </Col>
                                <Col span={12} className="text-right">
                                  <Text
                                    className="doctor-secondary-color cursor font12"
                                    underline
                                    onClick={() =>
                                      showFileDetails(item, "doctor")
                                    }
                                  >
                                    View
                                  </Text>
                                </Col>
                              </Row>
                            );
                          })}
                        {
                          <Col span={24}>
                            {/* <Button block type={"primary"} onClick={() => setTemplateViewer(true)} size={"large"} className={"mt20"}>{t("Prescription.AddPrescription")}</Button> */}
                            {seletectappointment?.is_prescription_add_allowed && (
                              <Row
                                gutter={[12, 12]}
                                style={{ width: "100% !important" }}
                              >
                                <Col
                                  span={
                                    seletectappointment?.is_thb_ep_ready ||
                                    seletectappointment?.is_max_ep_ready
                                      ? 24
                                      : 12
                                  }
                                >
                                  <Button
                                    block
                                    type={"primary"}
                                    href={seletectappointment?.practo_ep_link}
                                    target={"_blank"}
                                    size={"large"}
                                    className={"doctor-secondary-button"}
                                  >
                                    {seletectappointment?.is_thb_ep_ready ||
                                    seletectappointment?.is_max_ep_ready
                                      ? "Edit"
                                      : "Write"}{" "}
                                    Prescription
                                  </Button>
                                </Col>
                                {seletectappointment?.is_thb_ep_ready ||
                                seletectappointment?.is_max_ep_ready ? (
                                  ""
                                ) : (
                                  <Col
                                    span={12}
                                    className="text-center upload_button_col"
                                  >
                                    <UploadLayout
                                      isUnderline={true}
                                      setFileData={(file: any) =>
                                        showPrescriptionData(file)
                                      }
                                      fileData={[]}
                                      text={"Upload Prescription"}
                                    ></UploadLayout>
                                  </Col>
                                )}
                              </Row>
                            )}
                          </Col>
                        }
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <>
                    {/* <Button block type={"primary"} onClick={() => setTemplateViewer(true)} size={"large"} className={"mt20"}>{t("Prescription.AddPrescription")}</Button> */}

                    <Col span={24}>
                      {seletectappointment?.is_prescription_add_allowed && (
                        <Row gutter={[12, 12]}>
                          <Col
                            span={
                              seletectappointment?.is_thb_ep_ready ||
                              seletectappointment?.is_max_ep_ready
                                ? 24
                                : 12
                            }
                          >
                            <Button
                              block
                              type={"primary"}
                              href={seletectappointment?.practo_ep_link}
                              target={"_blank"}
                              size={"large"}
                              className={"mt20 doctor-secondary-button"}
                            >
                              {seletectappointment?.is_thb_ep_ready ||
                              seletectappointment?.is_max_ep_ready
                                ? "Edit"
                                : "Write"}{" "}
                              Prescription
                            </Button>
                          </Col>
                          {seletectappointment?.is_thb_ep_ready ||
                          seletectappointment?.is_max_ep_ready ? (
                            ""
                          ) : (
                            <Col
                              span={12}
                              className="mt20 text-center upload_button_col"
                            >
                              <UploadLayout
                                isUnderline={true}
                                setFileData={(file: any) =>
                                  showPrescriptionData(file)
                                }
                                fileData={[]}
                                text={"Upload Prescription"}
                              ></UploadLayout>
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </>
                )}

                {loading ? (
                  <>
                    <Skeleton></Skeleton> <Skeleton></Skeleton>
                  </>
                ) : seletectappointment?.procedure_info?.name ===
                    "video consultation" ||
                  seletectappointment?.procedure_info?.name === "instant" ? (
                  <Col
                    span={24}
                    className="doctor-box-shadow doctor-top-border  mt20"
                  >
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col flex="auto" className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Records Uploaded by Patient
                            </span>
                          </Col>
                          {
                            // Documents?.length == Documents?.filter((val:any)=> { return val?.dropdown_value_id !== null })?.length
                            Documents?.length > 0 && qcPass && (
                              <Col flex="none">
                                <Tag>{qcPass}</Tag>
                              </Col>
                            )
                          }
                        </Row>
                      </Col>
                      <Col span={24} className="white-background ">
                        <AppointmentRecords
                          type={"patient"}
                          prescriptions={Documents}
                          noRecordMessage={"No Records uploaded by Patient"}
                          showNoData={true}
                          enableCaseSummary={(value: any) =>
                            enableCaseSummary(value)
                          }
                          isPrescription={false}
                          details={seletectappointment}
                          openFilesUploaded={(type: any) =>
                            openFilesUploaded(type)
                          }
                          showFileDetails={(item: any, type: any) =>
                            showFileDetails(item, "patient")
                          }
                        ></AppointmentRecords>
                        {seletectappointment?.enable_case_summary && (
                          <>
                            {/* <Row>
                              <Col span={24}>
                                <p className="paddingLR24 mt-5">
                                  {" "}
                                  <Divider
                                    dashed
                                    className=" margin5"
                                  ></Divider>
                                </p>
                              </Col>
                            </Row> */}

                            <p className={"padding20LR mt-10"}>
                              <Button
                                block
                                type={"primary"}
                                onClick={() => enableCaseSummary(true)}
                                size={"large"}
                                className="doctor-border-button"
                              >
                                Prepare Case Summary{" "}
                              </Button>
                            </p>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}

                {prescriptionLoader ? (
                  <>
                    <Skeleton></Skeleton>
                  </>
                ) : (
                  <Col
                    span={24}
                    className="doctor-box-shadow doctor-top-border  mt20"
                  >
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={20} className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Max Healthcare Records
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="white-background">
                        <AppointmentRecords
                          type={"all"}
                          prescriptions={_data}
                          showNoData={true}
                          isPrescription={true}
                          details={seletectappointment}
                          openFilesUploaded={(type: any) =>
                            openFilesUploaded(type)
                          }
                          showFileDetails={(item: any) =>
                            showFileDetails(item, "doctor")
                          }
                        ></AppointmentRecords>
                      </Col>
                    </Row>
                  </Col>
                )}

                {appointment?.enable_reschedule && (
                  <Col
                    span={24}
                    className="doctor-box-shadow doctor-top-border mt20"
                  >
                    <Row>
                      <Col span={24} className="doctor-color-heading2">
                        <Row>
                          <Col span={20} className={""}>
                            <span className="doctor-primary-color font15 bold">
                              Want to Reschedule?
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} className="white-background padding-main">
                        <Row>
                          {/* <Col span={11}>
                                                               {appointment?.enable_no_show && <Button size="large" className="doctor-border-button" block type="default" onClick={() => setShow(true)}>No Show</Button>}
                                                           </Col> */}
                          <Col span={24}>
                            {appointment?.enable_reschedule && (
                              <Button
                                block
                                className="dark-border-button"
                                loading={_slots_loader}
                                size={"large"}
                                type="default"
                                onClick={() => reSheduleAppointment()}
                              >
                                Reschedule
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}

                {/* <Col
                  span={24}
                  className="mt20 doctor-box-shadow white-background padding-main border-radius5"
                >
                  <Row>
                    <Col span={20} className="mt10">
                      <p style={{ fontWeight: 600 }} className="colorDark">
                        Facing issues regarding your appointment?
                      </p>
                    </Col>

                    <Col span={24} className="mt10">
                      <p>
                        <Button
                          size={"large"}
                          className="doctor-border-button"
                          block
                          type="default"
                          onClick={() => setSupport(true)}
                        >
                          Contact Support
                        </Button>{" "}
                      </p>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Col>
          </Row>
        ) : !detail && addAppointment && !documents ? (
          <DoctorAddAppointment
            AddAppointment={(values: any) => AddAppointment(values)}
            showBack={true}
            onBack={(value: any) => setAddAppointment(value)}
          ></DoctorAddAppointment>
        ) : !detail && !addAppointment && documents ? (
          <FilesLayout
            type={selectedDocumentType === "all" ? "doctor" : "patient"}
            files={
              selectedDocumentType === "doctor"
                ? prescriptions
                : selectedDocumentType === "all"
                ? _data
                : Documents
            }
            showPatientCard={true}
            details={seletectappointment?.patient}
            showBack={true}
            onBack={() => openDetails()}
          ></FilesLayout>
        ) : caseSumary && !detail && !addAppointment && !documents ? (
          <PrepareCaseSummary
            showBack={true}
            onBack={() => disableCaseSummary()}
            details={seletectappointment?.patient}
            appointment={seletectappointment}
          ></PrepareCaseSummary>
        ) : (
          ""
        )}
      </Col>

      {/* Join Call Modal */}
      {/* <Modal
        className="joinVideoCallModal"
        visible={call}
        destroyOnClose
        footer={false}
        width={"30%"}
        style={{ paddingBottom: "0px" }}
        centered
        closable
        onCancel={() => cancelVideoModal()}
        closeIcon={<CrossIcon />}
        title={"Join Video Call"}
      >
        <VideoVerification
          _webcamEnabled={call}
          roomId={seletectappointment?.room_id}
          cancelVideoModal={() => cancelVideoModal()}
          showAppointmentDetails={(id: any) => showAppointmentDetails(id)}
          appointment_id={seletectappointment.id}
          appointment={seletectappointment}
          call_validation={call_validation}
          type={"doctor"}
        ></VideoVerification>
      </Modal> */}

      {/* No Show */}
      <Modal
        visible={show}
        footer={false}
        style={{ paddingBottom: "0px" }}
        width={"30%"}
        centered
        closable
        onCancel={() => setShow(false)}
        title={"No Show"}
      >
        <Row className="padding20">
          <Col span={24}>
            <p className="doctor-primary-color bold cursor bordered-grey-color padding20 capitalize">
              {seletectappointment?.patient?.pii?.first_name}{" "}
              {seletectappointment?.patient?.pii?.last_name}{" "}
              <small className="sub-text light-text">
                ({getAge(seletectappointment?.patient?.birth_year)}{" "}
                {seletectappointment?.patient?.genderMaster?.name})
              </small>{" "}
              <br />
              <small className="sub-text light-text capitalize">
                Time : {seletectappointment?.start_time}{" "}
              </small>
            </p>
            <p className="font12 text-center">
              Are you sure you want to mark <br />
              this patient “No Show”?
            </p>
          </Col>
          <Col span={11}>
            <Button
              className="doctor-border-button mt20"
              block
              size={"large"}
              onClick={() => setShow(false)}
            >
              No
            </Button>
          </Col>
          <Col span={11} offset={2}>
            <Button
              className="doctor-secondary-button mt20"
              block
              size={"large"}
              onClick={() => setNoShow()}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>
      {/* No Show */}

      {/* Old Appointments */}
      <Modal
        visible={oldAppointments}
        footer={false}
        style={{ paddingBottom: "0px" }}
        width={"30%"}
        centered
        closable
        onCancel={() => setOldAppointments(false)}
        title={"Start Practice"}
      >
        <Row className="padding20">
          <Col span={24}>
            <p className="font12 text-center">
              Below {old_slots.length} checked-in {_appointmentData.branch_name}{" "}
              <br /> {getDayFormat(_appointmentData.date, false)},{" "}
              {moment(_appointmentData.date).format("Do, MMM YYYY")} are still
              open
            </p>
            {old_slots.map((item: any) => {
              return (
                <p className="doctor-primary-color bold cursor bordered-grey-color padding20 capitalize">
                  {item?.patient?.pii?.first_name}{" "}
                  {item?.patient?.pii?.last_name}{" "}
                  <small className="sub-text light-text">
                    ({getAge(item?.patient?.birth_year)}{" "}
                    {item?.patient?.genderMaster?.name})
                  </small>{" "}
                  <br />
                  <small className="sub-text light-text">
                    Time : {item?.start_time}{" "}
                  </small>
                </p>
              );
            })}

            <p className="font12 text-center">Do you want to start practice?</p>
          </Col>
          <Col span={11}>
            <Button
              className="doctor-border-button mt20"
              block
              size={"large"}
              onClick={() => setOldAppointments(false)}
            >
              No
            </Button>
          </Col>
          <Col span={11} offset={2}>
            <Button
              className="doctor-secondary-button mt20"
              block
              size={"large"}
              onClick={() => startConsultation("start", true)}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>
      {/* No Show */}

      <MarkDelayModal
        _appointmentData={_appointmentData}
        markDelay={markDelay}
        setMarkDelay={setMarkDelay}
        setLoadingSlider={setLoadingSlider}
        setStatus={setStatus}
        newDate={newDate}
        setNewDate={setNewDate}
        start_time={start_time}
        setStartTime={setStartTime}
        end_time={end_time}
        setEndTime={setEndTime}
      />

      <BlockShiftModal
        _appointmentData={_appointmentData}
        profile={profile}
        blockShift={blockShift}
        setBlockShift={setBlockShift}
        blockShiftEntries={blockShiftEntries}
        setBlockShiftEntries={setBlockShiftEntries}
        setLoadingSlider={setLoadingSlider}
        setStatus={setStatus}
      />

      <MarkLeaveModal
        _appointmentData={_appointmentData}
        profile={profile}
        model_open={markLeave}
        set_model_open={setMarkLeave}
      />

      <UnmarkLeaveModal
        _appointmentData={_appointmentData}
        profile={profile}
        model_open={unmarkLeave}
        set_model_open={setUnmarkLeave}
      />

      {/* Prescription File Modal */}
      <Modal
        className="prescriptionModal"
        visible={prescriptionFinalModal}
        onCancel={() => setPrescriptionFinalModal(false)}
        footer={false}
        width={"25%"}
        style={{ paddingBottom: "0px" }}
        title={"Prescription Details"}
        centered
        closable
      >
        {uploading ? (
          <Row>
            <Col span={24} className={"text-center"}>
              {uploadPercentage === 100 ? (
                <SuccessMessage height={125} width={125} />
              ) : (
                <Progress
                  className={"font12"}
                  type="circle"
                  width={200}
                  percent={uploadPercentage}
                  format={() => `${uploadPercentage} %`}
                />
              )}
              <p
                style={{ color: "#163B6D", fontWeight: 600, fontSize: "22px" }}
                className={"doctor-primary-color text-center mt20"}
              >
                {uploadPercentage === 100
                  ? "Prescription Uploaded"
                  : `Uploading Prescription`}
              </p>
            </Col>
          </Row>
        ) : uploaderror !== "" ? (
          <Row>
            <Col span={24} className={"text-center"}>
              {" "}
              <Progress
                className={"font12"}
                type="circle"
                width={200}
                percent={uploadPercentage}
                status="exception"
                format={() =>
                  uploadPercentage === 100 ? "Uploaded" : uploaderror
                }
              />{" "}
            </Col>
          </Row>
        ) : (
          <Row className="">
            <Col span={24}>
              {fileData && (
                <Button
                  size="large"
                  block
                  className="doctor-border-button white-background"
                  style={{ height: "50px" }}
                  onClick={() => uploadPrescription()}
                >
                  <img
                    alt="prescription asset"
                    src={
                      require("../../../../assets/images/prescription.png")
                        .default
                    }
                    style={{ width: "10%" }}
                  ></img>{" "}
                  <span
                    style={{
                      width: "80%",
                      textAlign: "left",
                      paddingLeft: "10px",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    1 Prescription{" "}
                  </span>{" "}
                  <Divider
                    style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.3)" }}
                    type="vertical"
                  ></Divider>{" "}
                  <span>
                    {" "}
                    <PlusOutlined></PlusOutlined>{" "}
                  </span>
                </Button>
              )}
            </Col>
            <Col span={24} className="mt20">
              {/* Button Inside */}
              <Button
                size="large"
                block
                className="doctor-secondary-button"
                onClick={() => uploadPrescription()}
              >
                Upload Prescription
              </Button>
            </Col>
          </Row>
        )}
      </Modal>
      {/* Prescription File Modal */}
      <Modal
        centered
        footer={false}
        visible={loadingSlider}
        closable={false}
        style={{ paddingBottom: "0px" }}
        width={"20%"}
      >
        <p className="text-center">
          {" "}
          <LoadingOutlined style={{ fontSize: "80px" }} />{" "}
        </p>
        <p className="text-center mt20 capitalize">{status}</p>
      </Modal>

      {/* Reshedule File Modal */}
      <Modal
        style={{ paddingBottom: "0px" }}
        width={741}
        title={
          <p className={"capitalize"}>
            <b>
              Choose Slot for New Appointment <br></br>(
              {seletectappointment?.patient?.pii?.first_name}{" "}
              {seletectappointment?.patient?.pii?.last_name} |{" "}
              {seletectappointment?.branch?.name})
            </b>{" "}
            <br />
            <span className={"sub-title light-text"}>
              Previous Appointment :{" "}
              {moment(seletectappointment?.appointment_date).format(
                "Do, MMM YYYY"
              )}
              , {seletectappointment?.start_time} | ₹
              {seletectappointment?.amount}
            </span>
          </p>
        }
        centered
        className="doctor-app-dark-modal border-radius nopadding"
        onCancel={() => setReSheduleAppointment(false)}
        visible={resheduleAppointment}
        footer={
          <>
            {/*
                {
                    oldSlot !== null && <p className="primary-color-text mt10 bold text-right">New Appointment will be scheduled on {moment(oldSlot.slot_date).format("Do MMM' YYYY")}, {oldSlot.slot_time} with Rs. {oldSlot.fee} Fee. <br />
                        Are you sure you want to continue?</p>
                }

                */}

            <Button
              size={"large"}
              className={"doctor-secondary-button"}
              block
              onClick={() => rescheduleAppointment()}
            >
              Reschedule
            </Button>

            <p className={"text-center mt10"}>
              <i className="sub-text mt10">
                Invoice already generated. Request will be sent to Max front
                office
              </i>
            </p>
          </>
        }
      >
        <DoctorSlots
          onDateChange={(date: any) => onDateChange(date)}
          mode={"view"}
          slots={totalSlots}
          slots_loader={slots_loader}
          onSubmit={(slot: any, procedure: any, branch: any, current: any) =>
            onSubmit(slot, procedure, branch, current)
          }
        ></DoctorSlots>
      </Modal>
      <Modal
        maskStyle={{ background: "rgba(0, 0, 0, 0.15)" }}
        visible={support}
        title={"Support"}
        className="doctor-app-dark-modal border-radius"
        footer={false}
        onCancel={() => setSupport(false)}
        centered
      >
        <ReportLayout></ReportLayout>
      </Modal>
      {/* Chat */}
      {chat && (
        <ChatLayout
          modal={false}
          allMessages={allMessages}
          isDrawer={true}
          show={chat}
          appointment={seletectappointment}
          details={appointment?.patient}
          type={"patient"}
          loginUser={"doctor"}
          setChat={(value: any) => {
            setChat(value);
            setAllMessages([]);
            setChatCount(0);
          }}
        ></ChatLayout>
      )}
      <FileViewLayout
        modal={true}
        details={_file}
        fileviewer={fileviewer}
        setFileViewer={(value: any) => setFileViewer(value)}
        file={signedUrl}
      ></FileViewLayout>
      {/*template selecter model */}
      <Modal
        title={t("Prescription.AddPrescription")}
        visible={templateViewer}
        onOk={() => selectTemplate()}
        onCancel={() => setTemplateViewer(false)}
        okText={t("Prescription.Select")}
      >
        <Radio.Group
          onChange={(e) => {
            setSelectedTemplateId(e.target.value);
          }}
          value={selectedTemplateId}
        >
          <Space direction="vertical">
            {prescriptionTemplate &&
              prescriptionTemplate.map((sPrescriptionTemplate: any) => {
                return (
                  <>
                    <Radio value={sPrescriptionTemplate.template_data_id}>
                      {sPrescriptionTemplate.name} - (
                      {sPrescriptionTemplate.code})
                    </Radio>
                  </>
                );
              })}
          </Space>
        </Radio.Group>
      </Modal>
      <Modal
        title={t("Prescription.FillPrescription")}
        visible={templateFormViewer}
        onOk={() => selectTemplate()}
        onCancel={() => setTemplateFormViewer(false)}
        okText={t("Prescription.Submit")}
        width="100%"
        footer={null}
      >
        <>
          <Card bordered={false}>
            {selectedTemplateData &&
              selectedTemplateData.sections &&
              selectedTemplateId && (
                <DynamicFormContainer
                  templateId={selectedTemplateId}
                  onCancel={() => setTemplateFormViewer(false)}
                  saving={false}
                  onSubmit={async (data: any) => {
                    try {
                      //Max_DrName_PrescriptionDate
                      const response = await dispatch(
                        uploadFile({
                          file_name:
                            "Max_" +
                            seletectappointment.doctor.name +
                            "_" +
                            moment().format("YYYY-MM-DD h:i:s"),
                          type: "TEMPLATEFILLED",
                          content_type: "json",
                        })
                      );
                      const response2 = new Promise((resolve, reject) => {
                        resolve(
                          axios
                            .request({
                              headers: {
                                "Content-Type": "json", // This is set to application/flac
                              },
                              method: "PUT",
                              data: data,
                              url: response?.payload?.data?.data?.url,
                              timeout: 0,
                            })
                            .then(async function () {
                              const upload = await dispatch(
                                addPrescriptions({
                                  upload_id: response?.payload?.data?.data?.id,
                                  appointment_id: seletectappointment.id,
                                  patient_id: seletectappointment.patient_id,
                                  date: moment().format("YYYY-MM-DD"),
                                  type: "PRESCRIPTION",
                                })
                              );
                              setTemplateFormViewer(false);
                              dispatch(
                                fetchPrescriptions({
                                  appointment_id: seletectappointment.id,
                                  patient_id: seletectappointment.patient_id,
                                  type: "PRESCRIPTION",
                                })
                              );
                              // history.goBack();
                            })
                        );
                      });
                    } catch (e) {
                      message.error("Error in uploading prescription");
                      console.error(e);
                    }
                    return false;
                  }}
                  formData={
                    selectedTemplateData.sections
                      ? selectedTemplateData
                      : JSON.parse(selectedTemplateData)
                  }
                />
              )}
          </Card>
        </>
      </Modal>
    </Row>
  );
};

export default OPDAppointments;
