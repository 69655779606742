import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DELAY_SLOT_BY_ID,
  INSTANT_APPOINTMENT_VC,
  PREVOIUS_SLOTS,
  SLOT_BY_ID,
  SLOT_PROCEDURE_BY_ID,
  UPDATE_SLOT_BY_ID,
  MARK_LEAVE_BULK,
  GET_OFF_DAYS,
  OFF_DAYS_BY_ID,
  BLOCK_SHIFT_ENTRIES,
} from "../../shared/routes/route.constants";

import { SlotConfigration, SlotProcedure } from "../../shared/types/slot.type";
import { Client as ClientV1 } from "../../shared/Utils/api-client";
import { Client as ClientV2 } from "../../shared/Utils/api-client-v2";

const config = {
  name: "slot",
};
export const fetchSlotById: any = createAsyncThunk(
  `${config.name}/fetchSlotById`,
  async (id: string) => {
    return await ClientV1.get(SLOT_BY_ID(id), {});
  }
);

export const fetchSlotProcedureById: any = createAsyncThunk(
  `${config.name}/fetchSlotProcedureById`,
  async (id: string) => {
    return await ClientV1.get(SLOT_PROCEDURE_BY_ID(id), {});
  }
);

export const fetchPrevoiusSlots: any = createAsyncThunk(
  `${config.name}/fetchPrevoiusSlots`,
  async (id: string) => {
    return await ClientV1.get(PREVOIUS_SLOTS(id), {});
  }
);

export const updateDoctorSlotConfigById: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotConfigById`,
  async (data: string) => {
    return await ClientV1.post(UPDATE_SLOT_BY_ID, data);
  }
);

export const updateDoctorSlotDelay: any = createAsyncThunk(
  `${config.name}/updateDoctorSlotDelay`,
  async (data: string) => {
    return await ClientV1.post(DELAY_SLOT_BY_ID, data);
  }
);

export const fetchLeaveForDaysBetween: any = createAsyncThunk(
  `${config.name}/fetchLeaveForDaysBetween`,
  async (data: string) => {
    // NOTE: Data Format
    // {
    //  "doctor_ids": [
    //    "string"
    //  ],
    //  "branch_id": "string",
    //  "start_date": "string", [YYYY-MM-DD]
    //  "end_date": "string" [YYYY-MM-DD]
    // }
    return await ClientV2.post(GET_OFF_DAYS, data);
  }
);

export const markLeaveForSelectedDates = createAsyncThunk(
  `${config.name}/markLeaveForSelectedDates`,
  async (data: any) => {
    // NOTE: Data Format
    //{
    //  "doctors_off_days": [
    //    {
    //      "doctor_id": "string",
    //      "branch_id": "string",
    //      "start_date": "string", [YYYY-MM-DD]
    //      "end_date": "string", [YYYY-MM-DD]
    //      "reason": "string",
    //      "status": "mark"
    //    }
    //  ]
    // }
    return await ClientV2.post(MARK_LEAVE_BULK, data);
  }
);

export const fetchBlockShiftEntries = createAsyncThunk(
  // NOTE: data format
  // {
  //   "doctor_id": "string",
  //   "date": "string" [YYYY-MM-DD]
  //   "branch_id": "string" [optional]
  // }
  `${config.name}/fetchBlockShiftEntries`,
  async (data: any) => {
    return await ClientV2.get(BLOCK_SHIFT_ENTRIES, {
      params: { ...data },
    });
  }
);

export const updateDeleteAddBlockSlots = createAsyncThunk(
  // NOTE: Data Format
  // {
  //   "blocked_slots_data": [
  //     {
  //       "doctor_slot_config_id": "string",
  //       "doctor_id": "string",
  //       "date": "string",
  //       "update_blocked_slots": [
  //         {
  //           "id": "string",
  //           "start_time": "string",
  //           "end_time": "string",
  //           "reason": "string"
  //         }
  //       ],
  //       "create_blocked_slots": [
  //         {
  //           "id": "string",
  //           "start_time": "string",
  //           "end_time": "string",
  //           "reason": "string"
  //         }
  //       ],
  //       "delete_blocked_slot_ids": [
  //         null
  //       ]
  //     }
  //   ]
  // }
  `${config.name}/updateDeleteAddBlockShots`,
  async (data: any) => {
    return await ClientV2.post(BLOCK_SHIFT_ENTRIES, data);
  }
);

export const deleteLeaveByID = createAsyncThunk(
  `${config.name}/deleteLeaveByID`,
  async (id: string) => {
    return await ClientV2.delete(OFF_DAYS_BY_ID(id), {});
  }
);

// BOSS API
export const fetchAppointmentInstantVC: any = createAsyncThunk(
  `${config.name}/fetchAppointmentInstantVC`,
  async (id: any) => {
    return await ClientV1.get(INSTANT_APPOINTMENT_VC, {});
  }
);

export const slotSlice = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    savingDoctor: false,
    slot: null as SlotConfigration | null,
    error: "" as string,
    procedure: null as SlotProcedure | null,
    old_slots: [],
    instant_vc: [] as any,
  },
  reducers: {
    reset: (state) => {
      state.slot = null;
      state.loading = false;
      state.error = "";
      state.procedure = null;
      state.old_slots = [];
    },
  },
  extraReducers(builder) {
    builder
      // Doctor  Slot  List
      .addCase(fetchSlotById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSlotById.fulfilled, (state, action) => {
        state.loading = false;
        state.slot = action.payload?.data?.data;
      })
      .addCase(fetchSlotById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchPrevoiusSlots.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchPrevoiusSlots.fulfilled, (state, action) => {
        state.loading = false;
        state.old_slots = action.payload?.data?.data;
      })
      .addCase(fetchPrevoiusSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Procedure List

      .addCase(fetchSlotProcedureById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchSlotProcedureById.fulfilled, (state, action) => {
        state.loading = false;
        state.procedure = action.payload?.data?.data[0];
      })
      .addCase(fetchSlotProcedureById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateDoctorSlotConfigById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateDoctorSlotConfigById.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload.data?.data !== undefined) {
          state.procedure = action?.payload?.data?.data[0];
        }
      })
      .addCase(updateDoctorSlotConfigById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAppointmentInstantVC.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchAppointmentInstantVC.fulfilled, (state, action) => {
        state.loading = false;
        state.instant_vc = action.payload?.data?.data;
      })
      .addCase(fetchAppointmentInstantVC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { reset } = slotSlice.actions;

export default slotSlice.reducer;
