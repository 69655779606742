import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import React, { ReactElement, cloneElement, useState } from "react";

const { Text, Title } = Typography;

interface ConfirmationWrapperButtonProps {
  button: ReactElement;
  onConfirm: () => void;
  confirmationTitle?: string;
  confirmationContent?: string;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationWrapperButton: React.FC<
  ConfirmationWrapperButtonProps
> = ({
  button,
  onConfirm,
  confirmationTitle = "Confirmation",
  confirmationContent = "Are you sure you want to proceed?",
  confirmText = "Yes",
  cancelText = "No",
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
      onConfirm();
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const buttonWithConfirmation = cloneElement(button, {
      onClick: (e: React.MouseEvent) => {
        if (button.props.onClick) {
          button.props.onClick(e);
        }
        showModal();
      },
    });

    return (
      <>
        {buttonWithConfirmation}
        <Modal
          title={confirmationTitle}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={confirmText}
          cancelText={cancelText}
        >
          <p>{confirmationContent}</p>
        </Modal>
      </>
    );
  };

interface ConfirmationWrapperOptions {
  confirmationTitle: string;
  confirmationDescription: string;
}

export const confirmationWrapper = (
  func: (...args: any[]) => void,
  caller_arguments: any[],
  options: ConfirmationWrapperOptions
) => {
  return () => {
    Modal.confirm({
      title: options.confirmationTitle,
      content: options.confirmationDescription,
      onOk: () => func(caller_arguments),
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };
};

interface ConfirmationButtonProps {
  onConfirm: () => void;
  onCancel: () => void;
  ButtonTitle?: string;
  ButtonContent: string;
  confirmText: string;
  cancelText: string;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}

export const ConfirmationModal: React.FC<ConfirmationButtonProps> = ({
  onConfirm,
  onCancel,
  ButtonTitle = "Confirmation",
  ButtonContent = "Are you sure you want to proceed?",
  confirmText = "Yes",
  cancelText = "No",
  isModalVisible,
}) => {
  return (
    <Modal
      centered
      title={<Title className="text-center" level={4}>{ButtonTitle}</Title>}
      visible={isModalVisible}
      footer={false}
      width={"30%"}
      style={{ borderRadius: "24px", borderEndEndRadius: "24px" }}
      onCancel={onCancel}
    >
      <Row justify="center">
        <Col span={24} style={{ textAlign: "center" }}>
          <Col span={24} style={{ textAlign: "center" }} className="font18">
            <InfoCircleOutlined className="doctor-secondary-color" style={{ marginRight: 8, marginTop: 4 }} />
            <Text className="text-center doctor-primary-color">
              {ButtonContent}
            </Text>
          </Col>
          <br />
          <Row gutter={20}>
            <Col span={12}>
              <Button
                size="large"
                block
                key="cancel"
                className="doctor-border-button"
                onClick={onCancel}
              >
                {" "}
                {cancelText}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                block
                key="confirm"
                type="primary"
                className="doctor-secondary-button"
                onClick={() => { onConfirm() }}
              >
                {" "}
                {confirmText}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal >
  );
};


