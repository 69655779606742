import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Skeleton, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { validateAppointmentCallAttendantToken } from "../redux/reducers/appointment.slice";
import {
  attemptOTPLogin,
  attemptOTPLoginAttandant,
  validateOTPLoginAttandant,
} from "../redux/reducers/auth.slice";
import { RootState } from "../shared/constants";
import MaxHealthLogoIcon from "../shared/icons/MaxHealthLogoIcon";
import MaxLogoIcon from "../shared/icons/MaxLogoIcon";
import TimerLayout from "../shared/layouts/timer-desktop.layout";
import VideoVerification from "../shared/layouts/video-verification.layout";
import { ROUTE_PATIENT_WAITING_AREA_ATTANDANT } from "../shared/routes/patient.routes.constants";
import { getAge } from "../shared/Utils/utilities";
const { Text } = Typography;

function PublicAppointmentPage() {
  const RouteParams: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [call, joinCall] = useState(false);
  const [, setWebCamEnabled] = useState(true);
  const [appointment, setAppointment] = useState(null as any);
  const [loading, setLoading] = useState(true);
  const { otpSessionId } = useSelector((state: RootState) => state.auth);
  const [isError, setError] = useState(false as any);
  const [otp, setOTP] = useState(false as any);
  const [otpverify, setOtpVerify] = useState(null as any);
  const [resend, setResend] = useState(false);
  const [link, setlink] = useState("");
  const [participant, setParticipant] = useState(null as any);
  const { call_validation } = useSelector(
    (state: RootState) => state.appointment
  );
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const getToken = await dispatch(
          validateAppointmentCallAttendantToken({
            room_id: RouteParams["room_id"],
            participant_id: RouteParams["source_id"],
          })
        );

        if (getToken.payload.status === 200) {
          setAppointment(getToken.payload?.data?.data?.appointment_data);
          setParticipant(getToken.payload?.data?.data?.participant_info);
          // setlink(getToken.payload?.data?.data?.static_app_download_link);
          setlink("https://maxpatientapp.page.link/patient");
          setLoading(false);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  useEffect(() => {
    if (!loading && !isError && appointment?.id) {
      let link = document.getElementById("isMobile");
      try {
        if (
          (navigator && navigator?.platform === "iPhone") ||
          navigator?.platform === "iPod" ||
          navigator?.platform === "iPad" ||
          navigator?.platform === "MacPPC" ||
          navigator?.platform === "MacIntel" ||
          navigator.userAgentData?.mobile
        ) {
          link?.click();
          return;
        }
      } catch (e) {
        setIsMobile(false);
      }
    }
  }, [loading, isError, appointment]);
  const sendOTP = async () => {
    if (participant?.type === "patient") {
      const sendOTP = await dispatch(
        attemptOTPLogin({
          username: participant?.phone,
          account_id: appointment?.account_id,
          country_code: "+91",
          kind: "patient",
        })
      );
      if (sendOTP.payload.status === 201 || sendOTP.payload.status === 200) {
        sessionStorage.setItem("_patient_id", appointment?.patient_id);
        history.push(
          `/max/patient/login?sessionId=${sendOTP.payload?.data?.data.session_id}&number=${participant?.phone}`
        );
      }
      return;
    }

    (async function () {
      try {
        const response = await dispatch(
          attemptOTPLoginAttandant(participant?.id)
        );
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          setOTP(true);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    })();
  };

  const verifyOTP = () => {
    (async function () {
      try {
        const response = await dispatch(
          validateOTPLoginAttandant({
            id: participant?.id,
            data: { otp: otpverify, session_id: otpSessionId },
          })
        );
        if (
          response.payload.status === 201 ||
          response.payload.status === 200
        ) {
          history.push(
            ROUTE_PATIENT_WAITING_AREA_ATTANDANT(
              appointment?.room_id,
              RouteParams["source_id"],
              appointment?.id,
              "guest"
            )
          );
          sessionStorage.setItem("guest", "true");
        }
      } catch (e) {}
    })();
  };

  const cancelVideoModal = () => {
    joinCall(false);
    setWebCamEnabled(false);
  };

  return (
    <Row
      className={"padding20 full-height secondary-color-background"}
      justify="center"
    >
      <Col span={6} xs={24} md={6}>
        <MaxLogoIcon width="125" />
      </Col>

      {loading ? (
        <Col span={12} className={"text-center"}>
          <Skeleton className="mt50"></Skeleton>
        </Col>
      ) : isError ? (
        <Col
          span={12}
          className="white-background border-radius5 mt50 text-center"
          style={{ paddingBottom: "0px" }}
        >
          <h4 className="mt20">
            <b>
              <Text type="danger">Not Valid Room</Text>
            </b>
          </h4>
          <h4 className="doctor-primary-color mt20">
            <b>
              Download Max Healthcare Patient App <br />
              to join video call
            </b>
          </h4>
          <p className="doctor-primary-color mt20">
            {" "}
            <MaxHealthLogoIcon height="50" />
          </p>
          <Button
            size="large"
            className="doctor-secondary-button mt50"
            style={{ width: "200px" }}
            href={link}
            target={"_blank"}
          >
            Download App
          </Button>{" "}
          <br />
          <Text underline className="doctor-secondary-color font12 cursor mt10">
            Continue in Web Browser
          </Text>
          <img
            style={{ width: "100%" }}
            alt="public-page"
            src={require("../assets/images/public-page.png").default}
          />
        </Col>
      ) : (
        <Col md={12} xs={24} className={"text-center"}>
          <a
            href={
              `maxadhd://appointment/${appointment?.id}/${appointment?.patient_id}/` +
              RouteParams["source"] +
              `/${RouteParams["source_id"]}`
            }
            id={"isMobile"}
            target={"_self"}
          />
          <h2 className="doctor-secondary-color capitalize mb0">
            <b> {appointment?.procedure_info?.name} with</b>{" "}
          </h2>
          <h2 className="doctor-primary-color mt-10 capitalize">
            {appointment?.doctor?.name}
          </h2>
          <Row>
            <Col
              span={24}
              className="white-background border-radius5  padding20"
            >
              <h4 className="doctor-primary-color capitalize">
                <b>Appointment Details</b>
              </h4>
              <p className="doctor-primary-color capitalize">
                <UserOutlined /> Patient:{" "}
                {appointment?.patient?.pii?.first_name}{" "}
                {appointment?.patient?.pii?.last_name} (
                {getAge(appointment?.patient?.dob)},{" "}
                {appointment?.patient?.genderMaster?.name} ){" "}
              </p>
              <p className="doctor-primary-color mt-10 ">
                {" "}
                <ClockCircleOutlined />{" "}
                {moment(appointment?.appointment_date).format("MMMM Do YYYY")},
                {appointment?.start_time}{" "}
              </p>
              {participant && (
                <p className="doctor-primary-color mt-10 mb0">
                  <UserOutlined /> Attendant:{" "}
                  <span className="capitalize">{participant?.name}</span>{" "}
                </p>
              )}
            </Col>

            <Col
              span={24}
              className="white-background border-radius5 mt20 "
              style={{ paddingBottom: "0px" }}
            >
              <h4 className="doctor-primary-color mt20">
                <b>
                  Download Max MyHealth app <br />
                  to join video call
                </b>
              </h4>
              <p className="doctor-primary-color mt20">
                <MaxHealthLogoIcon height="50" />
              </p>
              <Button
                size="large"
                className="doctor-secondary-button "
                style={{ width: "200px" }}
                href={link}
                target={"_blank"}
              >
                Download App
              </Button>{" "}
              <br />
              <div className="mt15">
                <Text
                  underline
                  className="doctor-secondary-color font12 cursor mt10"
                  onClick={() => sendOTP()}
                >
                  Continue in Web Browser
                </Text>
              </div>
              <img
                alt="public-page"
                style={{ width: "100%" }}
                src={require("../assets/images/public-page.png").default}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col span={6}></Col>
      <Modal
        visible={otp}
        className={"doctor-app-dark-modal "}
        centered
        onCancel={() => setOTP(false)}
        title={"Enter OTP"}
        footer={false}
        maskClosable={false}
      >
        <Row>
          <Col span={24}>
            <h4 className="doctor-primary-color mt10">
              An OTP has been sent on phone no. <br></br>
              +91-{participant?.phone}
            </h4>

            <p className={"mt20"}>
              <OtpInput
                value={otpverify}
                inputStyle={"ant-input-otp"}
                onChange={(evt: any) => setOtpVerify(evt)}
                numInputs={6}
                separator={<span></span>}
              />
            </p>

            {resend && (
              <p className={"text-center"}>
                <Text className="bold mt-10 doctor-primary-color cursor">
                  Resend OTP in :{" "}
                  <span className="doctor-secondary-color bold">
                    <TimerLayout
                      placeholder="Resend OTP"
                      currentTimeIndex={currentTimeIndex}
                      onCompletion={() => {
                        setResend(true);
                        setCurrentTimeIndex(currentTimeIndex + 1);
                      }}
                      time={20}
                    ></TimerLayout>
                  </span>
                </Text>
              </p>
            )}

            <Button
              size="large"
              block
              className="doctor-secondary-button mt200"
              onClick={() => verifyOTP()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={call}
        style={{ paddingBottom: "0px" }}
        footer={false}
        centered
        closable
        onCancel={() => cancelVideoModal()}
        title={"Join Video Call"}
      >
        <VideoVerification
          appointment={appointment}
          appointment_id={appointment?.id}
          guestId={participant?.id}
          roomId={appointment?.room_id}
          call_validation={call_validation}
          type={RouteParams["source"]}
        ></VideoVerification>
      </Modal>
    </Row>
  );
}

export default PublicAppointmentPage;
