import { Button, Card, Col, Divider, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AccountRouteFetcher from "../AccountRouteFetcher";
import {
  fetchAccountByDomain,
  fetchApplications,
  switchApplication,
} from "../redux/reducers/auth.slice";
import { RootState } from "../shared/constants";
import { ROUTE_LOGIN } from "../shared/routes/route.constants";
import { _BASE_URL } from "../shared/Utils/api-client";

function LandingPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, applications } = useSelector(
    (state: RootState) => state.auth
  );
  const [baseUrl, setBaseUrl] = useState(null as any);
  useEffect(() => {
    localStorage.clear();
    dispatch(fetchAccountByDomain());
  }, []);
  useEffect(() => {
    if (account?.id) {
      localStorage.setItem("account_id", account?.id);
      dispatch(fetchApplications());
      setBaseUrl(_BASE_URL);
    }
    // account?.slug && history.push(ROUTE_CRM_LOGIN(account?.slug));
  }, [account]);

  return (
    <AccountRouteFetcher>
      <div>
        {t("genDict.landing")}
        <Divider />
        {applications.length > 0 &&
          applications.map((application: any) => {
            return (
              <Button
                onClick={async () => {
                  await dispatch(
                    switchApplication({
                      slug: application.slug,
                      application_id: application.id,
                    })
                  );
                  history.push(ROUTE_LOGIN(account.slug, application.slug));
                }}
              >
                Go {application.name} Login
              </Button>
            );
          })}
      </div>

      <Divider />
      <Card>
        <Row>
          <Col flex={"auto"}>
            <Input
              value={baseUrl}
              onChange={(e) => setBaseUrl(e.target.value)}
            />
            <div>
              Sample = <small>https://medi.hlthclub.in/vault/v1/</small>
            </div>
          </Col>
          <Col>
            <Button
              onClick={() => {
                localStorage.setItem("baseUrl", baseUrl);
              }}
            >
              Update Base Url
            </Button>
          </Col>
        </Row>
      </Card>
    </AccountRouteFetcher>
  );
}

export default LandingPage;
