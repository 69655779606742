import React from 'react';
import ReactMarkdown from 'react-markdown';
import markdown from '../Constants/Help.md';

export class HelpComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markdown: ''
        };
    }

    componentWillMount() {
        fetch(markdown).then((response) => response.text()).then((text) => {
            this.setState({ markdown: text })
        })
    }
    render() {
        return (
            <div  style={{padding:'50px'}}>
                    <ReactMarkdown>{this.state.markdown}</ReactMarkdown>            
          </div>
        )
    }
}

export default HelpComponent;
