export type RootState = {
  auth: any;
  user: any;
  branch: any;
  requestLogs: any;
  team: any;
  permission: any;
  department: any;
  role: any;
  messageTemplate: any;
  doctor: any;
  master: any;
  slot: any;
  patient: any;
  appointment: any;
  prescription: any;
  upload: any;
  profile: any;
  chat: any;
  contact: any;
  leads: any;
  metadata: any;
  calllogs: any;
  teamTypes: any;
  pharmacy: any;
  tickets: any;
  assignment: any;
  actionmap: any;
  agents: any;
  settings: any;
  region: any;
};

export const enum ENV {
  DEV,
  PROD,
}

export const CURRENT_ENV: ENV =
  process.env.ENVIRONMENT === "PROD" ? ENV.PROD : ENV.DEV;

export const BASE_API_URL = "https://maxalpha-medi.maxhospitals.in/vault/v1/";
export const BASE_API_URL_V2 =
  "https://maxbossprod-bossprodapi.maxhospitals.in/vault/v2/";
export const BASE_API_URL_V2_BETA =
  "https://betabossapi.maxhospitals.in/vault/v2/";

export const BASE_WEB_URL = "https://maxalpha-medi.maxhospitals.in/";
export const BASE_WEB_URL_NEW_BETA =
  "https://rfcty5ly9f.execute-api.ap-south-1.amazonaws.com/Stage/merge-proxy/";
export const BASE_WEB_URL_NEW_PROD =
  "https://rfcty5ly9f.execute-api.ap-south-1.amazonaws.com/Prod/merge-proxy/";

export const BASE_WEB_URL_PATIENT_BETA =
  "https://mds-beta.maxhealthcaredigital.com/api/merge-ids/";

export const BASE_WEB_URL_PATIENT_PROD =
  "https://maxdigitalservice-prod.us-west-2.elasticbeanstalk.com/api/merge-ids/";

export const GLOBAL_LIST_LIMIT = 25;
export const ACCOUNT_PREFIX: string = "max";
export const SENTRY_INIT_NAME: string = "max-ui";
export const CLIENT_NAME: string = "Max";
export const APPLICATION_NAME: string = "Max Hospitals";
export const ENABLE_NOTIFICATION: boolean = true;
export const ENABLE_WEB_SOCKET: boolean = true;

export const TNC_URL: string =
  "https://cdn.maxhospitals.in/terms_conditions/max_tnc.html";
export const PRIVACY_POLICY_URL: string =
  "https://cdn.maxhospitals.in/terms_conditions/max_privacy_policy.html";
export const SENTRY_INIT_DSN: string =
  "https://3faba4cf081140be9d341f3e55afd2aa@o1090269.ingest.sentry.io/6274274";
export const CHAT_BASE_URL: string = "https://alphawebsocket.maxhospitals.in/";
export const WEBSOCKET_END_POINT: string =
  "wss://3ma6hoh6l7.execute-api.ap-south-1.amazonaws.com/alpha";
export const SERVICE_LIST = [
  // {name: 'book_appointment'},
  { name: "order_medicines" },
];
export const TICKET_APPOINTMENT_ASSOCIATION: boolean = true;
