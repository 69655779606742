import React from 'react';

import FormElements from '../EditComponents/FormElements';
import { Row, Col,Tooltip } from 'antd';
import {
    SettingOutlined,
    SettingFilled ,
    PlusCircleFilled,
    DeleteFilled
} from '@ant-design/icons'

class Column extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.col && this.props.col.type ? this.props.col['type'] : ''
        }
    }

    getElemHtml() {
        let { type, uiData } = this.props.col;
        if (!type) return;
        let className = type.replace(type[0], type[0].toUpperCase());
        const ElemHtml = FormElements[className];
        let arr = [];
        arr.push(<><ElemHtml element={this.props.col} options={this.props.uiDataDict && this.props.uiDataDict[uiData] ? this.props.uiDataDict[uiData].options : []}></ElemHtml></>);
        return arr;
    }

    onColAddition = () => { this.props.onColAddition(this.props.colIndex); }
    onColDeletion = () => { this.props.onColDeletion(this.props.colIndex); }

    onEditColumnClick = () => {
        this.props.onEditColumnClick({ column: this.props.col, columnIndex: this.props.colIndex });
    }

    render() {
        const element = this.props.col['type'];
        const elementClass = this.getElemHtml();
        return (<>
            <Row className=" col-form-item pointer-cursor " onClick={this.onEditColumnClick.bind(this)}>
                <Col span={14}>
                    {this.props.col['header'] && <h6>Header: {this.props.col['header']}</h6>}
                    {(this.props.col['label_x'] || this.props.col['label_y']) &&
                        <h6>Row Label<i className="mdi mdi-arrow-right"></i>: {this.props.col['label_x']}
                            , Column Label<i className="mdi mdi-arrow-down"></i>: {this.props.col['label_y']}</h6>}
                    {!element && <h6>Col {this.props.colIndex + 1}</h6>}
                    {element && <div>{elementClass}</div>}
                </Col>
                <Col span={4} >{this.props.col['name'] ? this.props.col['name'] : '-'}</Col>
                <Col span={6}>
                    {!element && <a className="actions" data-tip data-for='addTool'>
                        <Tooltip
                            placement="right"
                            title="All Tool"
                            className="tooltip from-builder-tooltip"
                        >
                            <SettingOutlined  />
                        </Tooltip>
                    </a>}
                    {element && <a className="actions" onClick={this.onEditColumnClick.bind(this)} data-tip data-for='editTool'>
                        <Tooltip
                            placement="right"
                            title="Edit Tool"
                            className="tooltip from-builder-tooltip"
                        >
                           <SettingFilled />
                        </Tooltip>
                    </a>}
                    {this.props.showType !== 'xyTable' && <>
                        <a className="actions" onClick={this.onColAddition.bind(this)} data-tip data-for='addColumn'>
                        <Tooltip
                            placement="right"
                            title="All Column"
                            className="tooltip from-builder-tooltip"
                        >
                            <PlusCircleFilled />
                        </Tooltip>
                        </a>
                        {this.props.showDelete && <a className="actions" onClick={this.onColDeletion.bind(this)} data-tip data-for='deleteColumn'>
                        <Tooltip
                            placement="right"
                            title="Delete Column"
                            className="tooltip from-builder-tooltip"
                        >
                            <DeleteFilled />
                        </Tooltip>
                        </a>}
                    </>}
                </Col>
            </Row>
        </>
        );
    }
}

export default Column;
