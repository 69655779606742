import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import AccountRouteFetcher from "../AccountRouteFetcher";
import { ForgetPasswordPage } from "../pages/auth/components/forget-password.page";
import { LoginPage } from "../pages/auth/components/login.page";
import LandingPage from "../pages/landing.page";
import { ACCOUNT_PREFIX, RootState } from "../shared/constants";
import {
  ROUTE_FORGET_PASSWORD,
  ROUTE_LANDING,
  ROUTE_LOGIN,
} from "../shared/routes/route.constants";
import BossRoutes from "./boss.routes";
import DoctorRoutes from "./doctor.routes";
import PatientRoutes from "./patient.routes";

function RouteGuard({ ws, app, ...rest }: any) {
  const { token } = useSelector((state: RootState) => state.auth);
  const { profile } = useSelector((state: RootState) => state.profile);
  const application_slug = localStorage.getItem("application_slug");
  if (token && application_slug && app === "max") {
    app = application_slug;
  }
  const App: any =
    app === "doctor"
      ? DoctorRoutes
      : // : app === "order"
      // ? OrderRoutes
      app === "patient"
      ? PatientRoutes
      : // : app === "crm"
      // ? CRMRoutes
      app === "boss"
      ? BossRoutes
      : {};
  return (
    <Route
      {...rest}
      render={
        (props) =>
          token && profile ? (
            <AccountRouteFetcher>
              <App {...props} ws={ws} />
            </AccountRouteFetcher>
          ) : (
            <>
              <Route
                path={ROUTE_LOGIN(":account", ":source")}
                exact
                component={LoginPage}
              />
              <Route
                path={ROUTE_FORGET_PASSWORD(":account", ":source", ":number")}
                exact
                component={ForgetPasswordPage}
              />
              <Route
                path={ROUTE_LANDING(":account")}
                exact
                component={LandingPage}
              />
              <Redirect to={ROUTE_LANDING(ACCOUNT_PREFIX)} />
            </>
          )
        // <App {...props} />
      }
    />
  );
}

export default RouteGuard;
