import React from "react";
import { Button } from "antd";

interface PracticeButtonProps {
  practiceStatus: "start" | "pause" | "stop";
  onStartConsultation: (action: string, shouldStop: boolean) => void;
}

const PracticeButton: React.FC<PracticeButtonProps> = ({
  practiceStatus,
  onStartConsultation,
}) => {
  const buttonConfig = {
    start: {
      text: "Stop Practice",
      action: () => onStartConsultation("stop", true),
      className: "doctor-border-button",
    },
    pause: {
      text: "Resume Practice",
      action: () => onStartConsultation("start", false),
      className: "doctor-secondary-button",
    },
    stop: {
      text: "Start Practice",
      action: () => onStartConsultation("start", false),
      className: "doctor-secondary-button",
    },
  };

  const { text, action, className } = buttonConfig[practiceStatus];

  return (
    <Button
      className={`${className} mt20 mb-20`}
      size="large"
      block
      onClick={action}
    >
      {text}
    </Button>
  );
};

export default PracticeButton;
