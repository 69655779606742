import { notification } from "antd";
import { CustomEvents, sendInternalEvent } from "./utilities";
import { BASE_WEB_URL_NEW_BETA } from "../constants";

export const _BASE_URL = process.env.BASE_URL_BETA || BASE_WEB_URL_NEW_BETA;

export async function BetaClient(
  endpoint: string,
  { body, ...customConfig } = {} as any
) {
  const headers: any = { "Content-Type": "application/json", source: "doctor" };
  if (localStorage.getItem("token")) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  if (localStorage.getItem("account_id")) {
    headers["account_id"] = localStorage.getItem("account_id");
  }
  if (localStorage.getItem("application_id")) {
    headers["application_id"] = localStorage.getItem("application_id");
  }
  let application_slug;
  if (localStorage.getItem("application_slug")) {
    application_slug = localStorage.getItem("application_slug");
  }

  headers["source"] = "doctor";
  switch (application_slug) {
    case "admin":
      headers["no_auth"] = "enabled";
      break;
    default:
      headers["no_auth"] = "disabled";
  }
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    sendInternalEvent(CustomEvents.REQUEST_SENT, { endpoint });

    const response = await window.fetch(
      BASE_WEB_URL_NEW_BETA + endpoint,
      config
    );

    if (response.ok) {
      sendInternalEvent(CustomEvents.REQUEST_SUCCESS, { endpoint });

      if (response.status === 204) {
        return {
          status: response.status,
          headers: response.headers,
          url: response.url,
        };
      }
      data = await response.json();
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    } else if (response.status === 401) {
      notification.error({ message: "Un Authorized" });
      window.localStorage.clear();
      window.location.href = "/";
    } else if (response.status === 403) {
      data = await response.json();
      notification.error({ message: data.message || "Forbidden" });
      return;
    } else if (response.status === 400) {
      data = await response.json();
      let message = data.message;
      if (data.errorDetails) {
        message = data.errorDetails.map((e: any) => `${e.error}`).join(", ");
      }
      notification.error({ message: message || "Forbidden" });
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }

    console.log(response);
    notification.error({
      message: response.statusText || "Something went wrong",
    });

    return Promise.reject(response.statusText);
  } catch (err: any) {
    notification.error({ message: err.message ? err.message : data });
    return Promise.reject(err.message ? err.message : data);
  }
}

BetaClient.get = function (endpoint: string, customConfig: any = {}) {
  let params: any = [];
  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = p;
      params.push(`${key}=${encodeURIComponent(customConfig.params[p])}`);
    }
  }

  if (customConfig.params) {
    return BetaClient(endpoint + "?" + params.join("&"), {
      ...customConfig,
      method: "GET",
    });
  } else {
    delete customConfig.params;
    return BetaClient(endpoint, { ...customConfig, method: "GET" });
  }
};

BetaClient.post = function (endpoint: string, body: any, customConfig = {}) {
  return BetaClient(endpoint, { ...customConfig, body, method: "POST" });
};

BetaClient.put = function (endpoint: string, body: any, customConfig = {}) {
  return BetaClient(endpoint, { ...customConfig, body, method: "PUT" });
};

BetaClient.patch = function (endpoint: string, body: any, customConfig = {}) {
  return BetaClient(endpoint, { ...customConfig, body, method: "PATCH" });
};

BetaClient.delete = function (endpoint: string, customConfig = {}) {
  return BetaClient(endpoint, { ...customConfig, method: "DELETE" });
};
